import React, { useState, Dispatch, useRef } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication, IXrefPackage } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import {
  FormTextField,
  FormNumberField,
  MultilineFormTextField
} from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { validateEmail } from '../../../Candidates/EditCandidate';
import { scrollToElement } from '../../../utils/scroll-to-element';

export default function XRef({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [minReferees, setMinReferees] = useState<string>('');
  const [candidateNote, setCandidateNote] = useState<string>('');
  const [refereeNote, setRefereeNote] = useState<string>('');
  const [xRefPackage, setXRefPackage] = useState<IXrefPackage | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const fieldRefs = {
    package: useRef<HTMLInputElement>(null),
    referees: useRef<HTMLInputElement>(null),
    candidateNote: useRef<HTMLInputElement>(null),
    refereeNote: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null)
  };

  const { data: packages, isLoading: loadingPackages } = useQuery({
    queryKey: ['xref packages'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getXrefPackages(application.id);
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting onboard express data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: xRefExport, isLoading: loadingXRefExport } = useMutation({
    mutationFn: async () => {
      if (application && candidate && xRefPackage) {
        const { res } = await Api.xRefExport(application.id, {
          candidate_email: candidate.email,
          package_id: xRefPackage.id,
          min_referees: Number(minReferees),
          note_to_candidate: candidateNote,
          note_to_referee: refereeNote
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in exporting to Xref, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!xRefPackage) inputErrors.package = 'Please select a package';
    if (!minReferees) inputErrors.referees = 'Min referees can not be empty';
    if (!candidateNote.trim()) inputErrors.candidateNote = 'Candidate note can not be empty';
    if (!refereeNote.trim()) inputErrors.refereeNote = 'Referee note can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    setErrors(inputErrors);
    if (inputErrors.email) {
      scrollToElement(fieldRefs.email);
    } else if (inputErrors.package) {
      scrollToElement(fieldRefs.package);
    } else if (inputErrors.referees) {
      scrollToElement(fieldRefs.referees);
    } else if (inputErrors.candidateNote) {
      scrollToElement(fieldRefs.candidateNote);
    } else if (inputErrors.refereeNote) {
      scrollToElement(fieldRefs.refereeNote);
    }
    return !Object.keys(inputErrors).length;
  };

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.XREF}
      label="Xref export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Xref request</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 2 }}>
          <FormTextField
            label="Candidate email"
            value={candidate.email}
            styles={{ width: '50%' }}
            required
            onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            error={errors.email}
            innerRef={fieldRefs.email}
          />
          <Autocomplete
            disablePortal
            options={packages || []}
            getOptionLabel={(option) => option.name}
            value={xRefPackage}
            sx={{ ...sharedClasses.formAutocomplete, width: '50%', marginTop: 3, flexGrow: 1 }}
            loading={loadingPackages}
            loadingText="Loading packages..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Package"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                required
                helperText={errors.package}
                ref={fieldRefs.package}
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setMinReferees(String(value.min_referees));
                setCandidateNote(value.note_to_candidate);
                setRefereeNote(value.note_to_referee);
              } else {
                setMinReferees('');
                setCandidateNote('');
                setRefereeNote('');
              }
              setXRefPackage(value);
            }}
          />
          <FormNumberField
            label={'Min referees'}
            value={minReferees}
            onChange={(e) => setMinReferees(e.target.value)}
            required
            styles={{ width: '50%' }}
            error={errors.referees}
            innerRef={fieldRefs.referees}
          />
          <MultilineFormTextField
            label="Note to candidate"
            value={candidateNote}
            onChange={(e) => setCandidateNote(e.target.value)}
            fullWidth
            required
            rows={6}
            error={errors.candidateNote}
            innerRef={fieldRefs.candidateNote}
          />
          <MultilineFormTextField
            label="Note to referee"
            value={refereeNote}
            onChange={(e) => setRefereeNote(e.target.value)}
            fullWidth
            required
            rows={6}
            error={errors.refereeNote}
            innerRef={fieldRefs.refereeNote}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={() => validateInputs() && xRefExport()}
          isLoading={loadingXRefExport}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
