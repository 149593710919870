import React, { useCallback, useRef, useState, useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Box, Button, CircularProgress } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import StyledSnackbar from '../CustomUIElements/StyledSnackbar';
import { IChartData } from './types';
import { classes } from '../../EditJob/styles';
import DateRangeField from '../../../NewUI/Components/CustomUIElements/DateRangePickerWithDynamicTitle/DateRangeField';
import EntityField from '../../../reports/DataVizSection/FilterFields/EntityField';
import UserField from '../../../reports/DataVizSection/FilterFields/UserField';
import JobTitleField from '../../../reports/DataVizSection/FilterFields/JobTitleField';
import {
  timeToHireOptions,
  dateRangeOptions,
  setDatePeriod,
  exportPDF,
  htmlLegendPlugin
} from '../../../reports/DataVizSection/GraphDefaultOptions';
import { ITimeToHireParams } from '../../../reports/DataVizSection/types';
import { emptyChartText } from '../../../reports/DataVizSection/EmptyChartText';
import { ReportContext } from '../../../reports';
import { startOfDay, endOfDay } from './helper';
import dayjs from 'dayjs';
import { sharedClasses } from '../CustomUIElements/sharedClasses';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarGraph({
  chartData,
  reportState,
  reportDispatch
}: {
  chartData: IChartData;
  reportState;
  reportDispatch;
}) {
  const refChart = useRef<HTMLDivElement>(null);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasChartData, setHasChartData] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const reportServiceApi = useContext(ReportContext);
  const userTimezone = reportServiceApi.currentUser.iana_timezone;
  const startDate = startOfDay(reportState.timeToHireFields.startDate, userTimezone);
  const endDate = endOfDay(reportState.timeToHireFields.endDate, userTimezone);

  const fetchOptions = async (entities: number[]) => {
    setIsLoadingOptions(true);
    try {
      const { included_data } = await reportServiceApi.dataVisualisation.getChartOptions({
        entity_id: entities,
        chart_type: 'time_to_hire',
        start_date: startDate,
        end_date: endDate,
        actor_id: reportState.timeToHireFields.user.length
          ? reportState.timeToHireFields.user.map((user: { id: number; name: string }) => user.id)
          : undefined,
        job_title: reportState.timeToHireFields.jobTitle.length
          ? reportState.timeToHireFields.jobTitle
          : undefined
      });
      reportDispatch({
        type: 'setChartData',
        payload: {
          jobTitleOptions: included_data.job_titles,
          usersOptions: included_data.actors
        }
      });
    } catch (error) {
      setSnackbar({
        message: `There was an error with retrieving the filtering options, ${error}`,
        state: 'error'
      });
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const filterTimeToHire = async () => {
    setIsLoading(true);
    try {
      const fields = reportState.timeToHireFields;
      const entityId = fields.entity[0]?.id
        ? fields.entity[0]?.name === 'All entities'
          ? fields.entity[0].id
          : fields.entity.map((i) => i.id)
        : [1];

      const params: ITimeToHireParams = {
        entity_id: entityId,
        start_date: startDate,
        end_date: endDate,
        period: setDatePeriod(dayjs(fields.endDate).diff(dayjs(fields.startDate), 'day'))
      };

      if (fields.user?.length) params['actor_id'] = fields?.user?.map((val) => val?.id);
      if (fields.jobTitle?.length) params['job_title'] = fields?.jobTitle;

      const { data } = await reportServiceApi.dataVisualisation.getTimeToHire(params);

      reportDispatch({
        type: 'setChartData',
        payload: {
          timeToHire: {
            datasets: data?.datasets,
            labels: data?.labels
          }
        }
      });
    } catch (error) {
      setSnackbar({
        message: 'There was an error with retrieving the reporting data',
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadChart = useCallback(() => {
    if (refChart.current === null) return;
    exportPDF(refChart, setSnackbar);
  }, [refChart]);

  return (
    <Box>
      <Box sx={classes.generalModalFormLine}>
        <EntityField
          entityOptions={reportState.entitiesOptions}
          reportState={reportState.timeToHireFields}
          isLoading={reportState.loadingOptions}
          reportDispatch={reportDispatch}
          fieldName="timeToHireFields"
          resetOptions={{
            user: [],
            jobTitle: []
          }}
        />
        <DateRangeField
          dateRangeOptions={dateRangeOptions}
          reportState={reportState.timeToHireFields}
          isLoading={isLoading}
          reportDispatch={reportDispatch}
          fieldName="timeToHireFields"
          payloadName="timeToHire"
          inputValue={reportState.timeToHireFields.timeToHire}
          inputHeader="Date Range"
        />
        <UserField
          userOptions={reportState.usersOptions}
          reportState={reportState}
          isLoading={isLoadingOptions}
          reportDispatch={reportDispatch}
          fieldName="timeToHireFields"
        />
        <JobTitleField
          jobTitleOptions={reportState.jobTitleOptions}
          reportState={reportState}
          isLoading={isLoadingOptions}
          reportDispatch={reportDispatch}
        />
        <Button
          id="visual-reports-time-to-hire-generate-chart"
          type="submit"
          variant="contained"
          sx={{ ...sharedClasses.saveButton, ...classes.generateButton }}
          disabled={
            !reportState?.timeToHireFields?.entity?.length ||
            !dayjs(reportState.timeToHireFields.startDate).isValid() ||
            !dayjs(reportState.timeToHireFields.endDate).isValid() ||
            !reportState.timeToHireFields.timeToHire
          }
          onClick={() => {
            filterTimeToHire();
            setHasChartData(true);
            if (reportState.timeToHireFields.entity[0].name === 'All entities') {
              fetchOptions(reportState.timeToHireFields.entity[0].id);
            } else {
              fetchOptions(
                reportState.timeToHireFields.entity.map((e: { name: string; id: number }) => e.id)
              );
            }
          }}
        >
          Generate
        </Button>
      </Box>
      <Box sx={isLoading ? classes.loadingWrapper : null}>
        {isLoading && <CircularProgress sx={{ position: 'absolute' }} size={24} color="inherit" />}
        <Box sx={isLoading ? classes.loadingContent : classes.graphWrapper} ref={refChart}>
          {reportState.timeToHire?.datasets?.length ? (
            <Box>
              <Box sx={{ position: 'relative', minHeight: '300px', maxHeight: '500px' }}>
                <Bar
                  options={timeToHireOptions(reportState)}
                  data={chartData}
                  plugins={[htmlLegendPlugin]}
                />
                <div id="legend-container" />
              </Box>
            </Box>
          ) : (
            <Box sx={classes.noDataContainer}>{emptyChartText(hasChartData)}</Box>
          )}
        </Box>
      </Box>
      <Box sx={{ ...classes.tabFooter, minWidth: 'unset' }}>
        {reportState.timeToHire?.datasets?.length ? (
          <Button
            id="visual-reports-time-to-hire-download"
            type="submit"
            sx={sharedClasses.saveButton}
            onClick={downloadChart}
          >
            Download
          </Button>
        ) : (
          <Box sx={{ margin: '10px 0' }} />
        )}
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
