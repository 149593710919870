"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  questionsPageContainer: {
    paddingTop: "6px",
    overflowY: "visible",
    width: "100%"
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    "& h2": {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "1.2em",
      color: "#666666",
      marginTop: "0",
      "@media (max-width: 1450px)": {
        fontSize: "20px"
      },
      "@media (max-width: 1260px)": {
        fontSize: "14px"
      }
    }
  },
  modalWrapper: {
    height: "100%",
    position: "relative",
    display: "contents"
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
    rowGap: "28px",
    width: "100%",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  questionsHeader: {
    display: "flex",
    paddingRight: "18px",
    textAlign: "center",
    position: "sticky",
    top: "0px",
    height: "auto",
    zIndex: 2,
    backgroundColor: "#fff",
    padding: "20px"
  },
  noQuestionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  noQuestionsText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px",
    textAlign: "center"
  },
  actionsContianer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "22px",
    margin: "30px 0 48px 0",
    padding: "0px 16px"
  },
  actionButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "600",
    color: "#666666",
    backgroundColor: "#9393931F",
    padding: "10px 24px",
    borderRadius: "6px",
    border: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#93939335",
      border: "none"
    }
  },
  actionsModalReducedPadding: {
    display: "flex",
    flexDirection: "column",
    padding: "60px 60px 0 60px",
    paddingLeft: "48px",
    maxWidth: "900px",
    width: "100%",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "30px"
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  actionModalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    marginLeft: "15px"
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    margin: "24px 0 0 0"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%"
  },
  actionModalFormLine: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "26px",
    width: "100%",
    marginLeft: "14px"
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    marginTop: "auto",
    marginBottom: "40px"
  },
  modalFooterSmallerSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    marginBottom: "40px"
  },
  modalCancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "100px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  modalCreateButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 19px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  formError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    position: "absolute",
    top: "-24px",
    left: "4px"
  },
  inlineError: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    left: "4px"
  },
  checkboxContainer: {
    display: "flex",
    marginLeft: "19px"
  },
  switchLabels: {
    justifyContent: "start",
    width: "120px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      color: "#000000"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .Mui-checked": {
      color: "rgb(91, 196, 191, 0.5) !important"
    }
  },
  switch: {
    "& .MuiSwitch-thumb": {
      color: "#FFFFFF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    },
    "& :hover": {
      "& .MuiSwitch-track": {
        backgroundColor: "#000 !important"
      }
    }
  },
  switchActive: {
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "rgb(91, 196, 191, 0.17) !important",
      opacity: "1 !important"
    },
    "& .MuiSwitch-thumb": {
      color: "#5BC4BF"
    },
    "& .Mui-disabled": {
      color: "#BBBBBB !important",
      "& .Mui-checked+.MuiSwitch-track": {
        backgroundColor: "#DDDDDD !important",
        opacity: "1 !important"
      },
      "& .MuiSwitch-thumb": {
        color: "#BBBBBB !important"
      },
      "& .MuiSwitch-track": {
        color: "#BBBBBB !important",
        "& :hover": {
          backgroundColor: "#BBBBBB !important"
        }
      },
      "& :hover": {
        backgroundColor: "#BBBBBB !important"
      }
    }
  },
  labelActive: {
    "& .MuiFormControlLabel-label": {
      color: "#CCCCCC",
      fontFamily: "Source Sans Pro",
      fontSize: "14px"
    }
  },
  addChoiceContainer: {
    padding: "20px 15px"
  },
  addChoiceHead: {
    display: "flex",
    alignItems: "baseline"
  },
  addChoiceLabel: {
    color: "#838383",
    paddingLeft: "4px"
  },
  modalAddChoiceButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#5BC4BF",
    backgroundColor: "#5BC4BF12",
    borderRadius: "6px",
    boxShadow: "none",
    marginTop: "10px",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#4da8a412",
      boxShadow: "none"
    }
  },
  choice: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center"
  },
  availabilityContainer: {
    padding: "20px 15px"
  },
  config: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column"
  },
  questionsWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 2,
    background: "#F3F3F3",
    borderRadius: "6px",
    minWidth: "fit-content"
  },
  questionInput: {
    "& span": {
      fontSize: "14px !Important"
    }
  },
  questionsTitle: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "bold",
    paddingBottom: "5px",
    width: "70px",
    padding: "0 6px"
  },
  iconGreen: {
    color: theme.palette.success.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  iconRed: {
    color: theme.palette.error.main,
    fontSize: "1.25rem",
    margin: "auto",
    display: "flex"
  },
  weightValue: {
    fontSize: "13px",
    textAlign: "center"
  },
  noticeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "9px 16px",
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: "8px",
    color: "#666666",
    fontSize: "16px",
    margin: "16px",
    rowGap: "1px"
  },
  noticeIcon: {
    color: theme.palette.error.main,
    marginTop: "4px",
    fontSize: "16px"
  },
  infoIcon: {
    color: theme.palette.action.main,
    marginTop: "4px",
    fontSize: "16px"
  },
  dragHandle: {
    color: "#939393",
    fontSize: 30,
    padding: "6px",
    marginRight: "10px",
    "&:hover": {
      borderRadius: "8px",
      backgroundColor: "#DDDDDD"
    },
    outline: "none"
  },
  sortableItemSwitchContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 6px",
    marginBlock: "auto",
    minWidth: "100px"
  },
  switchLabel: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(1.5)
  },
  toggleButtonsContainer: {
    marginTop: "40px",
    marginBottom: "40px",
    padding: "0px 16px"
  },
  aiSwitch: {
    width: "unset",
    "& .MuiSwitch-thumb": {
      backgroundColor: "#2D388D !important"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(0,0,0,0.38)",
      opacity: "1"
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#92ADF4 !important",
      opacity: "1 !important"
    }
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "-8px"
  }
};
