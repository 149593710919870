"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../../../components/ThemeContext/ThemeObject";
import { styles as sharedClasses } from "../Modals/styles";
export const styles = __spreadProps(__spreadValues({}, sharedClasses), {
  integrationCard: {
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    padding: 2,
    rowGap: 2,
    width: "150px",
    justifyContent: "space-between"
  },
  integrationCardImageContainer: {
    padding: "16px 0px"
  },
  exportButton: {
    border: "1px solid #DDDDDD",
    borderRadius: "6px",
    padding: "8px 16px",
    width: "fit-content",
    color: "#333333",
    fontWeight: 600,
    cursor: "pointer"
  },
  modalDoubleColumn: {
    flexDirection: "row",
    columnGap: 3
  },
  alreadyExportedModal: {
    padding: "48px 32px",
    width: "428px",
    alignItems: "center",
    rowGap: 2
  },
  integrationDetails: {
    padding: 3,
    borderRadius: "6px",
    border: "1px solid #E3E3E3",
    rowGap: 1
  },
  integrationDetailsTable: {
    "& .MuiTableCell-root": {
      fontFamily: "Source Sans Pro",
      padding: "4px 16px 16px 0px",
      border: "none",
      fontWeight: 600
    }
  },
  integrationDetailsTableHeader: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    background: "white"
  },
  verifyActivityButtonsContainer: {
    flexDirection: "row",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "bold",
    width: "fit-content"
  },
  verifyActivityButton: {
    padding: "6px",
    width: "100px",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "6px"
  }
});
