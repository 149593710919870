"use strict";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const GREY = "#939393";
export const DARK_GREY = "#666666";
export const SCOUT_BLUE = "#084d6d";
export const SCOUT_BLUE_DARK = "#05354c";
export const SCOUT_TEAL = "#5BC4BF";
export const SCOUT_SECONDARY_BLUE = "#5BC4BF";
export const SCOUT_SECONDARY_LIGHT_BLUE = "#EEFBFB";
export const GREEN = "#76D18F";
export const LIGHT_GREEN = "#EEFAF1";
export const RED = "#E37D7A";
export const LIGHT_RED = "#FEF7F7";
export const DARK_RED = "#D32F2F";
export const LIGHT_BLUE = "#6DBEBF";
export const PURPLE = "#9268F7";
export const PINK = "#F7A1FF";
export const MEDIUM_RED = "#EC6E85";
export const DARK_BLUE = "#7EA7E4";
export const YELLOW = "#E6A052";
export const OPAQUE_GREEN = "#EFFDF3";
export const OPAQUE_BLUE = "rgba(109, 190, 191, 0.12)";
export const OPAQUE_PINK = "rgba(247, 161, 255, 0.12)";
export const OPAQUE_RED = "rgba(252, 239, 239, 1)";
export const OPAQUE_DARK_BLUE = "rgba(252, 239, 239, 1)";
export const OPAQUE_PURPLE = "#F2EDFE";
export const OPAQUE_YELLOW = "#FCF4EA";
