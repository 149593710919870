import React, { useEffect, useState, useRef, useCallback, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import { ResponseMessage, LoadingMessage } from './components';

export default function AIInterviewModal(jobDescription: string) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [response, setResponse] = useState<{ index: string; content: string }[]>([]);

  const generateChatResponse = useCallback(
    async (id: string, question: string, input: string, reactComponentRoot?: HTMLElement) => {
      setIsLoading(true);
      try {
        const response = await fetch('/api/v4/ai_interviews', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            description: jobDescription,
            question: question,
            input: input
          })
        }).then((res) => res.json());
        if (response && response.result) {
          setResponse((prevResponse) => [...prevResponse, { index: id, content: response.result }]);

          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(
            <ResponseMessage id={id} message={response.result} />,
            reactComponentRoot as unknown as HTMLElement
          );
        } else {
          throw new Error('No response from STG GPT');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [jobDescription]
  );

  const updateUI = useCallback(
    (felixContainer: HTMLElement, number: string, reactComponentRoot: any) => {
      const button = felixContainer.querySelector('button.felix');
      const responseMessageContainer = felixContainer.nextElementSibling as HTMLElement;

      if (responseMessageContainer) {
        responseMessageContainer.style.display = 'unset';
        if (button) {
          button.innerHTML = 'Regenerate feedback';
        }
      }

      const dataInputField = document.getElementById(
        `application_answers_attributes_${number}_data`
      ) as HTMLInputElement;

      const label = document.querySelector(
        `label[for="application_answers_attributes_${number}_data"]`
      );

      const labelText = label?.textContent?.replace('*', '') || '';

      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(<LoadingMessage />, reactComponentRoot);

      generateChatResponse(button?.id || '', labelText, dataInputField?.value, reactComponentRoot);
    },
    [generateChatResponse]
  );

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const targetElement = event.target as HTMLElement;
      const fieldContainer = targetElement.closest('.field-container');
      const reactComponentRoot = fieldContainer?.querySelector('#react-component-root');
      const felixContainer = targetElement.closest('.felix-container') as HTMLElement;

      const inputField = felixContainer?.previousElementSibling;
      const id = (inputField as HTMLElement)?.id;
      const match = id?.match(/answers_attributes_(\d+)_/);
      const number = match ? match[1] : null;
      if (number !== null) {
        updateUI(felixContainer, number, reactComponentRoot);
      }
    },
    [updateUI]
  );

  useEffect(() => {
    const buttons = document.getElementsByClassName('felix');

    Array.from(buttons).forEach((button) => {
      button.addEventListener('click', handleButtonClick as unknown as EventListener);
    });

    return () => {
      Array.from(buttons).forEach((button) => {
        button.removeEventListener('click', handleButtonClick as unknown as EventListener);
      });
    };
  }, [generateChatResponse, response, isLoading, handleButtonClick]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return null;
}
