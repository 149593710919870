import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Stack,
  Box,
  Autocomplete,
  TextField,
  Collapse,
  Button,
  CircularProgress
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../Application/API';
import { ApplicationAction } from '../../Application/types';
import { sharedClasses } from './sharedClasses';
import { classes } from '../../Job/Applications/styles';
import { styles } from '../../Application/Modals/styles';
import { IError } from '../../SmartForms/types';
import { ISmsTemplate } from '../../Job/Applications/Modals/SMSCandidates';
import { StyledSnackbarProps, SnackbarState } from './StyledSnackbar';
import { MultilineFormTextField, FormTextField } from './FormTextField';

const SmsTemplateComponent = ({
  smsTemplatePermissions,
  smsMessage,
  setSmsMessage,
  setSnackbar,
  dispatch,
  dropdownLabel,
  smsMessageError,
  placeholders,
  multipleSms
}: {
  smsTemplatePermissions: boolean;
  smsMessage: string;
  setSmsMessage: Dispatch<SetStateAction<string>>;
  setSnackbar?: StyledSnackbarProps['setSnackbarState'];
  dispatch?: Dispatch<ApplicationAction>;
  dropdownLabel?: string;
  smsMessageError?: string;
  placeholders?: string[];
  multipleSms?: boolean;
}) => {
  const [smsTemplateName, setSmsTemplateName] = useState<string>('');
  const [smsTemplateError, setSmsTemplateError] = useState<string>('');
  const [smsTemplate, setSmsTemplate] = useState<ISmsTemplate | null>(null);
  const queryClient = useQueryClient();

  const notify = (message: string, state: SnackbarState) => {
    if (setSnackbar) {
      setSnackbar({ message, state });
    }

    if (dispatch) {
      dispatch({ type: 'SET_SNACKBAR', payload: { message, state } });
    }
  };

  const { data: smsTemplates, isFetching: fetchingSmsTemplates } = useQuery({
    queryKey: ['sms templates'],
    queryFn: async () => {
      const { res } = await Api.getSmsTemplates();
      return res.sms_templates;
    },
    onError: (error: IError) => notify(error.errors.join(', '), 'error'),
    initialData: []
  });

  const { mutate: saveTemplate, isLoading: savingTemplate } = useMutation({
    mutationFn: async () => {
      const { res } = await Api.saveSmsTemplate(smsTemplateName, smsMessage);
      return res;
    },
    onError: (error: IError) => notify(error.errors.join(', '), 'error'),
    onSuccess: (res) => {
      queryClient.setQueryData(['sms templates'], [...smsTemplates, res]);
      setSmsTemplate(res);
      setSmsTemplateName('');
      notify('Template saved', 'success');
    }
  });

  const handleSaveTemplate = () => {
    if (smsTemplates?.find((template: ISmsTemplate) => template.title === smsTemplateName)) {
      setSmsTemplateError('Template name already exists');
      return;
    }
    saveTemplate();
  };

  return (
    <Stack sx={{ rowGap: 3, paddingTop: 4 }}>
      <Autocomplete
        disablePortal
        id="sms-template-select"
        options={smsTemplates}
        getOptionLabel={(option) => option.title || ''}
        value={smsTemplate}
        sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
        loading={fetchingSmsTemplates}
        loadingText="Loading SMS templates..."
        ListboxProps={{ style: classes.addToEventListboxProps }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={dropdownLabel || 'Choose SMS template'}
            InputLabelProps={{ shrink: true }}
            sx={{ '& .MuiInputBase-root': { margin: 'unset', marginTop: 1 } }}
          />
        )}
        onChange={(_event, value) => {
          if (value) {
            setSmsMessage(value.body);
          } else {
            setSmsMessage('');
          }
          setSmsTemplate(value);
        }}
      />
      <Stack sx={{ width: '100%' }}>
        <MultilineFormTextField
          label="Content"
          value={smsMessage}
          onChange={(e) => setSmsMessage(e.target.value)}
          maxLength={multipleSms ? undefined : 160}
          required
        />
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', columnGap: 2 }}>
          <Stack>
            {placeholders && (
              <Stack
                sx={{ color: '#838383', fontSize: '11px', lineBreak: 'anywhere', paddingLeft: 0.5 }}
              >
                Available placeholders are: {placeholders.map((p) => `{${p}}`).join(', ')}
              </Stack>
            )}
            <Stack sx={styles.errorMessage}>{smsMessageError}</Stack>
          </Stack>
          <Stack sx={classes.addToEventCharacterCount}>
            {multipleSms
              ? `${smsMessage.length} characters (${Math.ceil(smsMessage.length / 160)} messages)`
              : `${smsMessage.length} / 160`}
          </Stack>
        </Stack>
      </Stack>
      <Collapse
        in={smsTemplatePermissions && !!smsMessage.trim() && smsMessage !== smsTemplate?.body}
      >
        <Stack sx={{ flexDirection: 'row', alignItems: 'baseline', columnGap: 5 }}>
          <Stack>
            <FormTextField
              id="new-sms-template-title"
              label="Template title"
              value={smsTemplateName}
              styles={{
                width: '290px',
                '& .MuiInputBase-input': { margin: 'unset', marginTop: 1 }
              }}
              onChange={(e) => {
                setSmsTemplateName(e.target.value);
                if (smsTemplateError) setSmsTemplateError('');
              }}
            />
            {smsTemplateError && (
              <Box sx={{ ...classes.saveSMSTemplateError, position: 'inherit' }}>
                {smsTemplateError}
              </Box>
            )}
          </Stack>
          <Box sx={{ transform: 'translateY(-1.5px)' }}>
            <Button
              id="save-sms-template"
              type="submit"
              sx={classes.saveSMSTemplateButton}
              onClick={handleSaveTemplate}
            >
              {savingTemplate ? <CircularProgress size={20} color="inherit" /> : 'Save template'}
            </Button>
          </Box>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default SmsTemplateComponent;
