import React from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ApplicationAction, IAttachment } from '../../types';

export default function TextPreview({
  selectedAttachment,
  dispatch,
  setIsLoadingPreview
}: {
  selectedAttachment: IAttachment;
  dispatch: React.Dispatch<ApplicationAction>;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const BASE_URL = window.location.origin;
  const { data: text } = useQuery({
    queryKey: ['textData', selectedAttachment?.id],
    queryFn: async () => {
      const response = await fetch(`${BASE_URL}/admin/assets/${selectedAttachment?.id}`);
      return response.text();
    },
    onError: (error: Error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting text data, ${error.message}`,
          state: 'error'
        }
      });
    },
    onSettled: () => setIsLoadingPreview(false)
  });

  return (
    <>
      <Box>
        <pre>{text}</pre>
      </Box>
    </>
  );
}
