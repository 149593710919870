import { Reducer } from 'react';
import {
  GenericTableData,
  TPaginationState,
  TSortableColumn,
  TTableState,
  TableActionItemAction,
  TableActionDrawerAction,
  TableColumnAction,
  TablePaginationAction,
  TableScrollAction,
  TableSelectionAction,
  TableRowSelectedAction,
  TableRowRemoveSelectedAction
} from './types';
import { addSelected, removeSelected, addAllPageItems, removeAllPageItems } from './tableHelper';
import { ISnackbarInput } from '../CustomUIElements/StyledSnackbar'

export interface GenericTableState {
  searchQuery: string;
  searchSettings: TPaginationState<string>;
  tableState: TTableState<GenericTableData, TSortableColumn[]>;
  selectedRowTitle: null | { [key: string]: any };
  snackbarState: ISnackbarInput;
}

export const initialTableState = (
  columns: TSortableColumn[],
  defaultSort: { sortBy: string; sortOrder: 'asc' | 'desc' },
  actionItems?: Record<string, boolean>,
  modals?: Record<string, boolean>
): GenericTableState => {
  return {
    searchQuery: '',
    searchSettings: {
      page: 1,
      limit: 10,
      sort: defaultSort.sortBy,
      sort_order: defaultSort.sortOrder
    },
    tableState: {
      canScroll: [false, true],
      tableHovered: false,
      density: 'Default',
      columns: columns,
      selectedItems: [],
      selectedItemIds: [],
      actions: {
        selectedItem: null,
        anchorEl: null,
        isOpen: {
          ...(actionItems || {})
        }
      },
      modalsOpen: {
        ...(modals || {})
      }
    },
    snackbarState: {
      message: '',
      state: 'success'
    },
    selectedRowTitle: null
  };
};

export type GenericTableAction<T = GenericTableData> =
  | { type: 'SET_SEARCH_QUERY'; payload: string }
  | { type: 'SET_DEFAULT_DATA'; payload: GenericTableState }
  | { type: 'SET_SNACKBAR'; payload: ISnackbarInput }
  | TableScrollAction
  | TableSelectionAction<T>
  | TableColumnAction<TSortableColumn[]>
  | TablePaginationAction<string>
  | TableActionItemAction<T>
  | TableActionDrawerAction
  | TableRowSelectedAction
  | TableRowRemoveSelectedAction;

export const genericTableReducer: Reducer<GenericTableState, GenericTableAction> = (
  state: GenericTableState,
  action: GenericTableAction
) => {
  switch (action.type) {
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.payload,
        searchSettings: {
          ...state.searchSettings,
          page: 1
        }
      };
    case 'ADD_SELECTED_ITEM':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...addSelected(
            state.tableState.selectedItems,
            state.tableState.selectedItemIds,
            action.payload
          )
        }
      };
    case 'SET_CAN_SCROLL':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          canScroll: action.payload
        }
      };
    case 'SET_TABLE_HOVERED':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          tableHovered: action.payload
        }
      };
    case 'REMOVE_SELECTED_ITEM':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...removeSelected(
            state.tableState.selectedItems,
            state.tableState.selectedItemIds,
            action.payload
          )
        }
      };
    case 'ADD_ALL_PAGE_ITEMS':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...addAllPageItems(state.tableState.selectedItems, action.payload)
        }
      };
    case 'REMOVE_ALL_PAGE_ITEMS':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...removeAllPageItems(state.tableState.selectedItems, action.payload)
        }
      };
    case 'SET_DENSITY':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          density: action.payload
        }
      };
    case 'SET_SORT':
      return {
        ...state,
        searchSettings: {
          ...state.searchSettings,
          sort: action.payload.sortBy,
          sort_order: action.payload.sortOrder
        }
      };
    case 'SET_COLUMNS':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          columns: action.payload
        }
      };
    case 'SET_PAGE':
      return {
        ...state,
        searchSettings: {
          ...state.searchSettings,
          page: action.payload
        }
      };
    case 'SET_LIMIT':
      return {
        ...state,
        searchSettings: {
          ...state.searchSettings,
          limit: action.payload
        }
      };
    case 'SELECT_ACTION_ITEM':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          actions: {
            ...state.tableState.actions,
            selectedItem: action.payload.item,
            anchorEl: action.payload.anchorEl,
            isOpen: {
              ...state.tableState.actions.isOpen,
              [action.payload.name]: true
            }
          }
        }
      };
    case 'CLOSE_ACTION_ITEM':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          actions: {
            ...state.tableState.actions,
            selectedItem: null,
            isOpen: {
              ...state.tableState.actions.isOpen,
              [action.payload]: false
            }
          }
        }
      };
    case 'SET_ACTION_MODAL_OPEN':
      return {
        ...state,
        tableState: {
          ...state.tableState,
          modalsOpen: {
            ...state.tableState.modalsOpen,
            [action.payload.name]: action.payload.isOpen
          }
        }
      };
    case 'SET_DEFAULT_DATA':
      return {
        ...action.payload
      };
    case 'SET_SELECTED_ROW_DATA':
      return {
        ...state,
        selectedRowTitle: { ...action.payload }
      };
    case 'REMOVE_SELECTED_ROW_DATA':
      return {
        ...state,
        selectedRowTitle: null
      };
      case 'SET_SNACKBAR':
        return {
          ...state,
          snackbarState: action.payload
      };
    default:
      return state;
  }
};
