import React from 'react';
import { Box, Button } from '@mui/material';
import { pdfjs } from 'react-pdf';
import { IAttachment, IPreviewData } from '../../types';
import { classes } from '../../../Components/Modals/styles';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { useDragScroll } from '../../../hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function WordPreview({
  onPinchHandler,
  previewData,
  selectedAttachment,
  setCurrentIndex,
  zoom
}: {
  onPinchHandler: (...args: unknown[]) => ReactDOMAttributes;
  previewData: IPreviewData;
  selectedAttachment: IAttachment;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
  zoom: number;
}) {
  const [scrollRef] = useDragScroll();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={classes.docPreviewNavigation}>
        {previewData.prev && (
          <Button sx={classes.downloadButton} onClick={() => setCurrentIndex((prev) => prev - 1)}>
            Previous
          </Button>
        )}
        {previewData.next && (
          <Button sx={classes.downloadButton} onClick={() => setCurrentIndex((prev) => prev + 1)}>
            Next
          </Button>
        )}
      </Box>
      <Box ref={scrollRef} {...onPinchHandler()}>
        <Box
          alt={selectedAttachment.attached_file_name}
          component="img"
          draggable={false}
          src={previewData.data || ''}
          sx={{
            ...classes.imagePreview,
            height: '1000px',
            transform: `scale(${zoom})`,
            transformOrigin: '0 0'
          }}
        />
      </Box>
    </Box>
  );
}
