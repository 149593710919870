"use strict";
import {
  BLACK,
  DARK_RED,
  GREEN,
  LIGHT_GREEN,
  LIGHT_RED,
  RED,
  SCOUT_BLUE,
  SCOUT_BLUE_DARK,
  SCOUT_SECONDARY_BLUE,
  SCOUT_SECONDARY_LIGHT_BLUE,
  SCOUT_TEAL,
  WHITE,
  LIGHT_BLUE,
  PURPLE,
  PINK,
  MEDIUM_RED,
  DARK_BLUE,
  YELLOW,
  OPAQUE_GREEN,
  OPAQUE_BLUE,
  OPAQUE_PINK,
  OPAQUE_RED,
  OPAQUE_DARK_BLUE,
  OPAQUE_YELLOW,
  OPAQUE_PURPLE,
  GREY,
  DARK_GREY
} from "./constants";
export const theme = {
  spacing: (factor) => `${8 * factor}px`,
  palette: {
    primary: {
      main: SCOUT_BLUE,
      dark: SCOUT_BLUE_DARK
    },
    secondary: {
      main: SCOUT_SECONDARY_BLUE,
      light: SCOUT_SECONDARY_LIGHT_BLUE,
      medium: LIGHT_BLUE,
      purple: PURPLE,
      pink: PINK,
      mediumRed: MEDIUM_RED,
      darkBlue: DARK_BLUE,
      yellow: YELLOW
    },
    success: {
      main: GREEN,
      light: LIGHT_GREEN
    },
    opaque: {
      green: OPAQUE_GREEN,
      blue: OPAQUE_BLUE,
      pink: OPAQUE_PINK,
      red: OPAQUE_RED,
      darkBlue: OPAQUE_DARK_BLUE,
      yellow: OPAQUE_YELLOW,
      purple: OPAQUE_PURPLE
    },
    action: {
      main: SCOUT_TEAL,
      light: LIGHT_GREEN
    },
    error: {
      main: RED,
      light: LIGHT_RED,
      dark: DARK_RED
    },
    common: {
      white: WHITE,
      black: BLACK,
      grey: GREY,
      darkGrey: DARK_GREY
    }
  },
  typography: {
    fontFamily: "Source Sans Pro"
  },
  props: {
    MuiButton: {
      variant: "contained",
      color: "primary",
      disableElevation: true
    },
    MuiSelect: {
      variant: "standard"
    },
    MuiFormControl: {
      variant: "standard"
    }
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        flexGrow: 1,
        marginRight: 12,
        background: "#FAFAFA",
        border: "1px solid #EAEEF0",
        borderRadius: 6,
        justifyContent: "center",
        "& svg": {
          fill: "#939393"
        },
        "& fieldset": {
          border: 0
        },
        "& input": {
          padding: "14px 8px !important"
        },
        "&:hover": {
          border: "1px solid #dde2e5 !important"
        },
        "&.Mui-disabled": {
          opacity: 0.7,
          pointerEvents: "none",
          color: "inherit"
        }
      },
      paper: {
        boxShadow: "0px 2px 14px 0px rgba(48,90,111,0.16);"
      }
    },
    MuiSelect: {
      root: {
        height: "100%",
        padding: 0,
        display: "flex",
        alignItems: "center",
        color: "#333333",
        paddingLeft: 20,
        "&:focus": {
          backgroundColor: "#FFF !important"
        }
      },
      selectMenu: {
        height: "100%"
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: "0px 2px 14px 0px rgb(48 90 111 / 16%)"
      }
    },
    MuiOutlinedInput: {
      input: {
        height: "2.75rem",
        padding: "0px 15px",
        fontSize: "0.85rem"
      },
      root: {
        "& fieldset": {
          borderColor: "#DDD !important"
        },
        "&:hover fieldset": {
          borderColor: "#bdbdbd !important"
        },
        "&.Mui-focused fieldset": {
          borderColor: `${SCOUT_BLUE} !important`
        },
        "&.Mui-error fieldset": {
          borderColor: `${RED} !important`
        },
        "&.Mui-disabled": {
          opacity: 0.7,
          pointerEvents: "none"
        }
      }
    },
    MuiTextField: {
      root: {
        marginTop: "0.5rem"
      }
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: SCOUT_BLUE,
        color: WHITE,
        maxHeight: 40,
        minHeight: "auto",
        "&$expanded": {
          minHeight: "auto"
        }
      },
      content: {
        "&$expanded": {
          margin: "inherit"
        }
      },
      expandIcon: {
        color: WHITE
      }
    },
    MuiButton: {
      root: {
        padding: "10px 20px !important",
        "&:focus, &:hover": {
          outline: "0 !important"
        }
      },
      containedSecondary: {
        color: "#FFF"
      },
      outlinedSecondary: {
        color: SCOUT_SECONDARY_BLUE,
        backgroundColor: SCOUT_SECONDARY_LIGHT_BLUE,
        outline: 0,
        border: 0,
        "&:focus, &:hover": {
          outline: "0 !important",
          border: "0 !important"
        },
        "&.Mui-disabled": {
          border: 0,
          background: "#f9f9f9",
          "& svg": {
            fill: "currentColor"
          }
        },
        "& svg": {
          fill: SCOUT_SECONDARY_BLUE
        }
      }
    },
    MuiChip: {
      deletable: {
        "&:focus": {
          backgroundColor: "none"
        }
      }
    },
    MuiPickersModal: {
      dialogRoot: {
        "& .MuiDialogActions-root button": {
          backgroundColor: `${SCOUT_SECONDARY_BLUE} !important`,
          "&:hover": {
            backgroundColor: "rgb(63, 137, 133) !important"
          }
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: SCOUT_SECONDARY_LIGHT_BLUE
      }
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        alignItems: "center"
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: `${SCOUT_SECONDARY_BLUE} !important`
      },
      toolbarBtnSelected: {
        fontWeight: "900"
      }
    },
    MuiPickersBasePicker: {
      container: {
        "& button": {
          paddingTop: "2px !important",
          paddingBottom: "2px !important"
        }
      }
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: "#FAFAFA"
      }
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: SCOUT_BLUE
      }
    }
  }
};
