import React, { useRef, useEffect, useState, memo } from 'react';
import moment from 'moment';
import Api from '../API';
import NotesPopover from './NotesPopover';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  Pagination,
  Backdrop,
  CircularProgress,
  Paper,
  MenuItem,
  FormControl,
  Select,
  Popover
} from '@mui/material';
import TableSkeleton from './TableSkeleton';
import NoJobs from './NoJobs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IRequestParams, ITableProps, IJob } from '../types';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { classes } from './useStyles';
import CurrentJobsTableRow from './CurrentJobsTableRow';
import CurrentJobsTableHeader from './CurrentJobsTableHeader';
import { resourceDefault } from '../config';
import queryString from 'query-string';

const BASE_URL = window.location.origin;

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

function CurrentJobs({
  apiKey,
  sortableColumns,
  rowsPerPage,
  setRowsPerPage,
  search,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  filters,
  fetchUISwitch,
  page,
  setPage,
  filtersApplied,
  userPermissions,
  setSnackbarState,
  showPinnedJobs,
  density,
  jobStatusFeature,
  isDashboard,
  showNewApplicants,
  hideArrows,
  userTimezone
}: ITableProps) {
  const [jobs, setJobs] = useState(null);
  const [pinnedJobs, setPinnedJobs] = useState(null);
  const [selected, setSelected] = useState([]);
  const [maxPages, setMaxPages] = useState(1);
  const [maxJobs, setMaxJobs] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPinned, setIsLoadingPinned] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [stateIsSending, setStateIsSending] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [canScroll, setCanScroll] = useState([false, true]);
  const [stateAnchorEl, setStateAnchorEl] = useState(null);
  const [tempState, setTempState] = useState<{
    [key: number]: { label: string; list: IJob['job_states'] };
  }>({});
  const [selectedJob, setSelectedJob] = useState({ id: null, states: null, statuses: null });
  const [stateError, setStateError] = useState({ id: null, message: null });
  const [statusIsSending, setStatusIsSending] = useState(0);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [tempStatus, setTempStatus] = useState<{
    [key: number]: { label: string; list: IJob['job_statuses'] };
  }>({});
  const [noteAnchorEl, setNoteAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [showMore, setShowMore] = useState([]);
  const noteRefs = useRef([]);
  const allColumns = useRef(null);
  const actionCellRef = useRef(null);
  const titleCellRef = useRef(null);
  const statusDropdownRef = useRef([]);
  const [isPinning, setIsPinning] = useState(0);
  const [pinResponse, setPinResponse] = useState('');
  const [trashAlertOpen, setTrashAlertOpen] = useState(false);
  const [deleteJobs, setDeleteJobs] = useState([]);
  const [resourses, setResourses] = useState(resourceDefault);

  useEffect(() => {
    if (fetchUISwitch) return;
    getJobs(
      {
        page: page,
        limit: rowsPerPage,
        sort_by: orderBy,
        sort_direction: order,
        exclude_pinned: showPinnedJobs,
        'q[search]': search ? search : undefined,
        'filter[location]': filters.location.map((location) => location.name),
        'filter[state]': filters.state.map((state) => state.value),
        'filter[entity_ids]': filters.entity.map((entity) => entity.id),
        'filter[assigned_user_ids]': filters.assigned_user.map((user) => user.id),
        'filter[recruitment_ad_user_ids]': filters.recruitment_ad_user.map((user) => user.id),
        'filter[job_status_ids]': filters.status.map((status) => status.id),
        'filter[start_published_at]':
          filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
        'filter[end_published_at]':
          filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
        'filter[start_expires_at]':
          filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
        'filter[end_expires_at]':
          filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD'),
        ...(!!isDashboard && { pinned: true, dashboard: true }),
        ...(!!showNewApplicants && { with_new_applications: true, pinned: false })
      },
      apiKey
    );
    if (!showPinnedJobs) return;
    getPinnedJobs(
      {
        page: 1,
        limit: 100,
        sort_by: orderBy,
        sort_direction: order,
        'q[search]': search ? search : undefined,
        'filter[location]': filters.location.map((location) => location.name),
        'filter[state]': filters.state.map((state) => state.value),
        'filter[entity_ids]': filters.entity.map((entity) => entity.id),
        'filter[assigned_user_ids]': filters.assigned_user.map((user) => user.id),
        'filter[recruitment_ad_user_ids]': filters.recruitment_ad_user.map((user) => user.id),
        'filter[job_status_ids]': filters.status.map((status) => status.id),
        'filter[start_published_at]':
          filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
        'filter[end_published_at]':
          filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
        'filter[start_expires_at]':
          filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
        'filter[end_expires_at]':
          filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD'),
        pinned: true
      },
      apiKey
    );
  }, [search, page, rowsPerPage, orderBy, order, filters, fetchUISwitch, showPinnedJobs]);

  async function getJobs(params: IRequestParams, apiKey: string) {
    setIsLoading(true);
    try {
      const data = await Api.getJobs(params, { 'X-api-authenticate': apiKey });
      setJobs([...data.res.jobs]);
      setMaxPages(parseInt(data.resHead['x-total-pages']));
      setMaxJobs(parseInt(data.resHead['x-total-count']));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      firstLoad && setFirstLoad(false);
    }
  }

  async function getPinnedJobs(params: IRequestParams, apiKey: string) {
    setIsLoadingPinned(true);
    try {
      const data = await Api.getJobs(params, { 'X-api-authenticate': apiKey });
      setPinnedJobs([...data.res.jobs]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingPinned(false);
    }
  }

  async function changeState(state: IJob['job_states'][0], jobId: number, apiKey: string) {
    setStateIsSending(jobId);
    try {
      const response = await Api.putChangedState(state.link, { 'X-api-authenticate': apiKey });
      getNewStateAndStatus(response.res, jobId);
    } catch (err) {
      console.log(err);
      setStateError({ id: jobId, message: err.error ? err.error : 'Unknown error occured' });
      setTimeout(() => {
        setStateError((prevState) => ({ ...prevState, id: null }));
      }, 10000);
    } finally {
      setStateIsSending(0);
    }
  }

  async function changeStatus(jobId: number, newStatus: string, statusId: number, apiKey: string) {
    setStatusIsSending(jobId);
    try {
      const response = await Api.putChangedStatus(jobId, statusId, {
        'X-api-authenticate': apiKey
      });
      getNewStateAndStatus(response.res, jobId);
    } catch (err) {
      console.log(err);
    } finally {
      setStatusIsSending(0);
    }
  }

  async function pinJob(jobId: number) {
    setIsPinning(jobId);
    try {
      const response = await Api.putPinJob(jobId, { 'X-api-authenticate': apiKey });
      if (!response.res.errors) {
        if (showPinnedJobs) {
          setJobs((prev: IJob[]) => prev.filter((job) => job.id !== jobId));
          reloadJobs('pinned');
        } else {
          setJobs((prev: IJob[]) =>
            prev.map((job) => (job.id === jobId ? { ...job, pinned_by_user: true } : job))
          );
        }
      } else {
        setPinResponse(response.res.errors);
      }
    } catch (err) {
      setSnackbarState({
        message: err?.errors,
        state: 'error'
      });
    } finally {
      setIsPinning(0);
    }
  }

  async function unpinJob(jobId: number) {
    setIsPinning(jobId);
    try {
      const response = await Api.putUnpinJob(jobId, { 'X-api-authenticate': apiKey });
      if (!response.res.errors) {
        reloadJobs('jobs');
        if (showPinnedJobs) {
          setPinnedJobs((prev: IJob[]) => prev.filter((job) => job.id !== jobId));
        }
      }
    } catch (err) {
      setSnackbarState({
        message: err?.errors,
        state: 'error'
      });
    } finally {
      setIsPinning(0);
    }
  }

  const handleScroll = () => {
    if (allColumns.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      allColumns.current.scrollLeft >
      allColumns.current.scrollWidth - allColumns.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    allColumns.current.scrollLeft > 360
      ? (allColumns.current.scrollLeft -= 300)
      : (allColumns.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    allColumns.current.scrollWidth - allColumns.current.clientWidth > 360
      ? (allColumns.current.scrollLeft += 300)
      : (allColumns.current.scrollLeft =
          allColumns.current.scrollWidth - allColumns.current.clientWidth);
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(allColumns.current)) setHovered(true);
  };

  const handleScrollableExit = () => {
    setHovered(false);
  };

  const handleRequestSort = (event: React.MouseEvent<Element, MouseEvent>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = jobs.map((job: IJob) => job.id);
      if (showPinnedJobs) {
        newSelecteds.push(...pinnedJobs.map((job: IJob) => job.id));
      }
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<number> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePin = (event: React.MouseEvent, id: number, pinned_by_user: boolean) => {
    pinned_by_user ? unpinJob(id) : pinJob(id);
  };

  const handlePaginationChange = (event: React.ChangeEvent<HTMLInputElement>, newPage: number) => {
    setPage(newPage);
    scrollToTableTop();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const scrollToTableTop = () => {
    window.scrollTo({
      top: 40,
      behavior: 'smooth'
    });
  };

  async function getNewStateAndStatus(data: IJob, jobId: number) {
    setTempState((prevState) => ({
      ...prevState,
      [jobId]: { label: data.job_states[0].label, list: data.job_states }
    }));
    setTempStatus((prevState) => ({
      ...prevState,
      [jobId]: {
        label: data.job_statuses?.length ? data.job_statuses[0].label : '',
        list: data.job_statuses
      }
    }));
  }

  const handleStateDropdown = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    state: IJob['job_states']
  ) => {
    setStateAnchorEl(event.currentTarget);
    setSelectedJob({ id: id, states: state, statuses: null });
  };

  const handleStateDropdownClose = () => {
    setStateAnchorEl(null);
    setTimeout(() => {
      // timeout is used to account for the dropdown close animation time
      setSelectedJob({ id: null, states: null, statuses: null });
    }, 300);
  };

  const handleStateChange = (
    e: React.MouseEvent<HTMLElement>,
    id: number,
    state: IJob['job_states'][0]
  ) => {
    changeState(state, id, apiKey);
    handleStateDropdownClose();
  };

  const handleStatusDropdown = (
    event: React.MouseEvent<HTMLElement>,
    id: number,
    statuses: IJob['job_statuses']
  ) => {
    setStatusAnchorEl(event.currentTarget);
    setSelectedJob({
      id: id,
      states: null,
      statuses: statuses
    });
  };

  const changeStateTense = (state: string) => {
    switch (state) {
      case 'Draft':
        return 'draft';
      case 'Save':
        return 'saved';
      case 'Publish':
        return 'published';
      case 'Unpublish':
        return 'unpublished';
      case 'Close':
        return 'closed';
      default:
        return state;
    }
  };

  const showDropdown = (statuses: IJob['job_statuses']) => {
    if (statuses[1]) return true;
    return false;
  };

  const handleStatusDropdownClose = () => {
    setStatusAnchorEl(null);
    setTimeout(() => {
      // timeout is used to account for the dropdown close animation time
      setSelectedJob({ id: null, states: null, statuses: null });
    }, 300);
  };

  const handleStatusChange = (id: number, newStatus: string, statusId: string) => {
    handleStatusDropdownClose();
    changeStatus(id, newStatus, Number(statusId.split('status/')[1]), apiKey);
  };
  const handleOpenNote = (event: React.MouseEvent<HTMLElement>, id: number, pinned: boolean) => {
    setSelectedJob({ id: id, states: null, statuses: null, isPinnedSection: pinned });
    setNoteAnchorEl(event.currentTarget);
  };

  const handleCloseNote = () => {
    if (isEditing) return;
    setSelectedJob({ id: null, states: null, statuses: null });
    setNoteAnchorEl(null);
  };

  const handleShowMore = (id: number) => {
    if (!showMore.includes(id)) {
      const copy = [...showMore];
      copy.push(id);
      setShowMore(copy);
    }
    const flag = showMore.indexOf(id);
    if (flag > -1) {
      const copy = [...showMore];
      copy.splice(flag, 1);
      setShowMore(copy);
    }
  };

  const reloadJobs = (type: 'all' | 'jobs' | 'pinned') => {
    if (type === 'all' || type === 'jobs') {
      getJobs(
        {
          page: page,
          limit: rowsPerPage,
          sort_by: orderBy,
          sort_direction: order,
          exclude_pinned: showPinnedJobs,
          'q[search]': search ? search : undefined,
          'filter[location]': filters.location.map((location) => location.name),
          'filter[state]': filters.state.map((state) => state.value),
          'filter[entity_ids]': filters.entity.map((entity) => entity.id),
          'filter[assigned_user_ids]': filters.assigned_user.map((user) => user.id),
          'filter[recruitment_ad_user_ids]': filters.recruitment_ad_user.map((user) => user.id),
          'filter[job_status_ids]': filters.status.map((status) => status.id),
          'filter[start_published_at]':
            filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
          'filter[end_published_at]':
            filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
          'filter[start_expires_at]':
            filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
          'filter[end_expires_at]':
            filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD'),
          pinned: !!isDashboard
        },
        apiKey
      );
    }
    if (showPinnedJobs && (type === 'all' || type === 'pinned')) {
      getPinnedJobs(
        {
          page: 1,
          limit: 100,
          sort_by: orderBy,
          sort_direction: order,
          'q[search]': search ? search : undefined,
          'filter[location]': filters.location.map((location) => location.name),
          'filter[state]': filters.state.map((state) => state.value),
          'filter[entity_ids]': filters.entity.map((entity) => entity.id),
          'filter[assigned_user_ids]': filters.assigned_user.map((user) => user.id),
          'filter[recruitment_ad_user_ids]': filters.recruitment_ad_user.map((user) => user.id),
          'filter[job_status_ids]': filters.status.map((status) => status.id),
          'filter[start_published_at]':
            filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
          'filter[end_published_at]':
            filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
          'filter[start_expires_at]':
            filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
          'filter[end_expires_at]':
            filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD'),
          pinned: true
        },
        apiKey
      );
    }
  };

  async function handleArchiveJobs(ids: Array<number>) {
    setIsLoading(true);
    try {
      const response = await Api.putArchiveJobs({ 'X-api-authenticate': apiKey }, { job_ids: ids });
      if (response.res?.job_ids?.failure?.length) {
        const message =
          response.res.job_ids.success?.length > 0 || response.res.job_ids.failure?.length > 1
            ? `${response.res.job_ids.failure?.length} ${
                response.res.job_ids.failure?.length > 1 ? 'jobs were' : 'job was'
              } unable to be archived. Jobs in the Published, Closed and Draft state cannot be archived.`
            : `${
                response.res.job_ids.failure?.length === 1
                  ? 'Unable to archive job. Jobs in the Published, Closed and Draft state cannot be archived.'
                  : ''
              }`;
        setSnackbarState({
          message: message,
          state: 'error'
        });
      }
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
      alert('Something went wrong. Please try again.');
    } finally {
      setSelected([]);
      setSelectedJob({ id: null, states: null, statuses: null });
      setActionsAnchorEl(null);
      reloadJobs('all');
    }
  }

  async function handleTrashJobs(ids: Array<number>) {
    setIsLoading(true);
    try {
      await fetch(`${BASE_URL}/api/v4/jobs/delete`, {
        method: 'DELETE',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ job_ids: ids })
      });
      setDeleteJobs([]);
    } catch (error) {
      console.log(error);
      alert('Something went wrong. Please try again.');
    } finally {
      setSelected([]);
      setSelectedJob({ id: null, states: null, statuses: null });
      setActionsAnchorEl(null);
      reloadJobs('all');
    }
  }

  useEffect(() => {
    setSelected([]);
  }, [showPinnedJobs]);

  const cloneJob = async (id: number) => {
    setIsLoading(true);
    const queryParams = `?${queryString.stringify(resourses, {
      arrayFormat: 'bracket',
      encode: false
    })}`;
    const cloningJob = jobs.find((x) => x.id === id) || pinnedJobs.find((x) => x.id === id);
    try {
      const response = await fetch(`/api/v4/jobs/${id}/clone${queryParams}`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          job: {
            reference: cloningJob.reference,
            title: cloningJob.title,
            expires_at: '',
            location: cloningJob.location
          }
        })
      }).then((response) => response.json());
      if (response.job_link) {
        window.location.href = `${BASE_URL}${response.job_link}/edit`;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const tableRowSharedProps = {
    userPermissions,
    BASE_URL,
    isPinning,
    sortableColumns,
    tempState,
    stateIsSending,
    stateError,
    tempStatus,
    statusIsSending,
    statusDropdownRef,
    noteRefs,
    hovered,
    isSelected,
    showMore,
    handleShowMore,
    handleClick,
    handlePin,
    handleScrollableEnter,
    handleScrollableExit,
    handleStateDropdown,
    showDropdown,
    handleStatusDropdown,
    handleOpenNote,
    setActionsAnchorEl,
    setSelectedJob,
    jobStatusFeature,
    userTimezone
  };

  return (
    <Box id="table-container" sx={{ width: '100%', position: 'relative' }}>
      <Backdrop
        sx={{ color: '#83ECE7', zIndex: '99999' }}
        open={(isLoading || isLoadingPinned) && !firstLoad}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none ' }}>
        {(isLoading || isLoadingPinned) && firstLoad ? (
          <>
            <TableContainer
              id="table-scrollable"
              sx={classes.tableContainer}
              onScroll={handleScroll}
            >
              <Table
                stickyHeader
                sx={{
                  ...classes.tableWrapper,
                  minWidth: 750,
                  '& tr': { '& td, th': { padding: `${tablePadding(density)} 18px` } }
                }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <CurrentJobsTableHeader
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={jobs && jobs.length}
                  pinnedCount={pinnedJobs && showPinnedJobs ? pinnedJobs.length : 0}
                  sortableColumns={sortableColumns}
                  handleScrollableEnter={handleScrollableEnter}
                  handleScrollableExit={handleScrollableExit}
                  hovered={hovered}
                  actionCellRef={actionCellRef}
                  titleCellRef={titleCellRef}
                  jobStatusFeature={jobStatusFeature}
                  userPermissions={userPermissions}
                  isDashboard={isDashboard}
                />
              </Table>
            </TableContainer>
            <TableSkeleton />
          </>
        ) : (
          <>
            <TableContainer
              id="table-scrollable"
              ref={allColumns}
              sx={classes.tableContainer}
              onScroll={handleScroll}
            >
              {!hideArrows && (
                <>
                  <Box
                    onMouseEnter={() => handleScrollableEnter()}
                    onMouseLeave={() => handleScrollableExit()}
                    onClick={() => handleScrollLeft()}
                    sx={{
                      ...classes.leftArrow,
                      ...(hovered && canScroll[0] ? classes.showArrows : {}),
                      left: `${titleCellRef.current?.getBoundingClientRect().right}px`
                    }}
                    id="left-arrow"
                  >
                    <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
                  </Box>
                  <Box
                    onMouseEnter={() => handleScrollableEnter()}
                    onMouseLeave={() => handleScrollableExit()}
                    onClick={() => handleScrollRight()}
                    sx={{
                      ...classes.rightArrow,
                      ...(hovered && canScroll[1] ? classes.showArrows : {}),
                      left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`
                    }}
                    id="right-arrow"
                  >
                    <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
                  </Box>
                </>
              )}
              <Table
                stickyHeader
                sx={{
                  ...classes.tableWrapper,
                  minWidth: 750,
                  '& tr': {
                    '& td, th': { padding: `${tablePadding(density)} 18px`, textTransform: 'none' }
                  }
                }}
                aria-labelledby="tableTitle"
                size={'medium'}
              >
                <CurrentJobsTableHeader
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={jobs && jobs.length}
                  pinnedCount={pinnedJobs && showPinnedJobs ? pinnedJobs.length : 0}
                  sortableColumns={sortableColumns}
                  handleScrollableEnter={handleScrollableEnter}
                  handleScrollableExit={handleScrollableExit}
                  hovered={hovered}
                  actionCellRef={actionCellRef}
                  titleCellRef={titleCellRef}
                  jobStatusFeature={jobStatusFeature}
                  userPermissions={userPermissions}
                  isDashboard={isDashboard}
                />
                <TableBody id="table-body" data-testid="table-body" sx={classes.tableBody}>
                  {pinnedJobs && showPinnedJobs && (
                    <CurrentJobsTableRow
                      jobs={pinnedJobs}
                      isPinnedSection={true}
                      {...tableRowSharedProps}
                    />
                  )}
                  {jobs && (
                    <CurrentJobsTableRow
                      showPinnedBorder={showPinnedJobs && !isLoadingPinned}
                      isPinnedSection={false}
                      {...{ ...tableRowSharedProps, jobs, isDashboard }}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Popover
              id={stateAnchorEl ? 'state-selection' : undefined}
              sx={classes.stateDropdown}
              open={Boolean(stateAnchorEl)}
              anchorEl={stateAnchorEl}
              onClose={handleStateDropdownClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              {selectedJob.states &&
                selectedJob.states.map(
                  (state: IJob['job_states'][0]) =>
                    state.label !==
                      (tempState[selectedJob.id as keyof typeof tempState]?.label ||
                        selectedJob.states[0].label) && (
                      <span
                        key={`${selectedJob.id}-${state.label}`}
                        onClick={(e) => handleStateChange(e, selectedJob.id, state)}
                      >
                        {changeStateTense(state.label)}
                      </span>
                    )
                )}
            </Popover>
            <Popover
              id={statusAnchorEl ? 'status-selection' : undefined}
              sx={classes.statusDropdownItems}
              open={Boolean(statusAnchorEl)}
              anchorEl={statusAnchorEl}
              onClose={handleStatusDropdownClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              {selectedJob.statuses &&
                selectedJob.statuses.map(
                  (status: IJob['job_statuses'][0]) =>
                    ![
                      tempStatus[selectedJob.id as keyof typeof tempStatus]?.label ||
                        selectedJob.statuses[0].label,
                      'draft',
                      'published',
                      'unpublished',
                      'closed',
                      'saved'
                    ].includes(status.label) && (
                      <Box
                        key={`${selectedJob.id}-${status.label}`}
                        sx={classes.statusDropdownItem}
                        onClick={() =>
                          handleStatusChange(selectedJob.id, status.label, status.link)
                        }
                      >
                        {status.label}
                      </Box>
                    )
                )}
            </Popover>
            <NotesPopover
              setJobs={selectedJob.isPinnedSection ? setPinnedJobs : setJobs}
              anchorEl={noteAnchorEl}
              handleCloseNote={handleCloseNote}
              selectedJobId={selectedJob.id}
              apiKey={apiKey}
              setSnackbarState={setSnackbarState}
            />
            <Popover
              id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
              sx={classes.actionsMenu}
              open={Boolean(actionsAnchorEl)}
              anchorEl={actionsAnchorEl}
              onClose={() => {
                setActionsAnchorEl(null);
                setSelectedJob({ id: null, states: null, statuses: null });
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {userPermissions?.Jobs?.['Create / Edit Jobs'] && (
                <>
                  <span
                    onClick={() => {
                      window.location.href = BASE_URL + `/admin/jobs/${selectedJob.id}/edit`;
                    }}
                    className="edit-job-button"
                  >
                    Edit
                  </span>
                  {!isDashboard && !showNewApplicants && (
                    <span onClick={() => cloneJob(selectedJob.id)} className="clone-job-button">
                      Clone
                    </span>
                  )}
                </>
              )}
              {userPermissions?.Jobs?.['Archive Jobs'] && (
                <span
                  onClick={() => handleArchiveJobs([selectedJob.id])}
                  className="archive-job-button"
                >
                  Archive
                </span>
              )}
              {userPermissions?.Trash?.['Trash Jobs'] && (
                <span
                  onClick={() => {
                    setActionsAnchorEl(null);
                    setTimeout(() => setTrashAlertOpen(true), 100);
                    setDeleteJobs([selectedJob.id]);
                  }}
                  className="trash-job-button"
                  data-testid="trash-job-button-[CurrentJobs]"
                >
                  Trash
                </span>
              )}
            </Popover>
            {(userPermissions?.Jobs?.['Archive Jobs'] ||
              userPermissions?.Trash?.['Trash Jobs']) && (
              <Box
                id="selected-tooltip"
                sx={{
                  ...classes.selectedTooltip,
                  ...(selected.length > 0 ? classes.fadeIn : classes.fadeOut)
                }}
              >
                <Box id="selected-tooltip-text" sx={classes.selectedTooltipText}>
                  {selected.length} selected
                </Box>
                <Box sx={classes.selectedTooltipButtonsContainer}>
                  {userPermissions?.Jobs?.['Archive Jobs'] && (
                    <Button
                      variant="contained"
                      sx={classes.selectedTooltipButton}
                      onClick={() => handleArchiveJobs(selected)}
                    >
                      Archive
                    </Button>
                  )}
                  {userPermissions?.Trash?.['Trash Jobs'] && (
                    <Button
                      id="popover-trash-button"
                      data-testid="popover-trash-button"
                      variant="contained"
                      sx={classes.selectedTooltipButton}
                      onClick={() => {
                        setDeleteJobs(selected);
                        setTrashAlertOpen(true);
                      }}
                    >
                      Trash
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {jobs?.length === 0 && (
              <NoJobs searchOrFilterApplied={!!search || !!filtersApplied || isLoading} />
            )}
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px'
          }}
        >
          <Box sx={classes.rowSelectContainer}>
            <span>Rows per page: </span>
            <FormControl>
              <Select
                id="rows-per-page"
                data-testid="rows-per-page-[CurrentJobs]"
                sx={classes.rowSelect}
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                displayEmpty
                MenuProps={{ sx: classes.paginationMenuItems }}
              >
                <MenuItem id="first-rpp-item" data-testid="first-rpp-item-[CurrentJobs]" value={10}>
                  10
                </MenuItem>
                <MenuItem
                  id="second-rpp-item"
                  data-testid="second-rpp-item-[CurrentJobs]"
                  value={20}
                >
                  20
                </MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={classes.paginationContainer} data-testid={'pagination-container-[CurrentJobs]'}>
            <Pagination
              id="pagination-menu"
              count={maxPages}
              page={page}
              defaultPage={Math.ceil(maxPages / 2)}
              siblingCount={0}
              onChange={handlePaginationChange}
              sx={classes.pagination}
            />
            <span id="total-jobs" data-testid="total-jobs-[CurrentJobs]">
              {maxJobs} Total
            </span>
          </Box>
        </Box>
        <GenericDialog
          isDialogOpen={trashAlertOpen}
          setDialogOpen={setTrashAlertOpen}
          title={deleteJobs.length > 1 ? 'Trash jobs?' : 'Trash job?'}
          description={
            deleteJobs.length > 1
              ? 'Are you sure want to trash these jobs?'
              : 'Are you sure want to trash this job?'
          }
          buttonCallback={() => {
            setTrashAlertOpen(false);
            handleTrashJobs(deleteJobs);
            setDeleteJobs([]);
          }}
          callbackLoading={false}
          buttonText="Trash"
          url=""
        />
      </Paper>
    </Box>
  );
}

export default memo(CurrentJobs);
