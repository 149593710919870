import React, { Dispatch, useContext } from 'react';
import { Button, TableRow } from '@mui/material';
import { HistoryTableAction, HistoryTableState } from './types';
import { classes } from '../styles';
import { ReportContext } from '../index';
import { downloadReport } from './API';
import { tableValueFormat } from '../helper';
import {
  StickyLeftCell,
  StickyRightCell
} from '../../../TableWithSearchFieldDropDownAndFilter/StickyComponents';
import { ArrowRefsAndHoveredContext } from "../../../TableWithSearchFieldDropDownAndFilter/ArrowRefsAndHoveredContext";

const ReportTableBody = ({
  state,
  dispatch
}: {
  state: HistoryTableState;
  dispatch: Dispatch<HistoryTableAction>;
}) => {
  const { records, activeColumns } = state;
  const reportServiceApi = useContext(ReportContext);
  const { leftBoundCellRef, rightBoundCellRef, tableHovered } = useContext(
    ArrowRefsAndHoveredContext
  );
  const activeFields = Object.entries(state.fieldOptions).filter(
    ([key, value]) => activeColumns[value.name].enabled
  );

  return (
    <>
      {records.map((r, i) => (
        <TableRow key={i} sx={{ width: '100%' }}>
          {activeFields.map(([key, value]) => (
            <StickyLeftCell
              key={key}
              previousCellOffSet={value?.previousCellOffSet ? 0 : 50} // 50px offset from line 36 with min-width: 50px
              sticky={value.sticky}
              leftBound={value.leftBound}
              tableHovered={tableHovered}
            >
              {' '}
              {tableValueFormat(r[value.name], value.name, r, reportServiceApi.currentUser.user_timezone)}
            </StickyLeftCell>
          ))}
          <StickyRightCell additionalStyles={{ width: '150px' }} tableHovered={tableHovered}>
            <Button
              sx={classes.csvDownloadButton}
              onClick={() => downloadReport(r, dispatch, reportServiceApi)}
              id="history-report-download"
            >
              Download CSV
            </Button>
          </StickyRightCell>
        </TableRow>
      ))}
    </>
  );
};

export default ReportTableBody;
