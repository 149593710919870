import React, { useState, useEffect, useRef, Dispatch, SetStateAction, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Virtualize } from '../../../Components/CustomUIElements/VirtualizedAutocomplete';
import { FormControlLabel, CircularProgress, Button, Modal, Box, Switch } from '@mui/material';
import {
  MultilineFormTextField,
  FormTextField
} from '../../../Components/CustomUIElements/FormTextField';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import Api from '../../../Job/API';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableChoice from './SortableChoice';
import { IPermissions } from '../../../Jobs/types';

const fieldTypeOptions = ['Select', 'Date', 'Text', 'Radio Button', 'Rank', 'Checkbox', 'Textarea'];

const fieldTypeValues = {
  Select: 'select_field',
  'CSV Data Lookup': 'csv_data_lookup_field',
  Date: 'date_field',
  Text: 'text_field',
  'Radio Button': 'radio_button_field',
  Rank: 'rank_field',
  'Job Reference Lookup': 'job_reference_lookup_field',
  Availability: 'availability_field',
  Checkbox: 'check_box_field',
  Textarea: 'text_area_field'
};

const DateOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function NewField({
  aiInterview,
  apiKey,
  autoRating,
  editingQuestion,
  getQuestions,
  jobId,
  modalsOpen,
  setModalsOpen,
  setSnackbar,
  userPermissions
}: {
  aiInterview: boolean;
  apiKey: string;
  autoRating: boolean;
  editingQuestion?: any;
  getQuestions: any;
  jobId: number;
  modalsOpen: Record<string, boolean>;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  userPermissions: IPermissions;
}) {
  const [isCreating, setIsCreating] = useState(false);
  const [fieldType, setFieldType] = useState('');
  const [label, setLabel] = useState('');
  const [enabledSwitch, setEnabledSwitch] = useState(true);
  const [requiredSwitch, setRequiredSwitch] = useState(false);
  const [rateableSwitch, setRateableSwitch] = useState(false);
  const [confidentialSwitch, setConfidentialSwitch] = useState(false);
  const [felixAISwitch, setFelixAISwitch] = useState(false);
  const [choiceList, setChoiceList] = useState([
    { id: '', _destroy: 0, position: null, name: '', rating: null, rank: null, rank_value: null }
  ]);
  const [weekDuration, setWeekDuration] = useState('weekly');
  const [weekStartDay, setWeekStartDay] = useState(0);
  const [headingOne, setHeadingOne] = useState('');
  const [headingTwo, setHeadingTwo] = useState('');
  const [allowBreak, setAllowBreak] = useState(false);
  const [errorLabel, setErrorLabel] = useState('');
  const [errorField, setErrorField] = useState('');
  const [errorChoices, setErrorChoices] = useState('');
  const baseSwitchStyle = {
    ...classes.switchLabels,
    ...classes.aiSwitch
  };

  const labelRef = useRef<HTMLInputElement>(null);
  const fieldTypeRef = useRef<HTMLInputElement>(null);
  const choicesRef = useRef<HTMLInputElement>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = choiceList.map((c) => c.id).indexOf(active.id);
      const newIndex = choiceList.map((c) => c.id).indexOf(over.id);
      const newArray = arrayMove(choiceList, oldIndex, newIndex);

      setChoiceList(newArray);
    }
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const setDefaultValue = useCallback(async () => {
    if (editingQuestion) {
      setFieldType(getKeyByValue(fieldTypeValues, editingQuestion.field_type));
      setLabel(editingQuestion.title);
      setEnabledSwitch(editingQuestion.enabled);
      setRequiredSwitch(editingQuestion.required);
      setRateableSwitch(editingQuestion.rateable);
      setConfidentialSwitch(editingQuestion.confidential);
      setFelixAISwitch(editingQuestion.felix_ai_interview_prompter);
      setChoiceList(editingQuestion.field_choices);
    }
  }, [editingQuestion]);

  const handleClose = () => {
    setModalsOpen((prev) => ({ ...prev, newField: false }));
    clearData();
  };

  const handleSubmit = async (jobId: number) => {
    if (!fieldType || !label) {
      if (!fieldType) {
        fieldTypeRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorField('This field is required');
      } else {
        labelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setErrorLabel('This field is required');
      }
      return;
    }
    setIsCreating(true);

    let fieldObject = {
      title: label,
      enabled: enabledSwitch,
      required: requiredSwitch,
      rateable: rateableSwitch,
      confidential: confidentialSwitch,
      field_type: fieldTypeValues[fieldType],
      felix_ai_interview_prompter: felixAISwitch
    };

    if (['Select', 'Rank', 'Checkbox', 'Radio Button'].includes(fieldType)) {
      choiceList.map((choice, index) => {
        if (!choice?.name) {
          choicesRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setErrorChoices('All field choices must each have a value');
          return;
        }
        choice.position = index;
        if (choice.id.toString().length == 13) {
          choice.id = '';
        }
      });

      const jsonString = JSON.stringify(Object.assign({}, choiceList));
      const attributes = JSON.parse(jsonString);
      fieldObject['field_choices_attributes'] = attributes;
    } else if (['Availability'].includes(fieldType)) {
      fieldObject['availability_config_attributes'] = {
        week_duration: weekDuration.toLowerCase(),
        week_start_day: DateOptions.indexOf(weekStartDay),
        heading_1: headingOne,
        heading_2: headingTwo,
        allow_break: allowBreak
      };
    }

    try {
      if (editingQuestion) {
        await Api.putQuestion({ 'X-api-authenticate': apiKey }, editingQuestion.id, jobId, {
          field: fieldObject
        });
        setSnackbar({
          message: 'Field has been updated',
          state: 'success'
        });
      } else {
        await Api.newField({ 'X-api-authenticate': apiKey }, jobId, {
          field: fieldObject
        });
        setSnackbar({
          message: 'Field has been created',
          state: 'success'
        });
        clearData();
      }
      handleClose();
    } catch (error) {
      const errMsg = error.errors.split(',').slice(2).join(',') || error.errors;
      setSnackbar({
        message: errMsg,
        state: 'error'
      });
    } finally {
      setIsCreating(false);
      getQuestions();
    }
  };

  const handleAddChoice = () => {
    const newTempId = Date.now();
    setChoiceList([...choiceList, { id: newTempId, _destroy: 0, position: null, name: '' }]);
  };

  const onRemoveChoice = (index: number) => {
    const list = [...choiceList];
    if (list[index].position !== undefined && list[index].position !== null) {
      list[index]._destroy = 1;
    } else {
      list.splice(index, 1);
    }
    setChoiceList(list);
  };

  const handleChoiceInput = (e, index: number) => {
    const { name, value } = e.target;
    const list = [...choiceList];
    list[index].name = value;
    setChoiceList(list);
  };

  const handleSelectRating = (value, index: number) => {
    const list = [...choiceList];
    list[index].rating = value;
    setChoiceList(list);
  };

  const handleRankInput = (value, index: number) => {
    const list = [...choiceList];
    list[index].rank = value;
    setChoiceList(list);
  };

  const handleSelectRankValue = (value, index: number) => {
    const list = [...choiceList];
    list[index].rank_value = value;
    setChoiceList(list);
  };

  const clearData = () => {
    setFieldType('');
    setLabel('');
    setEnabledSwitch(true);
    setRequiredSwitch(false);
    setRateableSwitch(false);
    setConfidentialSwitch(false);
    setChoiceList([{ id: '', _destroy: 0, position: 0, name: '' }]);
    setWeekDuration('weekly');
    setWeekStartDay(0);
    setHeadingOne('');
    setHeadingTwo('');
    setAllowBreak(false);
    setErrorLabel('');
    setErrorField('');
    setErrorChoices('');
    setFelixAISwitch(false);
  };

  const showAiToggle = aiInterview && fieldType === 'Textarea';

  useEffect(() => {
    setDefaultValue();
  }, [setDefaultValue, editingQuestion]);

  return (
    <Modal
      data-testid="new-field-modal"
      open={modalsOpen.newField}
      onClose={() => handleClose()}
      aria-labelledby="new-field-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <Box sx={classes.modalWrapper}>
          <CloseIcon onClick={() => handleClose()} sx={classes.closeIcon} />
          <Box id="new-field-title" sx={classes.actionModalHeader}>
            New question
          </Box>
          <Box
            sx={classes.modalContent}
            id="new-field-modal-content"
            aria-label="New Field Modal Content"
          >
            <Box role="form" id="new-field-form" aria-label="New Field form">
              <Box sx={{ ...classes.actionModalFormLine, marginTop: '20px' }} ref={fieldTypeRef}>
                <Virtualize
                  value={fieldType}
                  passOptions={fieldTypeOptions}
                  setValue={(value: string) => {
                    setFieldType(value);
                    setErrorField('');
                  }}
                  passedStyles={{
                    ...sharedClasses.formAutocomplete,
                    width: '300px',
                    marginTop: '20px'
                  }}
                  label="Question Type"
                />
                {errorField && <Box sx={{ ...classes.formError, top: '63px' }}>{errorField}</Box>}
              </Box>
            </Box>
            <Box sx={{ marginLeft: '15px' }}>
              <Box sx={classes.modalFormLine}>
                <MultilineFormTextField
                  dataTestId="field-label"
                  value={label}
                  onChange={(e) => {
                    setErrorLabel('');
                    setLabel(e.target.value);
                  }}
                  styles={{ width: '600px', marginTop: '20px' }}
                  rows={6}
                  innerRef={labelRef}
                  error={errorLabel}
                  label={'Question'}
                />
              </Box>
            </Box>
            <Box sx={classes.checkboxContainer} id="enabled-filter">
              <FormControlLabel
                sx={
                  !enabledSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    data-testid="enable-switch"
                    sx={enabledSwitch ? classes.switchActive : classes.switch}
                    checked={enabledSwitch}
                    onChange={() => setEnabledSwitch(!enabledSwitch)}
                  />
                }
                label="Enabled"
                labelPlacement="end"
              />
              <FormControlLabel
                sx={
                  !requiredSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={requiredSwitch ? classes.switchActive : classes.switch}
                    checked={requiredSwitch}
                    onChange={() => setRequiredSwitch(!requiredSwitch)}
                  />
                }
                label="Required"
                labelPlacement="end"
              />
              <FormControlLabel
                sx={
                  !rateableSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={rateableSwitch ? classes.switchActive : classes.switch}
                    checked={rateableSwitch}
                    onChange={() => setRateableSwitch(!rateableSwitch)}
                  />
                }
                label="Rateable"
                labelPlacement="end"
              />
              <FormControlLabel
                sx={
                  !confidentialSwitch
                    ? { ...classes.switchLabels, ...classes.labelActive }
                    : classes.switchLabels
                }
                control={
                  <Switch
                    sx={confidentialSwitch ? classes.switchActive : classes.switch}
                    checked={confidentialSwitch}
                    onChange={() => setConfidentialSwitch(!confidentialSwitch)}
                  />
                }
                label="Confidential"
                labelPlacement="end"
              />

              {showAiToggle && (
                <FormControlLabel
                  sx={
                    !felixAISwitch
                      ? { ...baseSwitchStyle, ...classes.labelActive }
                      : baseSwitchStyle
                  }
                  control={
                    <Switch
                      sx={confidentialSwitch ? classes.switchActive : classes.switch}
                      checked={felixAISwitch}
                      onChange={() => setFelixAISwitch(!felixAISwitch)}
                    />
                  }
                  label="Felix AI Feedback"
                  labelPlacement="end"
                />
              )}
            </Box>
            {['Select', 'Rank', 'Checkbox', 'Radio Button'].includes(fieldType) && (
              <Box sx={classes.addChoiceContainer} ref={choicesRef}>
                <Box sx={classes.addChoiceHead}>
                  <Box id="add-choice-title" sx={classes.addChoiceLabel}>
                    Choices
                  </Box>
                  <Button
                    id="add-choice-button"
                    sx={classes.modalAddChoiceButton}
                    onClick={handleAddChoice}
                  >
                    Add choice
                  </Button>
                </Box>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext items={choiceList} strategy={verticalListSortingStrategy}>
                    {choiceList.map(
                      (choice, index) =>
                        !(choice?._destroy === 1) && (
                          <SortableChoice
                            key={choice.id}
                            id={choice.id}
                            choice={choice}
                            index={index}
                            handleChoiceInput={handleChoiceInput}
                            handleSelectRating={handleSelectRating}
                            handleRankInput={handleRankInput}
                            handleSelectRankValue={handleSelectRankValue}
                            onRemoveChoice={onRemoveChoice}
                            editingQuestion={editingQuestion}
                            rateableSwitch={rateableSwitch}
                            confidentialSwitch={confidentialSwitch}
                            autoRating={autoRating}
                            userPermissions={userPermissions}
                            fieldType={fieldType}
                          />
                        )
                    )}
                  </SortableContext>
                </DndContext>
                {errorChoices && <Box sx={{ ...classes.inlineError }}>{errorChoices}</Box>}
              </Box>
            )}
            {['Availability'].includes(fieldType) && (
              <Box sx={classes.availabilityContainer}>
                <Box id="availability-config-title" sx={classes.addChoiceLabel}>
                  Availability Config
                </Box>
                <Box sx={classes.config}>
                  <FormSelectField
                    options={['Weekly', 'Fortnightly']}
                    defaultValue="Weekly"
                    value={weekDuration}
                    onChange={(value) => setWeekDuration(value)}
                    label={'How long is your work period?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                    required={true}
                  />
                  <FormSelectField
                    options={DateOptions}
                    defaultValue="Sunday"
                    value={weekStartDay}
                    onChange={(value) => {
                      setWeekStartDay(value);
                    }}
                    label={'When does the work period start?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormTextField
                    label="Heading 1"
                    value={headingOne}
                    onChange={(e) => setHeadingOne(e.target.value)}
                    required={false}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormTextField
                    label="Heading 2"
                    value={headingTwo}
                    onChange={(e) => setHeadingTwo(e.target.value)}
                    required={false}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                  <FormSelectField
                    options={['Yes', 'No']}
                    defaultValue="No"
                    value={allowBreak}
                    onChange={(value) => setAllowBreak(value)}
                    label={'Allow break?'}
                    styles={{ width: '290px', marginBottom: '15px' }}
                  />
                </Box>
              </Box>
            )}
            <Box sx={classes.modalFooter}>
              <Button
                id="close-new-field-button"
                sx={classes.modalCancelButton}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-field-button"
                data-testid="confirm-button"
                type="submit"
                sx={{ ...classes.modalCreateButton, width: '91px', height: '41px' }}
                onClick={() => handleSubmit(jobId)}
              >
                {isCreating ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
