import React from 'react';
import { Stack } from '@mui/material';
import { styles } from './styles';

export default function Export({ onClick }: { onClick: () => void }) {
  return (
    <Stack sx={styles.exportButton} onClick={onClick}>
      Export
    </Stack>
  );
}
