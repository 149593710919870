import { Box, Link } from '@mui/material';
import React from 'react';
import { theme } from '../../../../../ThemeContext/ThemeObject';
import { ITask } from '../../../types';
import {
  Task as TaskIcon,
  AccessTime as AccessTimeIcon,
  CheckCircleOutline as CheckCircleOutlineIcon
} from '@mui/icons-material';
import dayjs from 'dayjs';

interface IProps {
  data: ITask;
  handleCompleteTask: (id: number, completed: boolean) => void;
}

export function TaskItem({ data, handleCompleteTask }: IProps) {
  const isCompleted = dayjs().isAfter(dayjs(data?.completed_at));

  function getColor(priority: IProps['data']['priority']) {
    if (priority === 'High') {
      return theme.palette.error.main;
    }
    if (priority === 'Normal') {
      return '#E6A052';
    }
    if (priority === 'Low') {
      return theme.palette.success.main;
    }
    return '';
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
      <Box flexBasis="40%" display="flex" flexDirection="row">
        <Box mr={2}>
          <Box sx={{ ...styles.icon, backgroundColor: `${getColor(data.priority)}33` }}>
            <TaskIcon sx={{ color: getColor(data.priority) }} fontSize="small" />
          </Box>
        </Box>
        <Box>
          <Box>
            <Link href={`/admin/tasks/${data.id}`} target="_blank" sx={styles.subject}>
              {data.subject}
            </Link>
          </Box>
          <Box mt="3px">
            <Box sx={styles.description}>{data.taskable_type}</Box>
          </Box>
        </Box>
      </Box>
      <Box ml={2} gap={1} display="flex" flexDirection="row" flexShrink={0}>
        <Box
          sx={{
            ...styles.tag,
            ...styles.priorityTag,
            color: getColor(data.priority),
            backgroundColor: `${getColor(data.priority)}33`
          }}
        >
          {data.priority} Priority
        </Box>
        <Box sx={{ ...styles.tag, ...styles.dateTag }}>
          <AccessTimeIcon sx={{ fontSize: '10px', color: '#666', marginRight: '4px' }} />
          {dayjs(data.due_at).format('ddd, DD MMM YYYY, h:mma')}
        </Box>
        <Box sx={styles.checkTag} onClick={() => handleCompleteTask(data.id, !isCompleted)}>
          <CheckCircleOutlineIcon
            sx={{ color: isCompleted ? theme.palette.success.main : '#DDD' }}
          />
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  icon: {
    borderRadius: '6px',
    padding: '6px'
  },
  subject: {
    fontSize: '15px',
    fontWeight: '700',
    color: '#333 !important'
  },
  description: {
    color: '#666'
  },
  tag: {
    padding: '0 10px',
    borderRadius: '14px',
    fontSize: '9px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0
  },
  priorityTag: {
    textTransform: 'uppercase',
    fontWeight: '700',
    userSelect: 'none'
  },
  dateTag: {
    backgroundColor: '#F3F3F3',
    color: '#666'
  },
  checkTag: {
    '&:hover': {
      cursor: 'pointer',
      '& svg': {
        opacity: 0.7
      }
    }
  }
};
