var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withScoutTheme } from "../../ThemeContext/ThemeContext";
import { Step1, Step2, Step3 } from "./Steps";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Dialog,
  Snackbar,
  CircularProgress
} from "@material-ui/core";
import useStyles from "./useStyles";
import sharedStyles from "../AdList/useStyles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import moment from "moment";
const steps = ["Classify Job", "Job Details", "Preview Ad"];
const salaryTypes = [
  ["Range", "Range"],
  ["Exact Rate", "ExactRate"]
];
function AdEditor({ jobAdParams }) {
  const [saving, setSaving] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [answers, setAnswers] = useState(updateAnswers(jobAdParams));
  const [activeError, setActiveError] = useState(null);
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const QontoConnector = withStyles((theme) => ({
    alternativeLabel: {
      top: 25,
      left: "calc(-50% + 25px)",
      right: "calc(50% + 25px)"
    },
    active: {
      "& $line": {
        borderColor: theme.palette.secondary.main
      }
    },
    completed: {
      "& $line": {
        borderColor: theme.palette.secondary.main
      }
    },
    line: {
      borderColor: "#F3F3F3",
      borderTopWidth: 3,
      borderRadius: 1
    }
  }))(StepConnector);
  function updateAnswers(params) {
    return {
      companyName: params.company_name || "",
      industry: params.industry_options.find((param) => param[1] === params.listing_classification) || "",
      subIndustry: params.listing_classification ? params.sub_industry_options[params.listing_classification].find(
        (param) => param[1] === params.listing_sub_classification
      ) : "",
      jobTitle: params.title || "",
      reportToWork: params.report_to ? "true" : "false",
      address: params.address || "",
      city: params.city || "",
      state: params.state || "",
      country: params.country || "",
      language: params.language || "",
      remoteWork: params.remote || params.remote_type_options[0][0],
      hires: params.number_of_hires || 1,
      hiring: params.always_hiring === null ? false : params.always_hiring,
      employmentType: params.work_type_options.filter((param) => params.work_type.includes(param[1])).map((param) => param[1]) || [],
      schedule: params.schedules_options.filter((param) => params.schedules.includes(param[1])).map((param) => param[1]) || [],
      isStartDate: params.start_date ? true : false,
      startDate: params.start_date ? moment(params.start_date).format("LL") : "",
      salaryTypes,
      selectedSalaryType: salaryTypes.find((param) => param[1] === params.salary_type) || salaryTypes[0],
      rangeTo: params.salary_max ? params.salary_max : "",
      rangeFrom: params.salary_min ? params.salary_min : "",
      rangeUnit: params.payment_frequencies_options.find((param) => param[1] === params.salary_frequency) || params.payment_frequencies_options[0],
      additionalPay: params.additional_payment_options.filter((param) => params.additional_payments.includes(param[1])).map((param) => param[1]) || [],
      benefits: params.salary_benefit_options.filter((param) => params.salary_benefits.includes(param[1])).map((param) => param[1]) || [],
      submitResume: params.require_resume || "required",
      isApplicationDeadline: params.application_deadline ? true : false,
      applicationDeadline: params.application_deadline ? moment(params.application_deadline).format("LL") : "",
      startConversation: params.applicant_starts_conversation,
      covidPrecautions: params.covid_precautions || "",
      jobReference: params.id || "",
      askQualifications: params.applicant_qualifications,
      description: handleDraftValue(params.description) || "",
      ad_preview: params.ad_preview || ""
    };
  }
  function submit(type) {
    return __async(this, null, function* () {
      try {
        type === "draft" ? setSavingDraft(true) : setSaving(true);
        const token = document.querySelector('meta[name="csrf-token"]').content;
        const { data } = yield axios.put(`/admin/job_ads/${jobAdParams.id}/indeed_ads/builder`, {
          headers: { "Content-Type": "application/json" },
          authenticity_token: token,
          page: activeStep + 1,
          job_ad: {
            additional_payments: answers.additionalPay || null,
            listing_location: answers.address || null,
            always_hiring: answers.hiring || false,
            applicant_qualifications: answers.askQualifications || false,
            applicant_starts_conversation: answers.startConversation || false,
            application_deadline: moment(answers.applicationDeadline).format() || null,
            city: answers.city || null,
            company_name: answers.companyName || null,
            country: answers.country || null,
            covid_precautions: answers.covidPrecautions || null,
            ad_details: draftToHtml(convertToRaw(answers.description.getCurrentContent())) || null,
            language: answers.language || null,
            listing_classification: answers.industry && answers.industry[1] || null,
            listing_sub_classification: answers.subIndustry && answers.subIndustry[1] || null,
            work_type: answers.employmentType || null,
            number_of_hires: answers.hires || null,
            remote: answers.remoteWork || null,
            report_to: answers.reportToWork || null,
            require_resume: answers.submitResume || null,
            salary_benefits: answers.benefits || null,
            salary_frequency: answers.rangeUnit && answers.rangeUnit[0] || null,
            salary_max: parseFloat(answers.rangeTo) || null,
            salary_min: parseFloat(answers.rangeFrom) || null,
            salary_type: answers.selectedSalaryType && answers.selectedSalaryType[1] || null,
            schedules: answers.schedule || null,
            start_date: moment(answers.startDate).format() || null,
            state: answers.state || null,
            title: answers.jobTitle || null,
            approved: type === "submit" ? true : false
          }
        });
        if (type === "draft") {
          handleSnack("Draft saved");
          setIsExitModalOpen(false);
          window.history.back();
        } else if (type === "submit") {
          setIsSuccessDialogOpen(true);
        } else if (type === "next") {
          setAnswers(updateAnswers(data));
          setActiveStep(activeStep + 1);
        }
      } catch (e) {
        if (type === "draft") {
          handleSnack("Unable to save draft");
        } else if (type === "submit") {
          handleSnack("Unable to post to Indeed. Please check required fields and try again.");
        } else if (type === "next") {
          setActiveStep(activeStep + 1);
        }
      } finally {
        setSaving(false);
        setSavingDraft(false);
      }
    });
  }
  function handleDraftValue(e) {
    const contentBlock = htmlToDraft(e);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  }
  function handleSnack(message) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }
  const requiredRefs = {
    companyName: useRef(null),
    industry: useRef(null),
    jobTitle: useRef(null),
    address: useRef(null),
    hires: useRef(null),
    employmentType: useRef(null),
    schedule: useRef(null),
    startDate: useRef(null),
    salary: useRef(null),
    description: useRef(null),
    jobReference: useRef(null)
  };
  function handleNext(type) {
    const errorRef = checkErrors();
    if (errorRef) {
      const ref = requiredRefs[errorRef];
      setActiveError(ref);
      window.scrollTo({ top: ref.current.offsetTop - 120, behavior: "smooth" });
    } else submit(type);
  }
  function checkErrors() {
    const error = Object.keys(requiredRefs).find((ref) => {
      if (activeStep === 0) {
        if (["companyName", "industry", "jobTitle"].includes(ref)) {
          return !answers[ref] && ref;
        } else if (ref === "address") {
          return !answers.address || !answers.city || !answers.state && ref;
        } else if (ref === "hires") {
          return !answers.hiring && !answers.hires && ref;
        }
      } else if (activeStep === 1) {
        if (["schedule", "employmentType"].includes(ref)) {
          return answers[ref] && answers[ref].length <= 0 && ref;
        } else if (ref === "startDate") {
          return answers.isStartDate && !answers.startDate && ref;
        } else if (ref === "salary") {
          return answers.rangeTo < answers.rangeFrom && ref;
        } else if (ref === "description") {
          return answers.description.getCurrentContent().getPlainText("").length === 0 && ref;
        } else if (ref === "jobReference") {
          return !answers.jobReference && ref;
        }
      }
    });
    return error;
  }
  useEffect(() => {
    setActiveError(null);
  }, [answers]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeStep]);
  return /* @__PURE__ */ React.createElement("div", { className: classes.containerOuter }, /* @__PURE__ */ React.createElement("div", { className: classes.container }, /* @__PURE__ */ React.createElement("div", { className: classes.stepSelect }, /* @__PURE__ */ React.createElement(
    Stepper,
    {
      classes: { root: classes.stepperRoot },
      alternativeLabel: true,
      activeStep,
      connector: /* @__PURE__ */ React.createElement(QontoConnector, null)
    },
    steps.map((step) => /* @__PURE__ */ React.createElement(Step, { key: step, classes: { root: `${classes.stepItem}` } }, /* @__PURE__ */ React.createElement(
      StepLabel,
      {
        classes: {
          iconContainer: classes.stepLabelIconContainer,
          label: classes.stepLabelLabel,
          active: classes.stepLabelActive,
          completed: classes.stepLabelCompleted
        },
        StepIconProps: {
          classes: {
            root: classes.stepIconRoot,
            active: classes.stepIconActive,
            completed: classes.stepIconCompleted
          }
        }
      },
      step
    )))
  )), activeStep === 0 && /* @__PURE__ */ React.createElement(
    Step1,
    {
      jobAdParams,
      answers,
      setAnswers,
      requiredRefs,
      activeError
    }
  ), activeStep === 1 && /* @__PURE__ */ React.createElement(
    Step2,
    {
      jobAdParams,
      answers,
      setAnswers,
      handleDraftValue,
      requiredRefs,
      activeError
    }
  ), activeStep === 2 && /* @__PURE__ */ React.createElement(
    Step3,
    {
      setActiveStep,
      jobAdParams,
      answers,
      setAnswers
    }
  ), /* @__PURE__ */ React.createElement("div", { className: classes.buttonContainer }, /* @__PURE__ */ React.createElement("a", { onClick: () => setIsExitModalOpen(true) }, "Save and exit"), /* @__PURE__ */ React.createElement("div", { className: classes.buttons }, activeStep !== 0 && /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: sharedClasses.buttonSecondaryLight,
      onClick: () => setActiveStep(activeStep - 1)
    },
    /* @__PURE__ */ React.createElement(ChevronLeftIcon, null)
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      disableElevation: true,
      className: sharedClasses.buttonSecondary,
      style: { minWidth: 130 },
      onClick: () => handleNext(activeStep + 1 === steps.length ? "submit" : "next"),
      endIcon: saving ? null : /* @__PURE__ */ React.createElement(ChevronRightIcon, null)
    },
    saving ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : activeStep + 1 === steps.length ? "Submit" : "Next"
  )))), /* @__PURE__ */ React.createElement(
    Dialog,
    {
      open: isExitModalOpen,
      onClose: () => setIsExitModalOpen(false),
      classes: { paper: classes.dialog }
    },
    /* @__PURE__ */ React.createElement("h4", null, "Are you sure?"),
    /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer }, /* @__PURE__ */ React.createElement(
      Button,
      {
        disableElevation: true,
        className: sharedClasses.buttonSecondaryLight,
        onClick: () => setIsExitModalOpen(false)
      },
      "Cancel"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        disableElevation: true,
        className: sharedClasses.buttonSecondary,
        onClick: () => submit("draft"),
        style: { minWidth: 130 }
      },
      savingDraft ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : "Save and exit"
    ))
  ), /* @__PURE__ */ React.createElement(
    Dialog,
    {
      open: isSuccessDialogOpen,
      onClose: () => window.history.back(),
      classes: { paper: classes.successDialog }
    },
    /* @__PURE__ */ React.createElement("div", { className: classes.successCheck }, /* @__PURE__ */ React.createElement(CheckCircleIcon, { color: "primary", fontSize: "large" })),
    /* @__PURE__ */ React.createElement("h4", null, "Success"),
    /* @__PURE__ */ React.createElement("span", null, "Your ad has been posted to Indeed"),
    /* @__PURE__ */ React.createElement("div", { className: classes.dialogButtonContainer, style: { marginTop: 20 } }, /* @__PURE__ */ React.createElement(
      Button,
      {
        disableElevation: true,
        className: sharedClasses.buttonSecondaryLight,
        onClick: () => window.history.back()
      },
      "Back to ads"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        disableElevation: true,
        className: sharedClasses.buttonSecondary,
        onClick: () => window.location = `/admin/indeed/job_ads/${jobAdParams.id}/sponsored_job/edit/`
      },
      "Sponsor job"
    ))
  ), /* @__PURE__ */ React.createElement(Snackbar, { open: isSnackOpen, onClose: () => setIsSnackOpen(false), message: snackMessage }));
}
AdEditor.propTypes = {
  jobAdParams: PropTypes.object.isRequired
};
export default withScoutTheme(AdEditor);
