var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import { Radio, Grid, CircularProgress, withStyles } from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import axios from "axios";
const PinkRadio = withStyles({
  root: {
    "&$checked": {
      color: "#E60278"
    }
  },
  checked: {}
})((props) => /* @__PURE__ */ React.createElement(Radio, __spreadValues({ color: "default" }, props)));
function AdSelection({
  answers,
  setAnswers,
  requiredRefs,
  jobAdId,
  setMaxBulletPoints,
  setBrandingEnabled
}) {
  const [getSeekAdSelectionData, setGetSeekAdSelectionData] = useState(null);
  const [loadingSeekAdType, setLoadingSeekAdType] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState(answers.ad_type);
  const classes = useStyles();
  const handleChangeAdType = (event) => {
    const adType = event.target.value;
    setSelectedValue(adType);
    setAnswers(__spreadProps(__spreadValues({}, answers), { ad_type: adType }));
  };
  useEffect(() => {
    if (selectedValue === "Classic") {
      setMaxBulletPoints(0);
      setBrandingEnabled(false);
    } else {
      setMaxBulletPoints(3);
      setBrandingEnabled(true);
    }
  }, [selectedValue, setMaxBulletPoints, setBrandingEnabled]);
  useEffect(() => {
    const getSeekAdType = (url) => __async(this, null, function* () {
      setLoadingSeekAdType(true);
      try {
        const { data } = yield axios.get(url, {
          params: {
            listing_sub_classification: answers.listing_sub_classification,
            listing_location: answers.listing_location,
            title: answers.title,
            job_ad_id: jobAdId
          }
        });
        setGetSeekAdSelectionData(data.data.seekAnzHirerAdvertisementCreationProducts);
      } catch (exception) {
        console.error(exception);
        setGetSeekAdSelectionData(null);
      } finally {
        setLoadingSeekAdType(false);
      }
    });
    answers.listing_sub_classification && answers.title && answers.listing_location && getSeekAdType(`/api/v4/seek/job_ads/${jobAdId}/ad_selections`);
  }, [answers.listing_location, answers.listing_sub_classification, answers.title, jobAdId]);
  if (loadingSeekAdType) {
    return /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" });
  }
  return /* @__PURE__ */ React.createElement(Grid, { container: true }, getSeekAdSelectionData && getSeekAdSelectionData.map((ad, index) => /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 4, key: index }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: !ad.enabledIndicator ? classes.seekAdTypeDisabled : classes.seekAdType,
      onClick: () => {
        if (!ad.enabledIndicator) {
          return;
        }
        setSelectedValue(ad.advertisementTypeCode);
        setAnswers(__spreadProps(__spreadValues({}, answers), { ad_type: ad.advertisementTypeCode }));
      }
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "seekAdheaderWrapper",
        style: index === 0 || index === 2 ? { borderRadius: index === 0 ? "5px 0 0 0" : "0 5px 0 0" } : {}
      },
      "Pay using your SEEK Contract"
    ),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: selectedValue === ad.advertisementTypeCode && ad.enabledIndicator ? { border: "#E60278 1px solid", minHeight: "12.75rem" } : { border: "#E9E8E8 1px solid", minHeight: "12.5rem" }
      },
      /* @__PURE__ */ React.createElement("div", { className: "seekRadioRadioBtnWrapper", ref: requiredRefs.ad_type }, /* @__PURE__ */ React.createElement(
        PinkRadio,
        {
          checked: selectedValue === ad.advertisementTypeCode && ad.enabledIndicator,
          onChange: handleChangeAdType,
          value: ad.advertisementTypeCode,
          disabled: !ad.enabledIndicator,
          name: "radio-buttons",
          inputProps: { "aria-label": "A" }
        }
      )),
      /* @__PURE__ */ React.createElement("h4", null, ad.advertisementTypeCode),
      /* @__PURE__ */ React.createElement("div", null, ad.price.summary),
      /* @__PURE__ */ React.createElement(
        "div",
        {
          className: classes.seekEmbeddedHTML,
          dangerouslySetInnerHTML: { __html: ad.description }
        }
      )
    )
  ))));
}
AdSelection.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  jobAdId: PropTypes.number,
  setMaxBulletPoints: PropTypes.func.isRequired,
  setBrandingEnabled: PropTypes.func.isRequired
};
export default AdSelection;
