"use strict";
export const classes = {
  systemMessage: {
    backgroundColor: "#FFFFFF",
    color: "#2D388D",
    fontWeight: "400",
    width: "fit-content",
    margin: "10px 0",
    maxWidth: "100%",
    borderRadius: "20px",
    border: "1.5px solid #2D388D",
    display: "flex",
    alignItems: "baseline",
    fontSize: "18px !important",
    padding: "6px 24px !important",
    "&& ul li": {
      fontWeight: "600 !important",
      color: "#2D388D !important"
    }
  },
  responseMessageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: "20px",
    position: "relative"
  },
  responseMessageContent: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9"
  },
  closeIcon: {
    padding: 0,
    alignSelf: "flex-end"
  }
};
