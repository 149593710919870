import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { classes } from './styles';
import NoQuestionSVG from './NoQuestionSVG';
import NoQuestionArrowSVG from './NoQuestionArrowSVG';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { IPermissions, IJob } from '../../Job/types';
import { QuestionCardSensor } from './QuestionCardSensor';
import { IApplicantQuestion } from '../types';

interface IQuestionListProps {
  apiKey: string;
  aiInterview: boolean;
  enabled: boolean;
  getQuestions: () => Promise<void>;
  handleDragEnd: (event: DragEndEvent) => void;
  isLoading: boolean;
  job: IJob;
  jobId: number;
  questions: IApplicantQuestion[];
  setEditingQuestion: Dispatch<SetStateAction<IApplicantQuestion | null>>;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  setQuestions: Dispatch<SetStateAction<IApplicantQuestion[]>>;
  setSnackbar: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  userPermissions: IPermissions;
}

export const QuestionList: FC<IQuestionListProps> = ({
  apiKey,
  aiInterview,
  enabled,
  getQuestions,
  handleDragEnd,
  isLoading,
  job,
  jobId,
  questions,
  setEditingQuestion,
  setModalsOpen,
  setQuestions,
  setSnackbar
}) => {
  const filteredQuestions = questions.filter((question) => question.enabled === enabled);
  const sensors = useSensors(
    useSensor(QuestionCardSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  if (!isLoading && !filteredQuestions.length) {
    return (
      <Box sx={{ ...classes.noQuestionsContainer, margin: '100px 0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: '100px' }}></Box>
          <NoQuestionSVG />
          <NoQuestionArrowSVG />
        </Box>
        <Box sx={classes.noQuestionsText}>
          You have no questions
          <br />
          Choose an options above to start creating questions
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={classes.questionsContainer}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={filteredQuestions.map((q) => q.id)}
          strategy={verticalListSortingStrategy}
        >
          {filteredQuestions.map((question, index) => (
            <SortableItem
              aiInterview={aiInterview}
              allQuestions={questions}
              apiKey={apiKey}
              applicationCount={job.applications_count}
              editingQuestion={question}
              getQuestions={getQuestions}
              index={index}
              jobId={jobId}
              key={question.id}
              questions={filteredQuestions}
              setEditingQuestion={setEditingQuestion} //TODO: Fix types in SortableItem
              setModalsOpen={setModalsOpen}
              setQuestions={setQuestions}
              setSnackbar={setSnackbar}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Box>
  );
};
