import React from 'react';
import SelectField from '../../../Components/CustomUIElements/SelectField';
import { DELAY_OPTIONS } from '../../../constants';

export default function DelaySelect({
  delay,
  setDelay
}: {
  delay: string;
  setDelay: (value: string) => void;
}) {
  const valueWithFallback = DELAY_OPTIONS.find((option) => option === delay) || DELAY_OPTIONS[0];

  return (
    <SelectField
      id='delay-select'
      label='Delay'
      options={DELAY_OPTIONS}
      value={valueWithFallback}
      onChange={setDelay}
      styles={{ width: '290px' }}
    />
  );
}
