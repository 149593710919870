import React from 'react';
import { Button, Stack } from '@mui/material';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { theme } from '../../../ThemeContext/ThemeObject';

export default function OldIntegrationsLink() {
  return (
    <Stack sx={{ alignItems: 'center', rowGap: 3, paddingTop: 5 }}>
      <Stack sx={{ alignItems: 'center', rowGap: 0.75, color: theme.palette.common.grey }}>
        <Stack sx={{ fontSize: '19px', fontWeight: 'bold' }}>
          {`Can't see one of your integrations?`}
        </Stack>
        <Stack>{`Some integrations haven't been moved over yet.`}</Stack>
        <Stack>Click below to access them on the old version</Stack>
      </Stack>
      <Button
        id="old-version-button"
        sx={sharedClasses.genericButtonSecondary}
        onClick={() =>
          window.open(
            `${window.location.href.replace('new_applications', 'applications')}#application-activities`,
            '_blank'
          )
        }
      >
        Go to the old version
      </Button>
    </Stack>
  );
}
