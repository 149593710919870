import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  MenuItem,
  FormControl,
  Select,
  Popover
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import AdTable from './AdTable';
import TableSkeleton from '../TableSkeleton';
import GenericDialog from '../GenericDialog';
import CreateAdDialog from './CreateAdDialog';
import { classes } from '../styles';
import { IJobAds } from '../types';
import { useSeekAccounts } from '../../../../SeekAd/hooks/useSeekAccounts';
import { useInitSeekAd } from '../../../../SeekAd/hooks/useInitSeekAd';

const ADTYPES = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'draft',
    name: 'Draft'
  },
  {
    id: 'expired',
    name: 'Expired'
  },
  {
    id: 'archived',
    name: 'Archived'
  }
];

export default function SeekAds({ apiKey, jobId }: { apiKey: string; jobId: string | null }) {
  const [ads, setAds] = useState<IJobAds>(null);
  const [adminUser, setAdminUser] = useState(false);
  const [page, setPage] = useState(1);
  const [maxAds, setMaxAds] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAdType, setSelectedAdType] = useState(ADTYPES[0].id);
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [createAdDialogOpen, setCreateAdDialogOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { seekAccounts } = useSeekAccounts({ jobId });
  // TODO: Remove after finishing seek integration upgrade
  const { mutate, jsonResponse } = useInitSeekAd();

  useEffect(() => {
    if (jsonResponse.url) {
      window.location.href = jsonResponse.url;
      setErrorModalOpen(false);
    } else if (jsonResponse.errors) {
      setErrorMessage(jsonResponse.errors ? jsonResponse.errors[0] : 'Error creating ad');
      setErrorModalOpen(true);
    } else {
      setErrorModalOpen(false);
    }
  }, [jsonResponse]);

  const getInitialAds = useCallback(async () => {
    setLoading(true);
    const url = jobId
      ? `/api/v4/seek/jobs/${jobId}/job_ads?limit=100`
      : '/api/v4/seek/job_ads/active';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then(async (response) => {
        if (response.ok) {
          return {
            data: await response.json(),
            maxAds: Number(response.headers.get('X-Total-Count')),
            maxPages: Number(response.headers.get('X-Total-Pages'))
          };
        }
        return response.json().then((error) => {
          error.code = response.status;
          throw error;
        });
      });
      if (jobId !== null) {
        setAds(response.data.job_ads);
        setAdminUser(response.data.admin_user);
      } else {
        setAds(response.data);
        setMaxAds(response.maxAds);
        setMaxPages(response.maxPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, jobId]);

  const getAds = async (currentType: string, currentPage: number, currentRows: number) => {
    if (jobId !== null) return;
    setLoading(true);
    const url = `/api/v4/seek/job_ads/${currentType}?page=${currentPage}&limit=${currentRows}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then(async (response) => {
        if (response.ok) {
          return {
            data: await response.json(),
            maxAds: Number(response.headers.get('X-Total-Count')),
            maxPages: Number(response.headers.get('X-Total-Pages'))
          };
        }
        return response.json().then((error) => {
          error.code = response.status;
          throw error;
        });
      });
      setAds(response.data);
      setMaxAds(response.maxAds);
      setMaxPages(response.maxPages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdTypeChange = (adType: string) => {
    setFilterAnchorEl(null);
    if (adType === selectedAdType) return;
    setSelectedAdType(adType);
    setPage(1);
    getAds(adType, 1, rowsPerPage);
  };

  const handlePageChange = (event: React.ChangeEvent, newPage: number) => {
    if (newPage === page) return;
    setPage(newPage);
    getAds(selectedAdType, newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getAds(selectedAdType, page, selection);
  };

  const handleCreateAd = () => {
    if (seekAccounts && seekAccounts.length > 0) {
      setCreateAdDialogOpen(true);
    } else {
      mutate(jobId);
    }
  };

  const setError = (error: string) => {
    setErrorModalOpen(true);
    setErrorMessage(error);
  };

  useEffect(() => {
    getInitialAds();
  }, [getInitialAds]);

  return (
    <Box sx={classes.containerOuter}>
      <Box sx={classes.container}>
        <Box sx={classes.banner}>
          <Button
            sx={classes.filterButton}
            startIcon={<SortIcon sx={{ marginRight: '8px' }} />}
            onClick={(e) => setFilterAnchorEl(e.currentTarget)}
          >
            {selectedAdType + ' Ads'}
          </Button>
          {jobId && (
            <Button
              disableElevation
              sx={classes.newAdButton}
              id="SeekNewAdMultiposterBtn"
              onClick={handleCreateAd}
            >
              {loadingCreate ? <CircularProgress size={20} color="inherit" /> : 'New Ad'}
            </Button>
          )}
        </Box>
        <Box>
          {loading ? (
            <TableSkeleton />
          ) : (
            <AdTable
              jobAds={ads}
              adminUser={adminUser}
              setAds={setAds}
              adsStatus={selectedAdType}
              ads={ads[selectedAdType as keyof IJobAds]}
              isSingleJob={jobId !== null}
              apiKey={apiKey}
            />
          )}
        </Box>
        {!jobId && (
          <Box sx={classes.paginationParent}>
            <Box sx={classes.rowSelectContainer}>
              <span>Rows per page: </span>
              <FormControl>
                <Select
                  id="rows-per-page"
                  sx={classes.rowSelect}
                  value={rowsPerPage}
                  onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                  displayEmpty
                  MenuProps={{ sx: classes.paginationMenuItems }}
                >
                  <MenuItem id="first-rpp-item" value={10}>
                    10
                  </MenuItem>
                  <MenuItem id="second-rpp-item" value={20}>
                    20
                  </MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={classes.paginationContainer}>
              <Pagination
                id="pagination-menu"
                count={maxPages}
                page={page}
                defaultPage={Math.ceil(maxPages / 2)}
                siblingCount={0}
                onChange={handlePageChange}
                sx={classes.pagination}
              />
              <span id="total-jobs">{maxAds} Total</span>
            </Box>
          </Box>
        )}
      </Box>
      <Popover
        id="filter-menu-popover"
        sx={classes.filterMenu}
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={() => {
          setFilterAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {ADTYPES.map(
          (type) =>
            type.id !== selectedAdType && (
              <span
                key={type.name}
                onClick={() => handleAdTypeChange(type.id)}
                id={`${type.name}-select-seek-ad-button`}
              >
                {type.name}
              </span>
            )
        )}
      </Popover>
      <GenericDialog
        url=""
        callbackLoading={false}
        dialogAdID={0}
        setDialogOpen={setErrorModalOpen}
        isDialogOpen={errorModalOpen}
        buttonCallback={() => setErrorModalOpen(false)}
        title="Unable to create ad"
        description={errorMessage}
        buttonText="Ok"
      />
      <CreateAdDialog
        jobId={jobId}
        seekAccounts={seekAccounts}
        createAdDialogOpen={createAdDialogOpen}
        setCreateAdDialogOpen={setCreateAdDialogOpen}
        setError={setError}
      />
    </Box>
  );
}
