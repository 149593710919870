"use strict";
import { useRef, useState, useEffect } from "react";
export const useDragScroll = () => {
  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const startPoint = useRef({ x: 0, y: 0 });
  const scrollRef = useRef({ left: 0, top: 0 });
  useEffect(() => {
    const handleMouseDown = (event) => {
      if (ref.current) {
        setIsDragging(true);
        startPoint.current = { x: event.clientX, y: event.clientY };
        scrollRef.current = { left: ref.current.scrollLeft, top: ref.current.scrollTop };
        ref.current.style.cursor = "grabbing";
        ref.current.style.overflow = "auto";
        ref.current.style.userSelect = "none";
      }
    };
    const handleMouseMove = (event) => {
      if (isDragging && ref.current) {
        const dx = event.clientX - startPoint.current.x;
        const dy = event.clientY - startPoint.current.y;
        ref.current.scrollLeft = scrollRef.current.left - dx;
        ref.current.scrollTop = scrollRef.current.top - dy;
      }
    };
    const handleMouseUp = () => {
      if (ref.current) {
        setIsDragging(false);
        ref.current.style.cursor = "grab";
        ref.current.style.removeProperty("user-select");
      }
    };
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [isDragging]);
  return [ref];
};
