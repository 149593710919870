"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const styles = {
  noticeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "9px 16px",
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "8px",
    color: theme.palette.error.main,
    fontSize: "16px",
    margin: "16px",
    rowGap: "1px"
  },
  noticeIcon: {
    color: theme.palette.error.main,
    marginTop: "4px",
    fontSize: "16px"
  },
  toggleButtonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    width: "100%",
    marginBottom: "40px",
    padding: "0px 16px"
  },
  toggleButton: {
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "0px !important"
    },
    width: "180px",
    padding: "4px 17px !important",
    fontFamily: "Source Sans Pro",
    color: "#666666",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "12px",
    backgroundColor: "#E5E5E5",
    textTransform: "none",
    "&:focus": {
      outline: "none"
    }
  },
  label: {
    color: "#333333 !important",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif",
    marginBottom: "6px",
    overflowWrap: "anywhere"
  }
};
