import React from 'react';
import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from './styles';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';

const StyledModal = ({
  isOpen,
  handleClose,
  disableEnforceFocus = false,
  styleOverrides,
  modalTitle,
  label,
  children,
  subHeaderStyling = true,
  closeIconId
}: {
  isOpen: boolean;
  handleClose: () => void;
  styleOverrides?: Record<string, string>;
  modalTitle?: string;
  label: string;
  children: React.ReactNode;
  subHeaderStyling?: boolean;
  closeIconId?: string;
  disableEnforceFocus?: boolean;
}) => {
  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby={`${label}-modal`} disableEnforceFocus={disableEnforceFocus} >
      <Box sx={{ ...classes.modal, ...(styleOverrides && styleOverrides) }}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} id={closeIconId} />
        {modalTitle && (
          <Box sx={subHeaderStyling ? classes.modalHeader : sharedClasses.sectionHeader}>
            {modalTitle}
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default StyledModal;
