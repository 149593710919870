import React, { useState, Dispatch } from 'react';
import { Stack } from '@mui/material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { ModalType } from '../config';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { styles } from './styles';
import { SingleDatePicker } from '../../Components/CustomUIElements/SingleDatePicker';
import dayjs from 'dayjs';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

export default function ExportToOnboard({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string>('');
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { mutate: exportToOnboard, isLoading: exportingToOnboard } = useMutation({
    mutationFn: async () => {
      if (application && startDate) {
        const { res } = await Api.exportToOnboard(application.job.id, application.id, startDate);
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in exporting candidate to onboard, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  const handleSubmit = () =>
    startDate ? exportToOnboard() : setDateError('Please select a start date');

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.EXPORT_TO_ONBOARD}
      label="Export to Onboard modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to :Onboard</Stack>
        <Stack sx={{ paddingBottom: 5, paddingTop: 2, overflow: 'auto' }}>
          <SingleDatePicker
            value={startDate}
            label={`When will ${application?.candidate?.firstname} ${application?.candidate?.lastname} start?`}
            inputStyles={{ ...sharedClasses.datePicker, width: '50%' }}
            onChange={(value) => {
              if (!value?.isValid()) {
                setDateError('Please input a valid date');
              } else {
                setDateError('');
                setStartDate(dayjs(value.toDate()).toDate());
              }
            }}
            showTodayButton
            error={dateError}
            required
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={handleSubmit}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
          isLoading={exportingToOnboard}
        />
      </Stack>
    </StyledModal>
  );
}
