import React, { FC } from 'react';
import { Box, keyframes, SxProps } from '@mui/material';
import { classes } from '../styles';
import { DELAYS_IN_SECONDS } from '../constants';

const blink = keyframes`
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
  `;

const BlinkingDot: FC<{ delay: number; style?: SxProps }> = ({ delay, style }) => (
  <Box
    sx={{
      animation: `${blink} 1s ${delay}s linear infinite`,
      fontSize: '30px',
      ...style
    }}
  >
    .
  </Box>
);

export const LoadingMessage = () => {
  return (
    <Box sx={classes.systemMessage}>
      Generating
      {DELAYS_IN_SECONDS.map((delay) => (
        <BlinkingDot key={delay} delay={delay} />
      ))}
    </Box>
  );
};
