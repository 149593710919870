import React, { useState, Dispatch } from 'react';
import { CircularProgress, Stack, Popover } from '@mui/material';
import {
  Circle as CircleIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { IApplication, ApplicationAction } from './types';
import { IApplicationStatus } from '../Job/types';
import { IUserPermissions } from '../Components/sharedTypes';
import Api from './API';
import { styles } from './styles';

export default function ApplicationStatusPopover({
  dispatch
}: {
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const { data: applicationStatuses } = useQuery({
    queryKey: ['application statuses'],
    queryFn: async () => {
      if (application?.job) {
        const { res } = await Api.getApplicationStatuses(application.job.id);
        return res.job_application_statuses as IApplicationStatus[];
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting application statuses, ${error}`,
          state: 'error'
        }
      }),
    initialData: [],
    enabled: !!application
  });

  const { mutate: updateApplicationStatus, isLoading: updatingApplicationStatus } = useMutation({
    mutationFn: async (id: number) => {
      if (application?.job) {
        const { res } = await Api.updateApplicationStatus(application.job.id, application.id, id);
        return res;
      }
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Application status has been successfully updated`, state: 'success' }
      });
      queryClient.invalidateQueries(['application'], { exact: true });

      queryClient.setQueryData(['application'], {
        ...application,
        status: res.name,
        status_details: res
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating application status, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const ArrowIcons = () =>
    anchorEl ? (
      <ArrowDropUpIcon sx={{ color: '#AAAAAA' }} />
    ) : (
      <ArrowDropDownIcon sx={{ color: '#AAAAAA' }} />
    );

  const enabled = permissions?.Applications?.['Change Status'];

  return (
    <Stack
      sx={{ ...styles.applicationStatusDropdownStyle, cursor: enabled ? 'pointer' : 'default' }}
      onClick={(e) =>
        enabled ? (anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)) : null
      }
    >
      <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
        <CircleIcon
          sx={{ fontSize: '0.5rem', color: application?.status_details?.colour || '#808080' }}
        />
        {application?.status}
      </Stack>
      {updatingApplicationStatus ? <CircularProgress size={15} /> : <ArrowIcons />}
      <Popover
        id={anchorEl ? 'status-selection' : undefined}
        sx={styles.applicationStatusPopoverStyle}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack sx={{ padding: '8px 0px' }}>
          {applicationStatuses
            ?.filter((status) => status.name !== application?.status)
            ?.map((status) => (
              <Stack
                key={status.id}
                onClick={() => updateApplicationStatus(status.id)}
                sx={{ fontSize: '12px', padding: '4px 16px', '&:hover': { background: '#F5F5F5' } }}
              >
                {status.name}
              </Stack>
            ))}
        </Stack>
      </Popover>
    </Stack>
  );
}
