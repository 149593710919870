import React, { Dispatch, useState, useRef, SyntheticEvent } from 'react';
import {
  Autocomplete,
  Chip,
  FilterOptionsState,
  Stack,
  TextField,
  createFilterOptions
} from '@mui/material';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from './API';
import { TCandidate } from '../Candidates/types';
import { CandidateAction } from './types';
import { ApplicationAction, IApplication } from '../Application/types';
import { styles } from './styles';

const CREATE_TEXT = 'Create "';

export default function CandidateTags({
  focused,
  candidate,
  dispatch,
  inputId
}: {
  focused: boolean;
  candidate: TCandidate;
  dispatch: Dispatch<CandidateAction> | Dispatch<ApplicationAction>;
  inputId?: string;
}) {
  const [tags, setTags] = useState<string[]>(candidate.tag_list);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const ref = useRef<HTMLDivElement>(null);

  const { mutate: updateCandidateTags } = useMutation({
    mutationFn: async (value: string[]) => {
      const { res } = await Api.updateCandidateTags(candidate.id, value);
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Candidate tags have been successfully updated`, state: 'success' }
      });
      // updating candidate data when the component is called from the individual candidate page
      queryClient.setQueryData(['candidate'], res);
      queryClient.invalidateQueries(['candidate'], { exact: true });
      // updating application candidate data when the component is called from the individual application page
      queryClient.setQueryData(['application'], { ...application, candidate: res });
      queryClient.invalidateQueries(['application'], { exact: true });
      ref.current?.focus();
    },
    onError: (error: { error: string }) => {
      setTags(candidate.tag_list);
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in updating candidate tags, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const handleTagsChange = (_e: SyntheticEvent, value: string[]) => {
    const newTags = value.map((val) =>
      val.startsWith(CREATE_TEXT) ? val.slice(CREATE_TEXT.length, -1) : val
    );

    setTags(newTags);
    updateCandidateTags(newTags);
    dispatch({ type: 'SET_FOCUSED', payload: true });
  };

  // function to filter options and suggest creating a new option
  const filterOptions = (options: string[], state: FilterOptionsState<string>) => {
    const { inputValue } = state;

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );

    const isNew = !options.some((option) => option.toLowerCase() === inputValue.toLowerCase());

    if (inputValue && isNew) {
      filtered.push(`${CREATE_TEXT}${inputValue}"`);
    }

    return filtered;
  };

  return (
    <Stack sx={{ ...styles.candidateSectionContainer, rowGap: 1 }}>
      <Stack sx={styles.candidateSectionHeader}>Candidate Tags</Stack>
      <Autocomplete
        id={inputId || 'candidate-tags-input'}
        clearIcon={false}
        options={candidate.entity_tags?.map((tag) => tag.value)}
        value={tags}
        onChange={handleTagsChange}
        filterOptions={filterOptions}
        freeSolo
        multiple
        sx={{ ...sharedClasses.formAutocomplete, '.MuiInputBase-root': { margin: 'unset' } }}
        ListboxProps={{ style: styles.listStyle }}
        renderTags={(value, props) =>
          value.map((option, index) => <Chip label={option} {...props({ index })} key={index} />)
        }
        slotProps={{ popper: { sx: { zIndex: 1 } } }}
        renderInput={(params) => (
          <TextField
            onBlur={() => dispatch({ type: 'SET_FOCUSED', payload: false })}
            autoFocus={focused}
            placeholder="Type your tag here..."
            {...params}
            sx={{ '& .MuiOutlinedInput-root': { padding: 0.5 } }}
            ref={ref}
          />
        )}
      />
    </Stack>
  );
}
