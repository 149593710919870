"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
import axios from "axios";
export const useSeekGlobalData = ({ key, endpoint }) => {
  const [processedData, setProcessedData] = useState([]);
  useEffect(() => {
    const getCurrencies = () => __async(void 0, null, function* () {
      const dataStr = localStorage.getItem(key);
      if (dataStr) {
        const dataFromLocalStorage = JSON.parse(dataStr);
        setProcessedData(dataFromLocalStorage);
        return;
      }
      try {
        const { data } = yield axios.get(endpoint);
        const dataFromApi = data.data[key];
        setProcessedData(dataFromApi);
        localStorage.setItem(key, JSON.stringify(dataFromApi));
      } catch (error) {
        console.log(error);
      }
    });
    getCurrencies();
  }, [endpoint, key]);
  return { processedData, setProcessedData };
};
