"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { candidateApplicationsTableColumn } from "./config";
export const InitialCandidateState = {
  snackbar: {
    message: "",
    state: "success"
  },
  candidateApplicationsTableState: {
    canScroll: [false, true],
    tableHovered: false,
    density: "Default",
    columns: candidateApplicationsTableColumn,
    selectedItemIds: [],
    selectedItems: [],
    actions: {
      selectedItem: null,
      anchorEl: null,
      isOpen: {
        delete: false,
        edit: false
      }
    },
    modalsOpen: { edit: false }
  },
  candidateApplicationsTableSettings: {
    page: 1,
    limit: 10,
    sort: "created_at",
    sort_order: "desc"
  },
  candidateTaskModalIsOpen: false,
  editedTask: null,
  taskWorkflowModalIsOpen: false,
  openEditModal: false,
  openDeleteModal: false,
  focused: false
};
export const CandidateReducer = (state, action) => {
  switch (action.type) {
    case "SET_SNACKBAR":
      return __spreadProps(__spreadValues({}, state), { snackbar: action.payload });
    case "SET_CAN_SCROLL":
      return __spreadProps(__spreadValues({}, state), {
        candidateApplicationsTableState: __spreadProps(__spreadValues({}, state.candidateApplicationsTableState), {
          canScroll: action.payload
        })
      });
    case "SET_TABLE_HOVERED":
      return __spreadProps(__spreadValues({}, state), {
        candidateApplicationsTableState: __spreadProps(__spreadValues({}, state.candidateApplicationsTableState), {
          tableHovered: action.payload
        })
      });
    case "SET_SORT":
      return __spreadProps(__spreadValues({}, state), {
        candidateApplicationsTableSettings: __spreadProps(__spreadValues({}, state.candidateApplicationsTableSettings), {
          sort: action.payload.sortBy,
          sort_order: action.payload.sortOrder
        })
      });
    case "SET_PAGE":
      return __spreadProps(__spreadValues({}, state), {
        candidateApplicationsTableSettings: __spreadProps(__spreadValues({}, state.candidateApplicationsTableSettings), {
          page: action.payload
        })
      });
    case "SET_LIMIT":
      return __spreadProps(__spreadValues({}, state), {
        candidateApplicationsTableSettings: __spreadProps(__spreadValues({}, state.candidateApplicationsTableSettings), {
          limit: action.payload
        })
      });
    case "OPEN_TASK_MODAL":
      return __spreadProps(__spreadValues({}, state), { candidateTaskModalIsOpen: action.payload });
    case "SET_EDITED_TASK":
      return __spreadProps(__spreadValues({}, state), { editedTask: action.payload });
    case "OPEN_TASK_WORKFLOW_MODAL":
      return __spreadProps(__spreadValues({}, state), { taskWorkflowModalIsOpen: action.payload });
    case "OPEN_EDIT_MODAL":
      return __spreadProps(__spreadValues({}, state), { openEditModal: action.payload });
    case "OPEN_DELETE_MODAL":
      return __spreadProps(__spreadValues({}, state), { openDeleteModal: action.payload });
    case "SET_FOCUSED":
      return __spreadProps(__spreadValues({}, state), { focused: action.payload });
    default:
      return state;
  }
};
