import React, { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { classes } from '../../Components/Modals/styles';
import { theme } from '../../../ThemeContext/ThemeObject';

/**
 * MissingPlaceholdersConfirmationModal component.
 *
 * This component renders a modal dialog to confirm sending an email with missing placeholders.
 *
 * @param missingPlaceholderList - A list of missing placeholders.
 * @param overrideFunction - Callback function to proceed despite missing placeholders.
 * @param missingPlaceholderConfirmation - Boolean to control the modal's open state.
 * @param setMissingPlaceholderConfirmation - Function to set the modal's open state.
 * @param overridingLoading - Boolean to indicate if the override action is loading.
 */
export function MissingPlaceholdersConfirmationModal({
  missingPlaceholderList,
  overrideFunction,
  missingPlaceholderConfirmation,
  setMissingPlaceholderConfirmation,
  overridingLoading
}: {
  missingPlaceholderList: string;
  overrideFunction: () => void;
  missingPlaceholderConfirmation: boolean;
  setMissingPlaceholderConfirmation: Dispatch<SetStateAction<boolean>>;
  overridingLoading: boolean;
}) {
  const MissingPlaceHolderDialogDescription = () => (
    <Stack>
      <Stack>It looks like there is no {missingPlaceholderList} in</Stack>
      <Stack>your email body. Do you still want to send it?</Stack>
    </Stack>
  );

  const Title = () => `Missing ${missingPlaceholderList} placeholder`;
  return (
    <GenericDialog
      isDialogOpen={missingPlaceholderConfirmation}
      setDialogOpen={setMissingPlaceholderConfirmation}
      title={Title()}
      description={<MissingPlaceHolderDialogDescription />}
      buttonCallback={overrideFunction}
      callbackLoading={overridingLoading}
      buttonText="Yes, send"
      secondaryButtonText="Back to editing"
      overrideColor={{
        primary: { ...classes.secondaryButtonAlternateColor, color: theme.palette.action.main },
        secondary: classes.primaryButtonAlternateColor
      }}
      switchOrder
      url=""
    />
  );
}
