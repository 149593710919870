import React, { useState } from 'react';
import { Stack } from '@mui/material';
import StyledModal from '../Components/GenericModal/StyledModal';
import {
  FormTextField,
  MultilineFormTextField
} from '../Components/CustomUIElements/FormTextField';
import ModalFooterButtons from '../Components/GenericModal/ModalFooterButtons';
import { styles } from './styles';

export default function AddRecord({
  isOpen,
  handleClose,
  addRecord,
  addingRecord,
  styleOverrides
}: {
  isOpen: boolean;
  handleClose: () => void;
  addRecord: ({ subject, body }: { subject: string; body: string }) => void;
  addingRecord: boolean;
  styleOverrides?: Record<string, string>;
}) {
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');

  return (
    <StyledModal
      isOpen={isOpen}
      label="Add candidate permanent record"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalContainer, width: '700px', ...styleOverrides }}
      closeIconId="close-permanent-record-button"
    >
      <Stack sx={styles.modalContent}>
        <Stack sx={styles.modalTitle}>Permanent record</Stack>
        <Stack sx={{ rowGap: 3, overflow: 'auto' }}>
          <FormTextField
            label="Subject"
            value={subject}
            required
            onChange={(v) => setSubject(v.target.value)}
            styles={{ width: '35%' }}
          />
          <MultilineFormTextField
            label="Body"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            fullWidth
            rows={5}
            required
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Save"
          primaryButtonCallback={() => addRecord({ subject, body })}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={addingRecord}
          primaryButtonDisabled={!subject || !body}
          primaryButtonID="save-permanent-record-button"
          secondaryButtonID="cancel-permanent-record-button"
          primaryButtonMinWidth="75px"
        />
      </Stack>
    </StyledModal>
  );
}
