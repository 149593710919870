import React from "react";
import { FormControl, FormLabel, TextField, FormHelperText } from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
function TextAreaWithCounter({ title, value, setValue, maxLength, helperText }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl} ${classes.medium}` }, /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, title), /* @__PURE__ */ React.createElement(
    TextField,
    {
      className: classes.textField,
      variant: "outlined",
      value,
      onChange: (e) => e.target.value.length <= maxLength && setValue(e.target.value)
    }
  ), /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, /* @__PURE__ */ React.createElement("span", { style: { marginRight: "1rem" } }, helperText), value ? value.length : 0, " /", maxLength));
}
TextAreaWithCounter.propTypes = {
  title: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  value: PropTypes.string,
  helperText: PropTypes.string
};
export default TextAreaWithCounter;
