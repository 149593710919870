import React, { useEffect, useState } from 'react';
import { classes } from './styles';
import { Autocomplete, Stack, Dialog, TextField, Typography, FormLabel } from '@mui/material';
import ModalFooterButtons from '../../GenericModal/ModalFooterButtons';
import { ISeekAccount } from '../types';
import { useInitSeekAd } from '../../../../SeekAd/hooks/useInitSeekAd';

function CreateAdDialog({
  jobId,
  createAdDialogOpen,
  setCreateAdDialogOpen,
  setError,
  seekAccounts
}: {
  jobId: string | null;
  createAdDialogOpen: boolean;
  setCreateAdDialogOpen: (value: boolean) => void;
  setError: (error: string) => void;
  seekAccounts: ISeekAccount[];
}) {
  const [selectedSeekAccount, setSelectedSeekAccount] = useState(null);
  const { mutate, jsonResponse, reset } = useInitSeekAd();

  useEffect(() => {
    if (jsonResponse.url) {
      window.location.href = jsonResponse.url;
    } else if (jsonResponse.errors) {
      setError(jsonResponse.errors ? jsonResponse.errors[0] : 'Error creating ad');
      setCreateAdDialogOpen(false);
      reset();
    }
  }, [jsonResponse, reset, setCreateAdDialogOpen, setError]);

  const handleNext = async () => {
    if (!selectedSeekAccount) return;

    if (selectedSeekAccount) {
      mutate(jobId, selectedSeekAccount.id);
    }
  };

  return (
    <Dialog open={createAdDialogOpen} onClose={() => setCreateAdDialogOpen(false)}>
      <Stack sx={classes.dialogContainer}>
        <Typography sx={classes.dialogTitle}>New SEEK Ad</Typography>
        <FormLabel required={true} sx={classes.label}>Select account</FormLabel> 
        <Autocomplete
          disablePortal
          options={seekAccounts}
          getOptionLabel={(option) => option.name}
          value={selectedSeekAccount}
          renderInput={(params) => <TextField {...params} placeholder='Please select' required />}
          onChange={(_event, value) => setSelectedSeekAccount(value)}
          sx={classes.dropdown}
        />
        <ModalFooterButtons
          primaryButtonText='Next'
          primaryButtonCallback={handleNext}
          secondaryButtonText='Cancel'
          secondaryButtonCallback={() => setCreateAdDialogOpen(false)}
        />
      </Stack>
    </Dialog>
  );
}

export default CreateAdDialog;
