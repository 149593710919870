export const adEditorCancelButton = {
  flexGrow: 1,
  color: '#E37D7A',
  fontSize: 16,
  fontWeight: '600',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
    color: '#E37D7A'
  }
};
