import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Api from '../../API';
import CircularProgress from '@mui/material/CircularProgress';
import { classes } from './styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import { IJobsForTask, IWorkflowAssignableUser, IWorkflowName } from '../../types';
import { useJobsForTask } from '../../../hooks/useJobsForTask';

const BASE_URL = window.location.origin;

export default function StartWorkFlow({
  apiKey,
  jobId,
  setSnackbarState,
  setActionsAnchorEl,
  isDashboard,
  buttonElement
}: {
  apiKey: string;
  jobId?: number;
  setActionsAnchorEl?: (value: null) => void;
  setSnackbarState?: StyledSnackbarProps['setSnackBarState'];
  isDashboard?: boolean;
  buttonElement?: React.ReactElement;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [workflowOptions, setWorkflowOptions] = useState(null);
  const [usersOptions, setUserOptions] = useState(null);
  const [assignTo, setAssignTo] = useState<IWorkflowAssignableUser | null>(null);
  const [workflowName, setWorkflowName] = useState<IWorkflowName | null>(null);
  const [hasError, setHasError] = useState(false);
  const [newWorkflowURL, setNewWorkflowURL] = useState('');
  const [selectedJob, setSelectedJob] = useState<IJobsForTask>();
  const { fetchJobs, jobs } = useJobsForTask();

  const fetchWorkFlowData = useCallback(async () => {
    if (!jobId && !selectedJob?.id) return;
    setIsLoading(true);
    try {
      const { data } = await Api.getWorkflows(
        { 'X-api-authenticate': apiKey },
        {},
        jobId || selectedJob.id
      );
      data?.assignable_users && setUserOptions(data.assignable_users);
      data?.workflows && setWorkflowOptions(data.workflows);
      data?.newWorkflowURL && setNewWorkflowURL(`${BASE_URL}${data.newWorkflowURL}`);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId, selectedJob]);

  useEffect(() => {
    if (!selectedJob) {
      setUserOptions(null);
      setWorkflowOptions(null);
      setNewWorkflowURL('');
    } else {
      fetchWorkFlowData();
    }
  }, [selectedJob, fetchWorkFlowData]);

  const createNewWorkflow = async () => {
    if (!assignTo || !workflowName) {
      setHasError(true);
      return;
    } else setHasError(false);
    try {
      await Api.assignWorkflow(
        { 'X-api-authenticate': apiKey },
        {
          id: workflowName?.id,
          user_id: assignTo?.id
        },
        jobId || selectedJob.id
      );
      if (!isDashboard) {
        setActionsAnchorEl(null);
      }
      setSnackbarState({
        message: 'Workflow has been created',
        state: 'success'
      });
      setIsDialogOpen(false);
      setAssignTo(null);
      setWorkflowName(null);
    } catch (error) {
      console.log(error);
      setSnackbarState({
        message: 'There was an error creating a workflow. Please try again.',
        state: 'error'
      });
    } finally {
      setIsLoading(false);
      if (isDashboard) {
        setIsDialogOpen(false);
        setSelectedJob(undefined);
      }
    }
  };

  return (
    <Box>
      <Box
        onClick={() => {
          !workflowOptions && fetchWorkFlowData();
          fetchJobs();
          setIsDialogOpen(true);
        }}
        id="start-workflow-job-button"
      >
        {buttonElement || 'Start Workflow'}
      </Box>
      <Modal
        open={isDialogOpen}
        aria-labelledby="modal-modal-title"
        onClose={() => setIsDialogOpen(false)}
      >
        <Box sx={classes.modalContent}>
          <CloseIcon onClick={() => setIsDialogOpen(false)} sx={classes.closeIcon} />
          <Box id="modal-modal-title" sx={classes.modalTitle}>
            Start Workflow
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
            {isDashboard && (
              <Box sx={classes.modalFormLine} pt={3}>
                <Autocomplete
                  disablePortal
                  options={jobs}
                  getOptionLabel={(option) => `${option.id} - ${option.title}`}
                  id="state-region-province"
                  value={selectedJob}
                  onChange={(event, value) => setSelectedJob(value || undefined)}
                  sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Job" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Box>
            )}
            {workflowOptions && workflowOptions?.length > 0 ? (
              <Box sx={{ marginTop: '1.75rem' }}>
                {!isLoading ? (
                  <Box sx={classes.modalFormLine}>
                    <Box sx={{ position: 'relative' }}>
                      <Autocomplete
                        disablePortal
                        options={workflowOptions}
                        getOptionLabel={(option) => option.name}
                        id="state-region-province"
                        value={workflowName}
                        onChange={(event, value) => setWorkflowName(value)}
                        sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                        ListboxProps={{
                          style: {
                            fontFamily: 'Source Sans Pro, sans-serif',
                            color: '#333333',
                            maxHeight: '200px'
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Workflow name"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {!workflowName && hasError && (
                        <Box sx={{ ...sharedClasses.errorBox }}>This field is required</Box>
                      )}
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                      <Autocomplete
                        disablePortal
                        options={usersOptions}
                        getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
                        id="state-region-province"
                        value={assignTo}
                        onChange={(event, value) => setAssignTo(value)}
                        sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                        ListboxProps={{
                          style: {
                            fontFamily: 'Source Sans Pro, sans-serif',
                            color: '#333333',
                            maxHeight: '200px'
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assign to"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {!assignTo && hasError && (
                        <Box sx={{ ...sharedClasses.errorBox }}>This field is required</Box>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={20} color="inherit" />
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {isLoading ? (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress size={20} color="inherit" />
                  </Box>
                ) : (
                  <Box sx={classes.noWorkflows}>No Workflows have been configured</Box>
                )}
              </Box>
            )}
          </Box>
          {(workflowOptions && workflowOptions?.length > 0) || (isDashboard && !selectedJob) ? (
            <Box sx={classes.modalActions}>
              <Button
                disableElevation
                sx={{ ...classes.buttonRedLight }}
                variant="text"
                onClick={() => {
                  setIsDialogOpen(false);
                  !isDashboard && setActionsAnchorEl(null);
                }}
              >
                Cancel
              </Button>
              <Button sx={classes.revertloadButton} onClick={() => createNewWorkflow()}>
                Submit
              </Button>
            </Box>
          ) : (
            <Box sx={{ margin: '0 auto' }}>
              {isLoading ? (
                <span />
              ) : (
                <Button
                  sx={classes.createNewWorkflowButton}
                  onClick={() => (window.location.href = newWorkflowURL)}
                >
                  Create task workflow
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
}
