"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
export const classes = {
  dialogConfirmButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    padding: "10px 30px",
    fontWeight: "bold",
    color: "#ffffff",
    background: theme.palette.action.main,
    "&:hover": {
      background: "#4da8a4"
    }
  },
  dialogContainer: {
    width: 700,
    maxWidth: "100%",
    padding: "40px"
  },
  dropdown: {
    width: "100%",
    marginBottom: "32px"
  },
  dropdownText: {
    fontSize: "14px !important"
  },
  dialogTitle: {
    fontFamily: "Source Sans Pro",
    fontSize: "25px",
    fontWeight: 700,
    marginBottom: "32px",
    color: theme.palette.primary.main
  },
  label: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    color: theme.palette.common.grey,
    "& span": {
      color: theme.palette.error.main
    }
  }
};
