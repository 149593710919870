var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import TextAreaWithCounter from "../UtilComponents/TextAreaWithCounter";
import PropTypes from "prop-types";
function SeekBillingReference({ answers, setAnswers }) {
  return /* @__PURE__ */ React.createElement(
    TextAreaWithCounter,
    {
      title: "Seek Billing Reference (Optional)",
      value: answers.billing_reference,
      setValue: (value) => setAnswers(__spreadProps(__spreadValues({}, answers), { billing_reference: value })),
      maxLength: 50,
      helperText: "Include a purchase order or reference to appear on your SEEK invoice"
    }
  );
}
SeekBillingReference.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired
};
export default SeekBillingReference;
