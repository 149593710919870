import React, { Dispatch } from 'react';
import { Button, Stack, Avatar } from '@mui/material';
import {
  Phone as PhoneIcon,
  PhoneIphone as PhoneIphoneIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  LinkedIn as LinkedInIcon
} from '@mui/icons-material';
import { IAddressDetails, TCandidate } from '../Candidates/types';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import Api from './API';
import { CandidateAction, CandidateState } from './types';
import { styles } from './styles';
import GenericTable from '../Components/GenericTable/GenericTable';
import CandidateApplicationsTableRows from './CandidateApplicationsTableRows';
import { headerStyle } from './config';
import CandidateTasks from './CandidateTasks';
import { IUserPermissions } from '../Components/sharedTypes';
import CandidateTags from './CandidateTags';
import Notepad from './Notepad';
import CandidateIcon from './CandidateIcon';

export const formatAddress = (address_details: IAddressDetails) => {
  const streetDetails = [
    address_details?.street_number,
    address_details?.street1,
    address_details?.street2,
    address_details?.street_type
  ]
    .filter((s) => s)
    .join(' ');

  const countryDetails = [address_details?.country?.printable_name, address_details?.postcode]
    .filter((d) => d)
    .join(' ');

  const fullAddress = [
    streetDetails,
    address_details?.suburb,
    address_details?.state?.name,
    countryDetails
  ]
    .filter((a) => a)
    .join(', ');

  return fullAddress;
};

export default function ProfileTab({
  candidate,
  CandidateState,
  dispatch,
  permissions
}: {
  candidate: TCandidate;
  CandidateState: CandidateState;
  dispatch: Dispatch<CandidateAction>;
  permissions?: IUserPermissions;
}) {
  const queryClient = useQueryClient();

  const { mutate: updateCandidateNotepad, isLoading: updatingCandidateNotepad } = useMutation({
    mutationFn: async (note: string) => {
      const { res } = await Api.updateCandidateNotepad(candidate.id, note);
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      queryClient.invalidateQueries(['candidate'], { exact: true });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in updating candidate notepad, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const { data: applications, isLoading: loadingApplications } = useQuery({
    queryKey: ['applications', CandidateState.candidateApplicationsTableSettings],
    queryFn: () =>
      Api.getCandidateApplications(candidate.id, {
        page: CandidateState.candidateApplicationsTableSettings.page,
        limit: CandidateState.candidateApplicationsTableSettings.limit,
        sort: CandidateState.candidateApplicationsTableSettings.sort,
        sort_order: CandidateState.candidateApplicationsTableSettings.sort_order
      }),
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting candidate data, ${error}`,
          state: 'error'
        }
      })
  });

  return (
    <Stack sx={{ rowGap: 3 }}>
      <Stack sx={{ padding: 4, flexDirection: 'row', columnGap: 3, paddingBottom: 1 }}>
        <Avatar sx={{ background: '#DDDDDD', width: '5rem', height: '5rem' }}>
          <CandidateIcon
            existingCandidate={candidate?.existing_candidate}
            sx={{ fontSize: '4rem' }}
          />
        </Avatar>
        <Stack>
          {candidate.employee_id && (
            <Stack
              sx={{ color: '#666666', fontSize: '10px', fontWeight: 'bold', marginLeft: '2px' }}
            >
              EMPLOYEE ID: {candidate.employee_id}
            </Stack>
          )}
          <Stack sx={{ rowGap: 1 }}>
            <Stack sx={styles.candidateNameContainer}>
              <Stack>
                {candidate.firstname} {candidate.lastname}
              </Stack>
              {candidate.linkedin_url && (
                <Button
                  variant="outlined"
                  sx={styles.linkedInButton}
                  disableRipple
                  onClick={() =>
                    candidate.linkedin_url && window.open(candidate.linkedin_url, '_blank')
                  }
                >
                  <LinkedInIcon sx={{ fontSize: '17px' }} />
                </Button>
              )}
            </Stack>
            <Stack sx={{ flexDirection: 'row', columnGap: '5rem' }}>
              <Stack sx={{ rowGap: 1 }}>
                <Stack sx={styles.candidateProfileDetails}>
                  <PhoneIcon sx={{ color: '#084D6D' }} />
                  {candidate.phone1}
                </Stack>
                <Stack sx={styles.candidateProfileDetails}>
                  <EmailIcon sx={{ color: '#084D6D' }} />
                  {candidate.email}
                </Stack>
              </Stack>
              <Stack sx={{ rowGap: 1 }}>
                {candidate.phone2 && (
                  <Stack sx={styles.candidateProfileDetails}>
                    <PhoneIphoneIcon sx={{ color: '#084D6D' }} />
                    {candidate.phone2 || '-'}
                  </Stack>
                )}
                <Stack sx={styles.candidateProfileAddress}>
                  <LocationOnIcon sx={{ color: '#084D6D' }} />
                  {candidate.address_details ? formatAddress(candidate.address_details) : '-'}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={styles.candidateSectionContainer}>
        <Stack sx={styles.candidateSectionHeader}>Applications</Stack>
        <GenericTable
          label="candidate applications"
          items={applications?.res?.applications || []}
          isLoadingItems={loadingApplications}
          tableState={CandidateState.candidateApplicationsTableState}
          dispatch={dispatch}
          headerStyle={headerStyle}
          paginationState={CandidateState.candidateApplicationsTableSettings}
          pagination={{
            totalItems: Number(applications?.resHead['x-total-count']) || 0,
            totalPages: Number(applications?.resHead['x-total-pages']) || 0
          }}
          TableRows={CandidateApplicationsTableRows}
          allowMultipleSelection={false}
          titleCellId="job"
        />
      </Stack>
      <CandidateTags focused={CandidateState.focused} candidate={candidate} dispatch={dispatch} />
      <Notepad
        note={candidate.notepad?.body}
        updateNote={updateCandidateNotepad}
        updatingNote={updatingCandidateNotepad}
      />
      <CandidateTasks
        candidate={candidate}
        CandidateState={CandidateState}
        dispatch={dispatch}
        permissions={permissions}
      />
    </Stack>
  );
}
