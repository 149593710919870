import React, { Dispatch, useState } from 'react';
import { Stack, Autocomplete, TextField } from '@mui/material';
import StyledModal from '../Components/GenericModal/StyledModal';
import { CandidatesAction, CandidatesState } from './types';
import { FormTextField } from '../Components/CustomUIElements/FormTextField';
import ModalFooterButtons from '../Components/GenericModal/ModalFooterButtons';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from './API';
import { ICountries, IStates } from '../Components/sharedTypes';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { TCandidate } from './types';
import { styles } from './styles';
import { CandidateAction, CandidateState } from '../Candidate/types';
import { streetTypes } from './config';

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const EditCandidate = ({
  candidates,
  editedCandidate,
  CandidateState,
  CandidateDispatch,
  CandidatesState,
  CandidatesDispatch
}: {
  candidates?: { res: { candidates: TCandidate[] }; resHead: any };
  editedCandidate: TCandidate;
  CandidateState?: CandidateState;
  CandidateDispatch?: Dispatch<CandidateAction>;
  CandidatesState?: CandidatesState;
  CandidatesDispatch?: Dispatch<CandidatesAction>;
}) => {
  const [country, setCountry] = useState<ICountries | null>(null);
  const [state, setState] = useState<IStates | null>(null);
  const [candidate, setCandidate] = useState<TCandidate>(editedCandidate);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const queryClient = useQueryClient();

  const validateLinkedInURL = (url: string) => {
    return String(url)
      .toLowerCase()
      .match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm);
  };

  const validateInputs = () => {
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!candidate.firstname.trim()) inputErrors.firstname = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastname = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    if (candidate.linkedin_url && !validateLinkedInURL(candidate.linkedin_url))
      inputErrors.linkedin = 'Please enter a proper LinkedIn URL';
    if (!candidate.phone1.trim()) {
      inputErrors.phone = 'Phone can not be empty';
    } else if (!Number(candidate.phone1)) {
      inputErrors.phone = 'Please enter a proper phone number';
    }
    if (candidate.phone2?.trim() && !Number(candidate.phone2))
      inputErrors.mobile = 'Please enter a proper mobile number';
    if (!candidate?.address_details?.street1?.trim())
      inputErrors.address = 'Street address can not be empty';
    if (!candidate?.address_details?.suburb?.trim())
      inputErrors.suburb = 'City, town or suburb can not be empty';
    if (!candidate?.address_details?.postcode?.trim())
      inputErrors.postcode = 'Postcode can not be empty';
    if (!country) inputErrors.country = 'Country can not be empty';
    if (!state) inputErrors.state = 'State can not be empty';
    setErrors(inputErrors);
    return !Object.keys(inputErrors).length;
  };

  const { data: countriesStates } = useQuery({
    queryKey: ['countries states'],
    queryFn: async () => {
      const { res } = await Api.getCountriesStates();
      return res;
    },
    onSuccess: (res) => {
      setCountry(res.find((r: ICountries) => r.id === candidate?.address_details?.country?.id));
      setState(
        res
          .find((r: ICountries) => r.id === candidate?.address_details?.country?.id)
          ?.states?.find((s: IStates) => s.id === candidate?.address_details?.state?.id)
      );
    },
    onError: (error) => {
      const errorSnackbarPayload: {
        type: 'SET_SNACKBAR';
        payload: { message: string; state: 'error' };
      } = {
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting countries and states data, ${error}`,
          state: 'error'
        }
      };
      CandidatesDispatch && CandidatesDispatch(errorSnackbarPayload);
      CandidateDispatch && CandidateDispatch(errorSnackbarPayload);
    }
  });

  const candidateData = {
    firstname: candidate.firstname,
    lastname: candidate.lastname,
    employee_id: candidate.employee_id,
    email: candidate.email,
    linkedin_url: candidate.linkedin_url,
    phone1: candidate.phone1,
    phone2: candidate.phone2
  };

  const candidateAddress = {
    street1: candidate?.address_details?.street1,
    street2: candidate?.address_details?.street2,
    suburb: candidate?.address_details?.suburb,
    postcode: candidate?.address_details?.postcode,
    street_number: candidate?.address_details?.street_number || '',
    street_type: candidate?.address_details?.street_type
  };

  const { mutate: updateCandidate, isLoading: updatingCandidate } = useMutation({
    mutationFn: (arg: { countryId: number; stateId: number }) =>
      Api.updateCandidate(candidate.id, {
        ...candidateData,
        address_attributes: {
          ...candidateAddress,
          country_id: arg.countryId,
          state_id: arg.stateId
        }
      }),
    onError: (error: { error: string }) => {
      const errorSnackbarPayload: {
        type: 'SET_SNACKBAR';
        payload: { message: string; state: 'error' };
      } = {
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error updating candidate, ${error.error}`,
          state: 'error'
        }
      };
      CandidatesDispatch && CandidatesDispatch(errorSnackbarPayload);
      CandidateDispatch && CandidateDispatch(errorSnackbarPayload);
    },
    onSuccess: () => {
      if (!country || !state) return;
      const successSnackbarPayload: {
        type: 'SET_SNACKBAR';
        payload: { message: string; state: 'success' };
      } = {
        type: 'SET_SNACKBAR',
        payload: {
          message: `Candidate has been updated.`,
          state: 'success'
        }
      };
      CandidatesDispatch && CandidatesDispatch(successSnackbarPayload);
      CandidateDispatch && CandidateDispatch(successSnackbarPayload);
      if (candidates) {
        const newCandidates = [...candidates.res.candidates];
        const index = newCandidates.findIndex((e) => e.id === candidate.id);
        newCandidates[index] = {
          ...newCandidates[index],
          ...candidateData,
          address_details: {
            ...newCandidates[index].address_details,
            ...candidateAddress,
            country: {
              ...newCandidates[index].address_details.country,
              id: country.id,
              name: country.name
            },
            state: state
          }
        };
        queryClient.setQueryData(
          ['candidates', CandidatesState?.candidatesTableSettings, CandidatesState?.searchQuery],
          {
            ...candidates,
            res: { candidates: newCandidates }
          }
        );
        queryClient.invalidateQueries(['candidates']);
        CandidatesDispatch && CandidatesDispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'edit' });
      } else {
        const updatedCandidate = {
          ...editedCandidate,
          ...candidateData,
          address_details: {
            ...editedCandidate.address_details,
            ...candidateAddress,
            country: country,
            state: state
          }
        };
        queryClient.setQueryData(['candidate'], updatedCandidate);
        queryClient.invalidateQueries(['candidate']);
        CandidateDispatch && CandidateDispatch({ type: 'OPEN_EDIT_MODAL', payload: false });
      }
    }
  });

  return (
    <StyledModal
      isOpen={
        !!CandidatesState?.candidatesTableState.actions.isOpen.edit ||
        !!CandidateState?.openEditModal
      }
      label="Edit Candidate"
      handleClose={() => {
        CandidatesDispatch && CandidatesDispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'edit' });
        CandidateDispatch && CandidateDispatch({ type: 'OPEN_EDIT_MODAL', payload: false });
      }}
      styleOverrides={{ maxHeight: '625px', maxWidth: '800px', padding: '40px' }}
      closeIconId="close-edit-candidate-button"
    >
      <Stack sx={{ width: '100%', height: '100%', rowGap: 4 }}>
        <Stack sx={{ fontWeight: 'bold', color: '#084D6D', fontSize: '25px' }}>
          {editedCandidate.firstname} {editedCandidate.lastname}
        </Stack>
        <Stack sx={styles.editCandidateModalContent}>
          <Stack sx={{ rowGap: 2 }}>
            <Stack sx={styles.editCandidateModalDetailsHeader}>Candidate Details</Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <FormTextField
                label="First name"
                value={candidate.firstname}
                required
                fullWidth
                onChange={(v) => setCandidate({ ...candidate, firstname: v.target.value })}
                error={errors.firstname}
              />
              <FormTextField
                label="Last name"
                value={candidate.lastname}
                required
                fullWidth
                onChange={(v) => setCandidate({ ...candidate, lastname: v.target.value })}
                error={errors.lastname}
              />
            </Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <FormTextField
                label="Employee ID"
                value={candidate.employee_id || ''}
                fullWidth
                onChange={(v) => setCandidate({ ...candidate, employee_id: v.target.value })}
              />
              <FormTextField
                label="LinkedIn URL"
                value={candidate.linkedin_url || ''}
                fullWidth
                onChange={(v) => setCandidate({ ...candidate, linkedin_url: v.target.value })}
                error={errors.linkedin}
              />
            </Stack>
          </Stack>
          <Stack sx={{ rowGap: 2 }}>
            <Stack sx={styles.editCandidateModalDetailsHeader}>Contact Details</Stack>
            <Stack>
              <FormTextField
                label="Email"
                value={candidate.email}
                required
                fullWidth
                onChange={(v) => setCandidate({ ...candidate, email: v.target.value })}
                error={errors.email}
              />
            </Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <FormTextField
                label="Phone"
                value={candidate.phone1}
                onChange={(v) => setCandidate({ ...candidate, phone1: v.target.value })}
                fullWidth
                required
                helperText="Digits only or add + for international numbers"
                error={errors.phone}
              />
              <FormTextField
                label="Mobile"
                value={candidate.phone2}
                onChange={(v) => setCandidate({ ...candidate, phone2: v.target.value })}
                fullWidth
                helperText="Digits only or add + for international numbers"
                error={errors.mobile}
              />
            </Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <FormTextField
                label="Street address"
                value={candidate?.address_details?.street1 || ''}
                fullWidth
                onChange={(v) =>
                  setCandidate({
                    ...candidate,
                    address_details: { ...candidate.address_details, street1: v.target.value }
                  })
                }
                required
                error={errors.address}
              />
              <FormTextField
                label="Street address cont'd"
                value={candidate?.address_details?.street2 || ''}
                fullWidth
                onChange={(v) =>
                  setCandidate({
                    ...candidate,
                    address_details: { ...candidate.address_details, street2: v.target.value }
                  })
                }
              />
            </Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <FormTextField
                label="Street number"
                value={candidate?.address_details?.street_number || ''}
                fullWidth
                onChange={(v) =>
                  setCandidate({
                    ...candidate,
                    address_details: { ...candidate.address_details, street_number: v.target.value }
                  })
                }
              />
              <Autocomplete
                filterSelectedOptions
                disablePortal
                autoHighlight
                includeInputInList
                value={candidate?.address_details?.street_type}
                options={streetTypes}
                getOptionLabel={(option) => option}
                onChange={(_event, newValue) =>
                  setCandidate({
                    ...candidate,
                    address_details: { ...candidate.address_details, street_type: newValue }
                  })
                }
                sx={{
                  ...sharedClasses.formAutocomplete,
                  ...styles.streetTypesAutocomplete
                }}
                ListboxProps={{ style: styles.editCandidateListboxStyle }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Street type"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please select"
                    sx={styles.editCandidateAutocompleteTextfield}
                  />
                )}
              />
            </Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <FormTextField
                label="City, town or suburb"
                value={candidate?.address_details?.suburb || ''}
                fullWidth
                onChange={(v) =>
                  setCandidate({
                    ...candidate,
                    address_details: { ...candidate.address_details, suburb: v.target.value }
                  })
                }
                required
                error={errors.suburb}
              />
              <FormTextField
                label="Postcode or zipcode"
                value={candidate?.address_details?.postcode || ''}
                fullWidth
                onChange={(v) =>
                  setCandidate({
                    ...candidate,
                    address_details: { ...candidate.address_details, postcode: v.target.value }
                  })
                }
                required
                error={errors.postcode}
              />
            </Stack>
            <Stack sx={styles.editCandidateModalDetailsRows}>
              <Autocomplete
                filterSelectedOptions
                disablePortal
                autoHighlight
                includeInputInList
                value={country}
                options={countriesStates || []}
                getOptionLabel={(option) => option.name}
                onChange={(_event, newValue) => {
                  if (newValue) {
                    const newErrors = { ...errors };
                    delete newErrors.country;
                    setErrors(newErrors);
                  }
                  setCountry(newValue);
                  setState(null);
                }}
                sx={{
                  ...sharedClasses.formAutocomplete,
                  width: '100%',
                  paddingTop: 3,
                  '.MuiInputBase-root': { margin: '3px 0px' }
                }}
                ListboxProps={{ style: styles.editCandidateListboxStyle }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please select"
                    sx={styles.editCandidateAutocompleteTextfield}
                    required
                    error={!country}
                    helperText={errors.country}
                  />
                )}
              />
              <Autocomplete
                filterSelectedOptions
                disablePortal
                autoHighlight
                includeInputInList
                value={state}
                options={country?.states || []}
                getOptionLabel={(option) => option.name}
                onChange={(_event, newValue) => {
                  if (newValue) {
                    const newErrors = { ...errors };
                    delete newErrors.state;
                    setErrors(newErrors);
                  }
                  setState(newValue);
                }}
                disabled={!country}
                sx={{
                  ...sharedClasses.formAutocomplete,
                  width: '100%',
                  paddingTop: 3,
                  '.MuiInputBase-root': { margin: '3px 0px' }
                }}
                ListboxProps={{ style: styles.editCandidateListboxStyle }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State, region or province"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please select"
                    sx={styles.editCandidateAutocompleteTextfield}
                    required
                    error={!state}
                    helperText={errors.state}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Save"
          primaryButtonCallback={() => {
            if (validateInputs() && country && state)
              updateCandidate({ countryId: country.id, stateId: state.id });
          }}
          primaryButtonID="save-candidate-button"
          secondaryButtonText="Cancel"
          secondaryButtonCallback={() => {
            CandidatesDispatch &&
              CandidatesDispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'edit' });
            CandidateDispatch && CandidateDispatch({ type: 'OPEN_EDIT_MODAL', payload: false });
          }}
          secondaryButtonID="cancel-edit-candidate-button"
          isLoading={updatingCandidate}
        />
      </Stack>
    </StyledModal>
  );
};

export default EditCandidate;
