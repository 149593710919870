"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
export const usePreviewSeekAd = (jobAdId) => {
  const [previewUrl, setPreviewUrl] = useState("");
  useEffect(() => {
    const getPreviewData = () => __async(void 0, null, function* () {
      try {
        const response = yield fetch(`/api/v4/seek/job_ads/${jobAdId}/preview`, {
          method: "POST"
        });
        const data = yield response.json();
        setPreviewUrl(data.url);
      } catch (e) {
        console.error(e);
      }
    });
    getPreviewData();
  }, [jobAdId]);
  return { previewUrl };
};
