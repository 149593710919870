var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect, useCallback } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup,
  FormHelperText,
  Grid,
  Select,
  MenuItem
} from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import axios from "axios";
import { useDebounce } from "use-debounce";
import { CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import ScoutAutocomplete from "../../../scout/autocomplete";
import AdProductSelection from "../Components/AdProductSelection";
import AdSelection from "../Components/AdSelection";
import PayDetails from "../Components/PayDetails";
import WorkTypes from "../Components/WorkTypes";
import SeekBillingReference from "../Components/SeekBillingReference";
const TITLE_LIMIT = 80;
function Step1({
  answers,
  setAnswers,
  apiKey,
  requiredRefs,
  activeError,
  jobAdId,
  setBrandingEnabled,
  setMaxBulletPoints,
  seek_billing_reference,
  seek_billing_reference_flag
}) {
  const [loadingSeekAdAllCategories, setLoadingSeekAdTypeAllCategories] = useState(true);
  const [loadingSeekAdCategories, setLoadingSeekAdCategories] = useState(true);
  const [seekAdCategories, setSeekAdCategories] = useState(null);
  const [getSeekAdCategoriesData, setGetSeekAdCategoriesData] = useState(null);
  const [categoriesIndex, setCategoriesIndex] = useState(null);
  const [debouncedTitle] = useDebounce(answers.title, 2500);
  const [debouncedAddress] = useDebounce(answers.listing_location, 2500);
  const classes = useStyles();
  const updateCategoryIndex = useCallback(
    (rawData) => {
      const data = rawData || getSeekAdCategoriesData;
      if (data) {
        const index = data.findIndex(
          (category) => category.id.value === answers.listing_classification
        );
        setCategoriesIndex(index);
      }
    },
    [answers.listing_classification, getSeekAdCategoriesData]
  );
  useEffect(() => {
    const getSeekAdCategories = (url) => __async(this, null, function* () {
      setLoadingSeekAdTypeAllCategories(true);
      try {
        const { data } = yield axios.get(url, {
          headers: { "Content-Type": "application/json" }
        });
        setGetSeekAdCategoriesData(data.data.jobCategories);
        setLoadingSeekAdTypeAllCategories(false);
      } catch (e) {
        setSeekAdCategories(null);
        setLoadingSeekAdTypeAllCategories(false);
      }
    });
    getSeekAdCategories(
      `/api/v4/seek/job_ads/${jobAdId}/job_categories?location_id=${answers.listing_location}`
    );
  }, [jobAdId, answers.listing_location]);
  useEffect(() => {
    answers.use_suggested_classification && answers.listing_classification && answers.listing_sub_classification && updateCategoryIndex(getSeekAdCategoriesData);
  }, [
    answers.listing_classification,
    answers.listing_sub_classification,
    answers.use_suggested_classification,
    getSeekAdCategoriesData,
    updateCategoryIndex
  ]);
  const getSugestedSeekAdCategories = (url) => __async(this, null, function* () {
    if (!answers.title || !answers.listing_location) {
      setSeekAdCategories(null);
      setLoadingSeekAdCategories(false);
      return;
    }
    try {
      const { data } = yield axios.get(url, {
        headers: { "X-api-authenticate": apiKey },
        params: { title: answers.title, listing_location: answers.listing_location }
      });
      setSeekAdCategories(data.data.jobCategorySuggestions);
      setLoadingSeekAdCategories(false);
    } catch (e) {
      setSeekAdCategories(null);
      setLoadingSeekAdCategories(false);
    }
  });
  useEffect(() => {
    getSugestedSeekAdCategories(`/api/v4/seek/job_ads/${jobAdId}/job_categories/suggestions`);
  }, [jobAdId, debouncedTitle, debouncedAddress]);
  useEffect(() => {
    setLoadingSeekAdCategories(true);
  }, [debouncedTitle, answers.listing_location]);
  useEffect(() => {
    if (!getSeekAdCategoriesData) return;
    updateCategoryIndex(null);
  }, [answers.listing_classification, getSeekAdCategoriesData, updateCategoryIndex]);
  return /* @__PURE__ */ React.createElement("div", { className: classes.stepContainer }, /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.title,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: classes.label }, "Job Title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.title,
        className: classes.textField,
        variant: "outlined",
        value: answers.title,
        onChange: (e) => e.target.value.length <= TITLE_LIMIT && setAnswers(__spreadProps(__spreadValues({}, answers), { title: e.target.value })),
        InputProps: {
          endAdornment: activeError === requiredRefs.title && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: classes.helperText }, answers.title.length, "/", TITLE_LIMIT)
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.listing_location,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { className: classes.label }, "Location", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.listing_location && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", { id: "SeekMultiposterErrorTxt" }, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      ScoutAutocomplete,
      {
        className: `${activeError === requiredRefs.listing_location ? classes.errorAutocomplete : ""} select-seek-location`,
        url: `/api/v4/seek/job_ads/${jobAdId}/locations/options`,
        name: "location",
        value: answers.listing_location,
        selectedLabel: answers.listing_location_display,
        includeBlank: true,
        placeholder: "Type to search...",
        onChange: (value, label, attrs) => {
          setAnswers(__spreadProps(__spreadValues({}, answers), {
            listing_location: value,
            listing_location_display: label,
            currency: attrs && attrs.currencies ? attrs.currencies[0].code : "AUD"
          }));
        }
      }
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.category,
      fullWidth: true,
      className: `${classes.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Category", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.category && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    seekAdCategories && /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Suggested category based on both job title and location"),
    loadingSeekAdCategories || loadingSeekAdAllCategories ? /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" }) : seekAdCategories ? /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.listing_sub_classification,
        onChange: (e) => {
          setAnswers(__spreadProps(__spreadValues({}, answers), {
            listing_sub_classification: e.target.value,
            use_suggested_classification: false,
            listing_sub_classification_display: e.target.name
          }));
        }
      },
      seekAdCategories.map((category) => /* @__PURE__ */ React.createElement("div", { key: category.jobCategory.id.value }, /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          label: category.jobCategory.parent.name,
          name: `${category.jobCategory.parent.name} > ${category.jobCategory.name}`,
          value: category.jobCategory.id.value,
          control: /* @__PURE__ */ React.createElement(Radio, null)
        }
      ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { style: { padding: "0 0.5rem 0 1.75rem" } }, ">"), " ", category.jobCategory.name)))
    ) : /* @__PURE__ */ React.createElement(Grid, { item: true }, /* @__PURE__ */ React.createElement("div", null, !answers.listing_location || !answers.listing_sub_classification ? "No suggested categories, please add a job title and location to see suggested categories" : "No suggested categories for the current job title and location")),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.use_suggested_classification,
        onChange: () => setAnswers(__spreadProps(__spreadValues({}, answers), {
          use_suggested_classification: true,
          listing_sub_classification: null,
          listing_classification: null
        }))
      },
      /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: "other",
          label: "Choose a different category",
          checked: answers.use_suggested_classification === true,
          control: /* @__PURE__ */ React.createElement(Radio, null)
        }
      )
    ),
    answers.use_suggested_classification && getSeekAdCategoriesData && /* @__PURE__ */ React.createElement(
      FormControl,
      {
        style: { marginTop: "5px" },
        fullWidth: true,
        className: `${classes.formControl} ${classes.medium}`
      },
      /* @__PURE__ */ React.createElement(
        Select,
        {
          error: activeError === requiredRefs.category,
          classes: { select: classes.input },
          variant: "outlined",
          value: answers.listing_classification,
          onChange: (e) => {
            const index = getSeekAdCategoriesData.findIndex(
              (category) => category.id.value === e.target.value
            );
            setAnswers(__spreadProps(__spreadValues({}, answers), {
              listing_classification: e.target.value,
              listing_classification_display: index >= 0 ? getSeekAdCategoriesData[index].name : ""
            }));
          },
          displayEmpty: true
        },
        getSeekAdCategoriesData.map((category) => /* @__PURE__ */ React.createElement(MenuItem, { key: category.id.value, value: category.id.value }, category.name))
      ),
      /* @__PURE__ */ React.createElement(
        FormLabel,
        {
          focused: false,
          style: { marginTop: "20px" },
          required: true,
          className: classes.label
        },
        "Sub Category"
      ),
      /* @__PURE__ */ React.createElement(
        Select,
        {
          error: activeError === requiredRefs.category,
          classes: { select: classes.input },
          disabled: !answers.listing_classification,
          variant: "outlined",
          value: answers.listing_sub_classification,
          onChange: (e) => {
            const index = getSeekAdCategoriesData[categoriesIndex].children.findIndex(
              (category) => category.id.value === e.target.value
            );
            setAnswers(__spreadProps(__spreadValues({}, answers), {
              listing_sub_classification: e.target.value,
              listing_sub_classification_display: index >= 0 ? getSeekAdCategoriesData[categoriesIndex].children[index].name : ""
            }));
          },
          displayEmpty: true
        },
        answers.listing_classification && getSeekAdCategoriesData && typeof categoriesIndex === "number" && categoriesIndex >= 0 && getSeekAdCategoriesData[categoriesIndex].children.map((category) => /* @__PURE__ */ React.createElement(MenuItem, { key: category.id.value, value: category.id.value }, category.name))
      )
    )
  ), /* @__PURE__ */ React.createElement(WorkTypes, { answers, setAnswers }), /* @__PURE__ */ React.createElement(
    PayDetails,
    {
      answers,
      setAnswers,
      requiredRefs,
      activeError
    }
  ), seek_billing_reference && seek_billing_reference_flag && /* @__PURE__ */ React.createElement(SeekBillingReference, { answers, setAnswers }), /* @__PURE__ */ React.createElement("h4", null, "SEEK Ad Type"), activeError === requiredRefs.ad_type && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", { id: "SeekMultiposterErrorTxt" }, "This field is required")), answers.seek_account_id ? /* @__PURE__ */ React.createElement(
    AdProductSelection,
    {
      answers,
      setAnswers,
      requiredRefs,
      jobAdId,
      setMaxBulletPoints,
      setBrandingEnabled,
      debouncedTitle
    }
  ) : /* @__PURE__ */ React.createElement(
    AdSelection,
    {
      answers,
      setAnswers,
      requiredRefs,
      jobAdId,
      setMaxBulletPoints,
      setBrandingEnabled
    }
  ));
}
Step1.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  apiKey: PropTypes.string.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  activeError: PropTypes.ref,
  jobAdId: PropTypes.number.isRequired,
  seek_billing_reference: PropTypes.bool,
  seek_billing_reference_flag: PropTypes.bool,
  setBrandingEnabled: PropTypes.func.isRequired,
  setMaxBulletPoints: PropTypes.func.isRequired
};
export default Step1;
