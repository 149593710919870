import React, { Dispatch, SetStateAction, RefObject } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction } from '../../types';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { IError } from '../../../SmartForms/types';
import { IFormsOption } from '../../types';
import { styles } from '../styles';

export default function FormsDropdown({
  selectedForm,
  setSelectedForm,
  dispatch,
  dropdownLabel,
  error,
  innerRef
}: {
  selectedForm: IFormsOption | null;
  setSelectedForm: Dispatch<SetStateAction<IFormsOption | null>>;
  dispatch: Dispatch<ApplicationAction>;
  dropdownLabel?: string;
  error?: string;
  innerRef?: RefObject<HTMLDivElement>;
}) {
  const { data: smartForms, isLoading: loadingSmartForms } = useQuery({
    queryKey: ['smart forms'],
    queryFn: async () => {
      const { res } = await Api.getSmartForms();
      return res.smart_forms;
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: error.errors.join(', '), state: 'error' }
      })
  });

  return (
    <Autocomplete
      disablePortal
      options={smartForms || []}
      getOptionLabel={(option) => option.name}
      value={selectedForm}
      sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 5, flexGrow: 1 }}
      loading={loadingSmartForms}
      loadingText="Loading forms..."
      ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={dropdownLabel || 'Select form'}
          placeholder="Please select"
          InputLabelProps={{ shrink: true }}
          sx={styles.autoCompleteTextfield}
          required
          helperText={error}
          ref={innerRef}
        />
      )}
      onChange={(event, value) => setSelectedForm(value)}
    />
  );
}
