import React, { ReactElement } from 'react';
import { classes } from './styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export interface IGenericDialogProps {
  url: string;
  title: string;
  description: string | ReactElement;
  buttonText: string;
  buttonCallback: (url: string, id: number) => void;
  callbackLoading: boolean;
  isDialogOpen: boolean;
  dialogId?: number;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void);
  secondaryButtonText?: string;
  disablePrimaryButton?: boolean;
  primaryButtonId?: string;
  secondaryButtonId?: string;
  alternateColor?: boolean;
  overrideColor?: { primary: React.CSSProperties; secondary: React.CSSProperties };
  switchOrder?: boolean;
}

function GenericDialog({
  url,
  title,
  description,
  buttonText,
  buttonCallback,
  callbackLoading,
  isDialogOpen,
  setDialogOpen,
  dialogId,
  secondaryButtonText,
  disablePrimaryButton,
  primaryButtonId,
  secondaryButtonId,
  alternateColor,
  overrideColor,
  switchOrder
}: IGenericDialogProps) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setDialogOpen(false)}
      sx={{ '& .MuiPaper-root': { ...classes.dialog, maxWidth: '500px' }, zIndex: '20000' }}
    >
      <h4>{title}</h4>
      {description && <span>{description}</span>}
      <Box
        sx={{
          ...classes.dialogButtonContainer,
          flexDirection: switchOrder ? 'row-reverse' : 'row'
        }}
      >
        <Button
          disableElevation
          id={secondaryButtonId}
          sx={{
            ...classes.buttonSecondaryLight,
            ...(alternateColor && classes.secondaryButtonAlternateColor),
            ...(overrideColor && overrideColor.secondary)
          }}
          onClick={() => setDialogOpen(false)}
          style={{ padding: '10px 30px' }}
        >
          {secondaryButtonText || 'Cancel'}
        </Button>
        <Button
          data-testid="dialog-action-button"
          className="dialog-action-button"
          id={primaryButtonId}
          disableElevation
          sx={{
            ...classes.buttonRed,
            ...(alternateColor && classes.primaryButtonAlternateColor),
            '&.Mui-disabled': { background: '#E5E5E5', color: '#939393' },
            ...(overrideColor && overrideColor.primary)
          }}
          onClick={() => (dialogId ? buttonCallback(url, dialogId) : buttonCallback(url, 0))}
          style={{ padding: '10px 30px' }}
          disabled={disablePrimaryButton}
        >
          {callbackLoading ? <CircularProgress size={20} color="inherit" /> : buttonText}
        </Button>
      </Box>
    </Dialog>
  );
}

export default GenericDialog;
