"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState } from "react";
export const useInitSeekAd = () => {
  const [jsonResponse, setJsonResponse] = useState({});
  const mutate = (jobId, seekAccountId) => __async(void 0, null, function* () {
    const param = seekAccountId ? { seek_account_id: seekAccountId } : {};
    try {
      const response = yield fetch(`/api/v4/seek/jobs/${jobId}/job_ads/init_seek_ad`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(param)
      });
      const json = yield response.json();
      setJsonResponse(json);
    } catch (exception) {
      console.log(exception);
    }
  });
  const reset = () => {
    setJsonResponse({});
  };
  return { mutate, jsonResponse, reset };
};
