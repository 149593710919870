import React, { Dispatch, useContext, useState } from 'react';
import { Button, TableRow, IconButton, Popover, Box, Tooltip } from '@mui/material';
// import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded';
import { CustomReportsActionType, CustomReportsStateType } from '../types';
import { classes } from '../../styles';
// import ScheduleReportModal from '../../ScheduledReportModal';
import { MoreVert } from '@mui/icons-material';
import {
  StickyLeftCell,
  StickyRightCell
} from '../../../../TableWithSearchFieldDropDownAndFilter/StickyComponents';
import { tableValueFormat } from '../../helper';
import { ReportContext } from '../../index';
import { ArrowRefsAndHoveredContext } from '../../../../TableWithSearchFieldDropDownAndFilter/ArrowRefsAndHoveredContext';

const ReportTableBody = ({
  state,
  dispatch,
  deleteReport
}: {
  state: CustomReportsStateType;
  dispatch: Dispatch<CustomReportsActionType>;
  deleteReport: (id: number) => void;
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<{ value?: any } | null>(null);
  // const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  // const [scheduleReport, setScheduleReport] = useState({});
  const { records, activeColumns } = state;
  const { tableHovered } = useContext(ArrowRefsAndHoveredContext);
  const activeFields = Object.entries(state.fieldOptions).filter(
    ([key, value]) => activeColumns[value.name].enabled
  );
  const reportServiceApi = useContext(ReportContext);

  return (
    <>
      {records.map((r, i) => (
        <TableRow key={i} sx={{ width: '100%' }}>
          {activeFields.map(([key, value]) => (
            <StickyLeftCell
              key={key}
              previousCellOffSet={value?.previousCellOffSet ? 0 : 50} // 50px offset from line 36 with min-width: 50px
              sticky={value.sticky}
              leftBound={value.leftBound}
              tableHovered={tableHovered}
              additionalStyles={value.style}
            >
              {' '}
              {tableValueFormat(
                r[value.name],
                value.name,
                r,
                reportServiceApi.currentUser.user_timezone
              )}
            </StickyLeftCell>
          ))}
          <StickyRightCell tableHovered={tableHovered} additionalStyles={{ width: '20%' }}>
            <Box sx={classes.actionButtonSection}>
              <Button
                id="report-builder-download"
                sx={classes.csvDownloadButton}
                onClick={() => {
                  dispatch({ type: 'SET_PREVIEW_REPORT', payload: r });
                }}
              >
                View Report
              </Button>
              <Box sx={classes.actionButtonIcons}>
                {/* <Tooltip title="Schedule report" placement="top">
                  <IconButton
                    id="report-builder-schedule-report"
                    sx={{ margin: '0 0.5rem' }}
                    onClick={() => {
                      setScheduleReport(r);
                      setScheduleModalOpen(true);
                    }}
                  >
                    <ScheduleSendRoundedIcon />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="More options" placement="top">
                  <IconButton value={r.id} onClick={(e) => setActionsAnchorEl(e.currentTarget)}>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </StickyRightCell>
        </TableRow>
      ))}
      {/* <ScheduleReportModal
        scheduleModalOpen={scheduleModalOpen}
        setScheduleModalOpen={setScheduleModalOpen}
        reportData={scheduleReport}
      /> */}
      <Popover
        id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
        sx={classes.actionsMenu}
        open={Boolean(actionsAnchorEl)}
        anchorEl={actionsAnchorEl}
        onClose={() => setActionsAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {/* <span id="report-builder-save-report" onClick={() => setActionsAnchorEl(null)}>
          Save to My Reports
        </span> */}
        <span
          id="report-builder-trash-report"
          onClick={() => {
            deleteReport(actionsAnchorEl?.value);
            setActionsAnchorEl(null);
          }}
        >
          Trash
        </span>
      </Popover>
    </>
  );
};

export default ReportTableBody;
