import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { ArrowCircleLeft as ArrowCircleLeftIcon, Close as CloseIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ITabs,
  IApprovalTemplate,
  IApprovalManagers,
  IApprovers,
  IAssignableUsers,
  IApprovalForm,
  IAnswer,
  IAttachedDocument
} from '../types';
import { classes } from './styles';
import Api from '../API';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { DebouncedFormTextField } from '../../Components/CustomUIElements/FormTextField';
import QuestionFieldGenerator, {
  IApplicantQuestion,
  IFieldChoice
} from '../../Components/Utilities/QuestionFieldGenerator';
import { FileWithPath } from 'react-dropzone';
import DocTypeIcon from '../../Components/Utilities/DocTypeIcon';
import AttachmentSelector from '../../Components/Utilities/AttachmentSelector';
import { serialize } from 'object-to-formdata';
import moment from 'moment';
import { IJob, IPermissions } from '../../Jobs/types';
import LoadingButton from '../../Components/Buttons/LoadingButton';
import { scrollToElement } from '../../utils/scroll-to-element';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import { emailIsValid } from '../../Components/Utilities/emailIsValid';
import { getPermissions } from '../../../shared/permissionHelpers';

const urlParams = new URLSearchParams(window.location.search);
const template_id = urlParams.get('form_id'); // used for new approval created from the approval forms page
const jobId = urlParams.get('job_id'); // used for new approval created from the candidate application
const applicationId = urlParams.get('application_id'); // used for new approval created from the candidate application
const cognologyRecordId = urlParams.get('cognology_record_id'); // used for new approval created from the application export cognology
const BASE_URL = window.location.origin;

const createApprovalFormQuestionsObject = (questions: IApplicantQuestion[]) => {
  const newObject = {};
  questions.forEach((question: IApplicantQuestion) => {
    if (question.type === 'RankField') {
      newObject[question.id] = {
        ...question,
        data: {}
      };
      question.field_choices.forEach((choice: IFieldChoice, index: number) => {
        newObject[question.id]['data'][choice.id] = index + 1;
      });
    } else if (question.type === 'JobReferenceLookupField') {
      newObject[question.id] = {
        ...question,
        data: 'validation bypass'
      };
    } else {
      newObject[question.id] = {
        ...question,
        data: ''
      };
    }
  });
  return newObject;
};

export const createQuestionErrorsObject = (questions: IApplicantQuestion[]) => {
  const errors: Record<number, string> = {};
  questions.forEach((question: IApplicantQuestion) => {
    errors[question.id] = '';
  });
  return errors;
};

export const containsDuplicates = (array: string[]) => {
  return new Set(array).size !== array.length;
};

const getAnswerAttributes = (id: number, value: any, answers?: IAnswer[]) => {
  const questionObj = answers?.find((answer: IAnswer) => answer.field_id === value.id);
  if (value.type === 'RadioButtonField' || value.type === 'SelectField') {
    return {
      field_id: id,
      field_choice_id: value.data,
      id: questionObj?.id
    };
  } else if (value.type === 'CheckBoxField') {
    return {
      field_id: id,
      field_choice_ids: Object.entries(value.data)
        .filter(([_, value]) => value)
        .map(([id]) => id),
      id: questionObj?.id
    };
  } else {
    return {
      field_id: id,
      data: value.data,
      id: questionObj?.id
    };
  }
};

export default function NewApproval({
  apiKey,
  entityApprovalAdmin,
  requisitionId, // used for editing new approval
  templateId, // used for editing new approval
  entityId
}: {
  apiKey: string;
  entityApprovalAdmin: string;
  requisitionId?: number;
  templateId?: number;
  entityId: number;
}) {
  const [approvalTemplateData, setApprovalTemplateData] = useState<IApprovalTemplate | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingApprovers, setLoadingApprovers] = useState<boolean>(false);
  const [loadingEmailTemplates, setLoadingEmailTemplates] = useState<boolean>(false);
  const [loadingAssignableUsers, setLoadingAssignableUsers] = useState<boolean>(false);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [approvers, setApprovers] = useState<
    {
      email: string;
      name?: string;
      id?: number;
      destroyed?: boolean;
      error?: string;
      mandatoryBlank?: boolean;
    }[]
  >([]);
  const [approvalManagers, setApprovalManagers] = useState<IApprovalManagers[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [emailTemplates, setEmailTemplates] = useState<
    [string, Array<{ id: number; name: string }>][]
  >([]);
  const [approvalEmailTemplate, setApprovalEmailTemplate] = useState<number | null>(null);
  const [approvalAdministrator, setApprovalAdministrator] = useState<string>(
    entityApprovalAdmin || ''
  );
  const [administratorList, setAdministratorList] = useState<IApprovers[]>([]);
  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const [attachedDocuments, setAttachedDocuments] = useState<IAttachedDocument[]>([]);
  const [questions, setQuestions] = useState<IApplicantQuestion[]>([]);
  const [emailTemplateQuestions, setEmailTemplateQuestions] = useState<IApplicantQuestion[]>([]);
  const [approvalFormQuestions, setApprovalFormQuestions] = useState(() =>
    createApprovalFormQuestionsObject([])
  );
  const [questionErrors, setQuestionErrors] = useState(() =>
    createQuestionErrorsObject(questions || [])
  );

  const fieldRefs = {
    positionTitle: useRef<HTMLDivElement>(null),
    approvalManagers: useRef<HTMLDivElement>(null),
    questions: useRef<Array<HTMLDivElement | null>>([])
  };

  const [assignableUsers, setAssignableUsers] = useState<IAssignableUsers[]>([]);
  const [assignedUser, setAssignedUser] = useState<number | null>(null);
  const [fetchingCSV, setFetchingCSV] = useState<boolean>(false);
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [approvalFormData, setApprovalFormData] = useState<IApprovalForm>({});
  const [loadingFormData, setLoadingFormData] = useState<boolean>(false);
  const [dataInitialized, setDataInitialized] = useState<boolean>(true);
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [job, setJob] = useState<number | null>(null);
  const [applications, setApplications] = useState<{ id: number; candidate_name: string }[]>([]);
  const [application, setApplication] = useState<number | null>(null);
  const [fetchingJobs, setFetchingJobs] = useState<boolean>(false);
  const [fetchingApplications, setFetchingApplications] = useState<boolean>(false);
  const [fetchingInitialJobsAndApplications, setFetchingInitialJobsAndApplications] =
    useState<boolean>(false);
  const [positionTitleError, setPositionTitleError] = useState<string>('');
  const [approvalAnsweredIds, setApprovalAnsweredIds] = useState<number[] | null>(null);
  const [userPermissions, setUserPermissions] = useState<Record<string, Record<string, boolean>>>(
    {}
  );

  const showPositionTitle = !Number(approvalTemplateData?.config?.hide_position_title);

  async function getUserPermissions(apiKey: string) {
    try {
      const data = await getPermissions(apiKey);
      const allRoles: Record<string, Record<string, boolean>> = {};
      data.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        })
      );
      setUserPermissions(allRoles);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting permissions',
        state: 'error'
      });
    }
  }

  // Validate approval question fields
  const validateQuestions = () => {
    let isValid = true;
    let questionError: number | undefined = undefined;

    questions
      .filter((question) => (requisitionId ? approvalAnsweredIds?.includes(question.id) : true))
      .forEach((question: IApplicantQuestion, index: number) => {
        let tempErrorMessage = '';
        if (question.type === 'CheckBoxField') {
          const isChecked = Object.values(approvalFormQuestions[question.id].data).some(
            (value) => value === true
          );
          if (question.required && !isChecked) {
            tempErrorMessage = 'Must select at least one option';
            if (questionError === undefined) {
              questionError = index;
            }
            isValid = false;
          }
        } else if (question.type === 'RankField') {
          const duplicates = containsDuplicates(
            Object.values(approvalFormQuestions[question.id].data)
          );
          if (duplicates) {
            tempErrorMessage = 'Ranked choices must be unique';
            if (questionError === undefined) {
              questionError = index;
            }
            isValid = false;
          }
        } else if (question.required && !approvalFormQuestions[question.id].data) {
          tempErrorMessage =
            question.type !== 'RadioButtonField'
              ? 'This field is required'
              : 'Must select an option';
          if (questionError === undefined) {
            questionError = index;
          }
          isValid = false;
        }
        setQuestionErrors((prev) => ({
          ...prev,
          [question.id]: tempErrorMessage
        }));
      });

    if (questionError !== undefined) {
      scrollToElement(fieldRefs.questions.current[questionError]);
    }

    return isValid;
  };

  const validatePositionTitle = () => {
    if (!showPositionTitle) return true;

    setPositionTitleError('');

    if (!positionTitle) {
      setPositionTitleError('Position title is required to create an approval form.');
      scrollToElement(fieldRefs.positionTitle);
      return false;
    }

    return true;
  };

  const validateApprovalManagers = () => {
    const newApprovers = [...approvers];
    let isValid = true;
    newApprovers.map((approver, i) => {
      approver.error = '';
      if (!approver.email) {
        newApprovers[i].error = 'Please choose an approver or delete the row.';
        isValid = false;
      }
      if (!emailIsValid(approver.email)) {
        newApprovers[i].error = 'Please enter a valid email address.';
        isValid = false;
      }
    });
    if (!isValid) {
      scrollToElement(fieldRefs.approvalManagers);
    }
    setApprovers(newApprovers);
    return isValid;
  };

  const answers_attributes = {};
  const sorted_questions = Object.entries(approvalFormQuestions).sort(
    (a, b) => a[1].position - b[1].position
  );
  sorted_questions.forEach(([id, value], index) => {
    if (requisitionId && !approvalAnsweredIds?.includes(value.id)) return;
    const answers = approvalFormData.answers;
    answers_attributes[index] = getAnswerAttributes(id, value, answers);
  });

  const approvers_attributes = {};
  approvers.map((approver, i) => {
    approvers_attributes[i] = {
      id: approver.id,
      email: approver.email.includes('*') ? approver.email.slice(1) : approver.email,
      position: 0,
      required: approver.email.includes('*'),
      _destroy: approver.destroyed
    };
  });

  const documents_attributes = {};
  fileList.map((document, i) => {
    documents_attributes[i] = {
      attached: document
    };
  });

  const approvalAttributes = {
    requisition_form_id: template_id || templateId,
    cognology_record_id: cognologyRecordId,
    position_title: positionTitle,
    note: notes,
    email_template_id: approvalEmailTemplate,
    assigned_user_id: assignedUser,
    documents_attributes,
    to_email: approvalAdministrator,
    application_id: application,
    approvers_attributes
  };

  const approvalData = {
    requisition: {
      ...approvalAttributes,
      answers_attributes
    }
  };

  const handleSaveDraft = async () => {
    const formData = serialize({ ...approvalData, draft: true });

    try {
      const response = await fetch(`${BASE_URL}/api/v4/requisitions`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        },
        body: formData
      }).then((res) => res.json());
      if (response.success === 'Draft created') {
        window.location.href = response.url;
        setSnackbar({
          message: 'Approval form was saved successfully',
          state: 'success'
        });
      } else {
        throw new Error('Error saving Approval form');
      }
    } catch (error) {
      setSnackbar({
        message: `Something went wrong. Please try again. ${error}`,
        state: 'error'
      });
    }
  };

  const handleSubmitApproval = async () => {
    if (!validatePositionTitle() || !validateQuestions() || !validateApprovalManagers()) return;
    const formData = serialize(approvalData);

    try {
      const response = await fetch(`${BASE_URL}/api/v4/requisitions`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        },
        body: formData
      }).then((res) => res.json());
      if (response.success === 'Approval created') {
        window.location.href = response.url;
        setSnackbar({
          message: 'Approval form created successfully',
          state: 'success'
        });
      } else {
        throw response.errors;
      }
    } catch (error) {
      setSnackbar({
        message: error.error || `Something went wrong. Please try again. ${error}`,
        state: 'error'
      });
    }
  };

  const updateApprovalForm = async (state?: string) => {
    if (!state && (!validatePositionTitle() || !validateQuestions() || !validateApprovalManagers()))
      return;
    const formData = state ? serialize({ ...approvalData, draft: true }) : serialize(approvalData);

    try {
      const response = await fetch(`${BASE_URL}/api/v4/requisitions/${requisitionId}`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey
        },
        body: formData
      }).then((res) => res.json());
      if (response.success === 'Approval updated') {
        window.location.href = response.url;
        setSnackbar({
          message: 'Approval form updated successfully',
          state: 'success'
        });
      } else {
        throw response.errors;
      }
    } catch (error) {
      setSnackbar({
        message: `Something went wrong. Please try again. ${error}`,
        state: 'error'
      });
    }
  };

  const getApprovalTemplateData = useCallback(async () => {
    setLoadingData(true);
    const approvalTemplateId = Number(template_id) || templateId;
    if (!approvalTemplateId) return;
    try {
      const data = await Api.getApprovalTemplate(
        { 'X-api-authenticate': apiKey },
        approvalTemplateId
      );
      setApprovalTemplateData(data.res);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch approval template data, ${err}`,
        state: 'error'
      });
    } finally {
      setLoadingData(false);
    }
  }, [apiKey, templateId]);

  const handleLookUpButton = async (questionId: number, positionId: number) => {
    setFetchingCSV(true);
    try {
      const data = await Api.getCSVData({ 'X-api-authenticate': apiKey }, questionId, positionId);
      setApprovers(
        data.res.approvers.map((approver: string) => ({
          email: approver.toLowerCase(),
          destroyed: false
        }))
      );
      questions?.map((question) => {
        const answerExist = data.res.fields[0][question.original_title];
        if (answerExist) {
          setApprovalFormQuestions((prev) => ({
            ...prev,
            [question.id]: {
              ...prev[question.id],
              data:
                question.type === 'SelectField' || question.type === 'RadioButtonField'
                  ? question.field_choices.find((e) => e.name === answerExist)?.id
                  : answerExist
            }
          }));
        }
      });
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch csv data, ${err}`,
        state: 'error'
      });
    } finally {
      setFetchingCSV(false);
    }
  };

  async function getEmailTemplates(apiKey: string) {
    setLoadingEmailTemplates(true);
    try {
      const data = await Api.getEmailTemplates({ 'X-api-authenticate': apiKey });
      setEmailTemplates(data.res.email_templates);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch email templates, ${err}`,
        state: 'error'
      });
    } finally {
      setLoadingEmailTemplates(false);
    }
  }

  async function getApproverManagers() {
    setLoadingApprovers(true);
    try {
      const data = await Api.getApprovalManagers({
        override_limit: true,
        limit: 2000,
        sort: 'name',
        sort_order: 'asc'
      });
      setApprovalManagers(data.res.requisition_managers);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch approval managers, ${err}`,
        state: 'error'
      });
    } finally {
      setLoadingApprovers(false);
    }
  }

  async function getAssignableUsers(apiKey: string) {
    setLoadingAssignableUsers(true);
    try {
      const data = await Api.getAssignableUsers({ 'X-api-authenticate': apiKey });
      setAssignableUsers(data.res.assignable_users);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch assignable users, ${err}`,
        state: 'error'
      });
    } finally {
      setLoadingAssignableUsers(false);
    }
  }

  const getApprovalFormData = useCallback(async () => {
    if (!requisitionId) return;
    setLoadingFormData(true);
    try {
      const data = await Api.getApprovalForm({ 'X-api-authenticate': apiKey }, requisitionId);
      setApprovalFormData(data.res);

      // Only previous answered questions will be displayed, newly added questions will not show in edit page
      const ids = data.res.answers.map((answer) => parseInt(answer.field_id));
      setApprovalAnsweredIds(ids);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch approval form data, ${err}`,
        state: 'error'
      });
    } finally {
      setLoadingFormData(false);
    }
  }, [apiKey, requisitionId]);

  const headerRefs = useRef(null);

  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`approvals-tabpanel-${index}`}
        aria-labelledby={`approvals-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const TabProps = useCallback((index: number) => {
    return {
      id: `approvals-tab-${index}`,
      'aria-controls': `approvals-tabpanel-${index}`
    };
  }, []);

  useEffect(() => {
    setIndicatorWidth(headerRefs?.current.clientWidth - 32);
    getApprovalTemplateData();
  }, [getApprovalTemplateData]);

  useEffect(() => {
    getApprovalFormData();
  }, [getApprovalFormData]);

  useEffect(() => {
    setDataInitialized(false);
    if (!approvalTemplateData) return;
    const fullAdministratorList = entityApprovalAdmin
      ? [
          { label: `Entity Default (${entityApprovalAdmin})`, value: entityApprovalAdmin },
          ...approvalTemplateData.approvers
        ]
      : [...approvalTemplateData.approvers];
    setAdministratorList(fullAdministratorList);
    if (template_id) {
      // Only enabled questions for New approval form
      const questionsData = approvalTemplateData.fields.filter(
        (question) => question.enabled && !question.for_email_template
      );
      const emailTemplateQuestionsData = approvalTemplateData.fields.filter(
        (question) => question.enabled && question.for_email_template
      );
      setQuestions(questionsData);
      setEmailTemplateQuestions(emailTemplateQuestionsData);

      // initial data load for NEW approval form
      questionsData && setApprovalFormQuestions(createApprovalFormQuestionsObject(questionsData));

      // If no default approvers set in approval template but has default approvers in entity level
      if (approvalTemplateData.config.default_approvers) {
        setApprovers(
          approvalTemplateData.config.default_approvers.split(',').map((approver) => ({
            email: approver,
            destroyed: false,
            mandatoryBlank: approver === '*'
          }))
        );
      } else if (approvalTemplateData.entity_default_approver) {
        setApprovers(
          approvalTemplateData.entity_default_approver.map((approver) => ({
            email: approver.required ? `*${approver.email}` : approver.email,
            destroyed: false,
            mandatoryBlank: approver.required
          }))
        );
      }
      setApprovalEmailTemplate(approvalTemplateData?.email_template_id);
      if (jobId && applicationId) {
        setFetchingInitialJobsAndApplications(true);
        getApplications(Number(jobId));
        setJob(Number(jobId));
        setApplication(Number(applicationId));
      }
    } else {
      // All questions for EDIT approval form which includes answered but disabled questions
      const questionsData = approvalTemplateData.fields.filter(
        (question) => !question.for_email_template
      );
      const emailTemplateQuestionsData = approvalTemplateData.fields.filter(
        (question) => question.for_email_template
      );
      setQuestions(questionsData);
      setEmailTemplateQuestions(emailTemplateQuestionsData);

      // initial data load for EDIT approval form
      showPositionTitle && setPositionTitle(approvalFormData.position_title);
      setNotes(approvalFormData.note);
      setApprovalEmailTemplate(approvalFormData.email_template?.id);
      fullAdministratorList.some(
        (approver) => approver.value === approvalFormData.approval_administrator
      )
        ? setApprovalAdministrator(approvalFormData.approval_administrator)
        : setApprovalAdministrator('');
      const formApprovers =
        approvalFormData.approvers?.map((approver, index) => ({
          email: approver.email,
          id: approver.id,
          destroyed: false,
          name: approver.name,
          mandatoryBlank:
            approvalTemplateData?.config?.default_approvers?.split(',')?.[index] === '*'
        })) || [];
      setApprovers(formApprovers);
      const formAssignedUser = assignableUsers.find(
        (user) => `${user.firstname} ${user.lastname}` === approvalFormData.assigned_user
      );
      setAssignedUser(formAssignedUser?.id);
      setAttachedDocuments(approvalFormData.documents);
      const questionObj = createApprovalFormQuestionsObject(questionsData);
      approvalFormData.answers?.forEach((answer) => {
        const questionId = Object.keys(questionObj).find(
          (key) => questionObj[key].id === answer.field_id
        );
        if (questionId) {
          if (
            answer.type === 'TextField' ||
            answer.type === 'TextAreaField' ||
            answer.type === 'CsvDataLookupField' ||
            answer.type === 'DateField'
          ) {
            questionObj[questionId].data = answer.answer;
          } else if (answer.type === 'SelectField' || answer.type === 'RadioButtonField') {
            questionObj[questionId].data = answer.field_choice_ids[0];
          } else if (answer.type === 'RankField') {
            const rankObj = {};
            answer.field_choice_ids.map((id, i) => {
              rankObj[id] = (i + 1).toString();
            });
            questionObj[questionId].data = rankObj;
          } else if (answer.type === 'CheckBoxField') {
            const checkBoxObj = {};
            answer.field_choice_ids.map((id) => (checkBoxObj[id] = true));
            questionObj[questionId].data = checkBoxObj;
          } else if (answer.type === 'AvailabilityField') {
            const convertAnswer = (answer: Record<string, string>) =>
              Object.keys(answer).reduce(
                (acc, key, index) => ({ ...acc, [index + 1]: answer[key] }),
                {}
              );
            questionObj[questionId].data = convertAnswer(answer.answer);
          }
        }
      });
      setApprovalFormQuestions(questionObj);
      if (approvalFormData.application) {
        setFetchingInitialJobsAndApplications(true);
        getApplications(approvalFormData.application.job_id);
        setJob(approvalFormData.application.job_id);
        setApplication(approvalFormData.application.id);
      }
    }
  }, [approvalTemplateData, approvalFormData, assignableUsers]);

  useEffect(() => {
    setDataInitialized(true);
  }, [approvalFormQuestions]);

  useEffect(() => {
    if (applications.length > 0) {
      setFetchingInitialJobsAndApplications(false);
    }
  }, [applications]);

  useEffect(() => {
    getApproverManagers();
    getEmailTemplates(apiKey);
    getAssignableUsers(apiKey);
    getJobs(apiKey);
    getUserPermissions(apiKey);
  }, [apiKey]);

  const handleAddApprover = () => {
    setApprovers([...approvers, { email: '', destroyed: false }]);
  };

  const removeApprover = (i: number) => {
    if (approvers.length > 1) {
      const newApprovers = [...approvers];
      if (newApprovers[i].id) {
        newApprovers[i].destroyed = true;
      } else {
        newApprovers.splice(i, 1);
      }
      setApprovers(newApprovers);
    }
  };

  const handleEmailChange = (i: number, value: IApprovalManagers) => {
    const newApprovers = [...approvers];
    newApprovers[i].email = value ? value.email : '';
    setApprovers(newApprovers);
  };

  const handleDeleteAttachment = (index: number) => {
    const updatedFileList = fileList.filter((file, i) => i !== index);

    setFileList(updatedFileList);
  };

  const templates = emailTemplates.reduce(
    (acc, currVal) => {
      const entity: string = currVal[0];
      const arrayOfTemplates: { id: number; name: string }[] = currVal[1];
      return [...acc, ...arrayOfTemplates.map((template) => ({ ...template, entity }))];
    },
    [] as { id: number; name: string; entity: string }[]
  );

  const handleDeleteDocument = async (i: number, id: number) => {
    try {
      await Api.putApprovalForm({ 'X-api-authenticate': apiKey }, requisitionId, {
        requisition: { documents_attributes: { [i]: { id: id, _destroy: true } } }
      });
    } catch (err) {
      setSnackbar({
        message: `Failed to delete document, ${err}`,
        state: 'error'
      });
    } finally {
      const newAttachedDocuments = [...attachedDocuments];
      newAttachedDocuments.splice(i, 1);
      setAttachedDocuments(newAttachedDocuments);
    }
  };

  async function getJobs(apiKey: string) {
    setFetchingJobs(true);
    try {
      const data = await Api.getJobs({ 'X-api-authenticate': apiKey });
      setJobs(data.res.jobs);
    } catch (err) {
      setSnackbar({
        message: `Failed fetching job options. Please try again. ${error}`,
        state: 'error'
      });
    } finally {
      setFetchingJobs(false);
    }
  }

  async function getApplications(jobId: number) {
    setFetchingApplications(true);
    try {
      const data = await Api.getApplications({ 'X-api-authenticate': apiKey }, jobId);
      setApplications(data.res);
    } catch (err) {
      setSnackbar({
        message: `Failed fetching application options. Please try again. ${error}`,
        state: 'error'
      });
    } finally {
      setFetchingApplications(false);
    }
  }

  const handleJobChange = (id: number) => {
    if (id === job) return;
    setApplications([]);
    setJob(id);
    getApplications(id);
    setApplication(null);
  };

  const handleDeleteJob = () => {
    setJob(null);
    setApplication(null);
    setApplications([]);
  };

  const handleEmailInput = (i: number, value: string) => {
    const newApprovers = [...approvers];
    newApprovers[i].email = value;
    setApprovers(newApprovers);
  };
  const allowedEntityIds = [
    30886, 32859, 31972, 33032, 31971, 31970, 31969, 31968, 33393, 33392, 31967
  ];
  const shouldRenderText = allowedEntityIds.includes(entityId);

  return (
    <Box sx={classes.jobPageContainer}>
      <Box sx={classes.jobPageHeader}>
        {loadingData ? (
          <Skeleton animation="wave" width={600} height={56} />
        ) : (
          <h1>{approvalFormData?.position_title || approvalTemplateData?.name}</h1>
        )}
      </Box>
      <Box sx={classes.tabsContainer}>
        <Tabs
          value={0}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Approvals"
          sx={{
            ...classes.tabs,
            '& .MuiTabs-indicator': { ...classes.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab
            ref={headerRefs}
            label={requisitionId ? 'Edit approval form' : 'New approval form'}
            {...TabProps(0)}
          />
        </Tabs>
      </Box>
      <Box>
        <TabPanel tabValue={0} index={0} />
      </Box>
      <Box sx={classes.backToJobButton}>
        <Stack
          flexDirection="row"
          alignItems="center"
          onClick={() => {
            window.location.href = '/admin/requisitions';
          }}
        >
          <IconButton sx={classes.iconGrey}>
            <ArrowCircleLeftIcon />
          </IconButton>
          <Box>Back to approval forms</Box>
        </Stack>
      </Box>
      <Box sx={{ padding: 2 }}>
        {loadingData || !dataInitialized ? (
          <Skeleton animation="wave" width={600} height={56} />
        ) : (
          <>
            {showPositionTitle && (
              <Box sx={{ paddingBottom: 3 }}>
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setPositionTitle(value);
                  }}
                  label="Vacancy position title"
                  defaultValue={positionTitle}
                  styles={{ width: '300px' }}
                  required
                  innerRef={fieldRefs.positionTitle}
                  error={positionTitleError}
                />
              </Box>
            )}
            {questions?.map(
              (question, index) =>
                (!approvalAnsweredIds || approvalAnsweredIds.includes(question.id)) && (
                  <Box sx={{ paddingBottom: 3 }} key={question.id}>
                    <QuestionFieldGenerator
                      question={question}
                      questionRef={(el) => (fieldRefs.questions.current[index] = el)}
                      questionError={questionErrors[question.id]}
                      onChangeCallback={(id, value) => {
                        setApprovalFormQuestions((prev) => ({
                          ...prev,
                          [id]: {
                            ...prev[id],
                            data: value
                          }
                        }));
                        setQuestionErrors((prev) => ({
                          ...prev,
                          [id]: value || !question.required ? '' : 'This field is required'
                        }));
                      }}
                      handleLookUpButton={handleLookUpButton}
                      answer={approvalFormQuestions[question.id]?.data}
                      fetchingCSV={fetchingCSV}
                    />
                  </Box>
                )
            )}
            {/* TODO: Hide this section for now */}
            {/* {emailTemplateQuestions.length > 0 && (
              <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>
                Email Template Questions
              </Stack>
            )}
            {emailTemplateQuestions?.map((question, index) => (
              <Box sx={{ paddingBottom: 3 }} key={question.id}>
                <QuestionFieldGenerator
                  question={question}
                  questionRef={(el) => (fieldRefs.questions.current[index] = el)}
                  questionError={questionErrors[question.id]}
                  onChangeCallback={(id, value) => {
                    setApprovalFormQuestions((prev) => ({
                      ...prev,
                      [id]: {
                        ...prev[id],
                        data: value
                      }
                    }));
                    setQuestionErrors((prev) => ({
                      ...prev,
                      [id]: value || !question.required ? '' : 'This field is required'
                    }));
                  }}
                  answer={approvalFormQuestions[question.id]?.data}
                  disabled={true}
                />
              </Box>
            ))} */}
          </>
        )}
      </Box>
      {userPermissions?.['Approval Forms']?.[
        `Add/attach documents (complementary to 'Submit Approval Form')`
      ] && (
        <Box sx={{ padding: 2 }}>
          <Stack sx={{ paddingBottom: 1 }}>
            <Typography sx={{ ...classes.sectionHeader, paddingY: 0 }}>Documents</Typography>

            {shouldRenderText && (
              <Typography sx={classes.sectionSubtitle}>
                {/* Please upload a copy of this roles Position Description below */}
                Please upload required documents below
              </Typography>
            )}
          </Stack>
          <AttachmentSelector
            performUpload={false}
            apiKey={apiKey}
            setFileList={(file) => {
              setFileList((previous) => [...previous, ...file]);
            }}
            maxSize={50000000}
          />
          {attachedDocuments &&
            attachedDocuments.map((document, index) => (
              <Box key={index} sx={classes.fileContainer}>
                <Box sx={classes.fileIconContainer}>
                  <Box sx={classes.fileIcon}>
                    {DocTypeIcon(document?.attached_file_name?.split('.').pop())}
                  </Box>
                  <Box>
                    <Box sx={classes.fileName}>{document.attached_file_name}</Box>
                    <Box sx={classes.fileSize}>
                      {moment(document.created_at).format('Do MMMM YYYY[,] h:mma')}
                    </Box>
                  </Box>
                </Box>
                <IconButton onClick={() => handleDeleteDocument(index, document.id)}>
                  <DeleteIcon sx={{ color: '#D6827D' }} />
                </IconButton>
              </Box>
            ))}
          {fileList &&
            fileList.map(
              (file, index) =>
                file.name && (
                  <Box key={index} sx={classes.fileContainer}>
                    <Box sx={classes.fileIconContainer}>
                      <Box sx={classes.fileIcon}>{DocTypeIcon(file?.name?.split('.').pop())}</Box>
                      <Box>
                        <Box sx={classes.fileName}>{file.name}</Box>
                        <Box sx={classes.fileSize}>
                          {moment(file.lastModified).format('Do MMMM YYYY[,] h:mma')}
                        </Box>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleDeleteAttachment(index)}>
                      <DeleteIcon sx={{ color: '#D6827D' }} />
                    </IconButton>
                  </Box>
                )
            )}
        </Box>
      )}
      {(requisitionId
        ? userPermissions?.['Approval Forms']?.['Edit the assigned user of a requisition']
        : userPermissions?.['Approval Forms']?.['Assign User To Requisition']) &&
        !approvalTemplateData?.config.hide_assigned_user && (
          <>
            {loadingAssignableUsers ? (
              <Skeleton animation="wave" width={600} height={56} />
            ) : (
              <Box sx={{ padding: 2 }}>
                <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>Assigned User</Stack>
                <Autocomplete
                  disablePortal
                  options={assignableUsers}
                  getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                  value={assignableUsers.find((e) => e.id === assignedUser) || null}
                  onChange={(event, value) =>
                    value ? setAssignedUser(value.id) : setAssignedUser(null)
                  }
                  sx={{ width: '300px', paddingTop: 3, ...sharedClasses.formAutocomplete }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pßro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assigned user"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Please select"
                    />
                  )}
                />
              </Box>
            )}
          </>
        )}
      {loadingData ? (
        <Skeleton animation="wave" width={600} height={56} />
      ) : (
        <>
          {!Number(approvalTemplateData?.config.hide_approval_administrator) &&
            administratorList && (
              <Box sx={{ padding: 2 }}>
                <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>
                  Approval Administrator
                </Stack>
                <Autocomplete
                  disablePortal
                  options={administratorList}
                  getOptionLabel={(option) => option.label}
                  value={
                    administratorList.find((manager) => manager.value === approvalAdministrator) ||
                    null
                  }
                  onChange={(event, value) =>
                    value ? setApprovalAdministrator(value.value) : setApprovalAdministrator('')
                  }
                  sx={{ width: '300px', paddingTop: 3, ...sharedClasses.formAutocomplete }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pßro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Send to"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Please select"
                    />
                  )}
                />
              </Box>
            )}
        </>
      )}
      {userPermissions?.['Approval Forms']?.[
        `Add approvers (complementary to 'Submit Approval Form')`
      ] && (
        <>
          {loadingApprovers ? (
            <Skeleton animation="wave" width={600} height={56} />
          ) : (
            <Box sx={{ padding: 2 }}>
              <Stack sx={classes.sectionHeader} ref={fieldRefs.approvalManagers}>
                Approvers
              </Stack>
              {approvers.map((approver, index) => {
                const deleted = approver?.name?.includes('deleted');
                const mandatory = approver.email.includes('*');
                const mandatoryBlank = approver.mandatoryBlank;
                let value = deleted
                  ? approver
                  : approvalManagers.find(
                      (manager) =>
                        (mandatory ? approver.email.slice(1) : approver.email) === manager.email
                    );
                if (!value) {
                  value = approvers.find(
                    (manager) =>
                      (mandatory ? approver.email.slice(1) : approver.email) === manager.email
                  );
                  if (!value) value = mandatory ? approver.email.slice(1) : approver.email;
                }
                const approverOptions = [
                  ...approvalTemplateData?.entity_default_approver,
                  ...approvalManagers
                ];
                return (
                  <Box>
                    <React.Fragment key={`${value?.id}-${index}`}>
                      {!approver.destroyed && (
                        <Box sx={classes.approversContainer}>
                          {approverOptions.length === 0 ? (
                            <FormTextField
                              label="Email"
                              onChange={(e) => {
                                handleEmailInput(index, e.target.value);
                              }}
                              draggable={mandatory}
                              value={mandatory ? approver.email.slice(1) : approver.email}
                              styles={{ width: '290px' }}
                              error={approver.error ? 'Please enter a proper email address' : ''}
                            />
                          ) : (
                            <Autocomplete
                              key={`${value?.id}-${index}`}
                              disablePortal
                              disabled={!mandatoryBlank && (mandatory || deleted)}
                              options={approverOptions}
                              getOptionLabel={(option) =>
                                option?.name?.includes('deleted')
                                  ? approvalFormData.state === 'draft'
                                    ? 'Please select an approval manager'
                                    : option.name
                                  : option.email
                              }
                              value={value || null}
                              onChange={(event, value) => handleEmailChange(index, value)}
                              sx={{ width: '450px', ...sharedClasses.formAutocomplete }}
                              ListboxProps={{
                                style: {
                                  fontFamily: 'Source Sans Pro, sans-serif',
                                  color: '#333333',
                                  maxHeight: '200px'
                                }
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    label="Email"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Please select"
                                  />
                                  {approver.error && (
                                    <Stack
                                      sx={{ color: '#E37D7A', fontSize: '13px', paddingLeft: 1 }}
                                    >
                                      {approver.error}
                                    </Stack>
                                  )}
                                </>
                              )}
                              renderOption={(
                                props: React.HTMLAttributes<HTMLLIElement>,
                                option: INewApprovalOptions
                              ) => (
                                <Box {...props} key={option.id}>
                                  {option.email}
                                </Box>
                              )}
                            />
                          )}
                          {!mandatoryBlank && !mandatory && !deleted && (
                            <Button
                              sx={{ '&:hover': { backgroundColor: 'inherit' } }}
                              onClick={() => removeApprover(index)}
                            >
                              <CloseIcon color="warning" sx={{ cursor: 'pointer' }} />
                            </Button>
                          )}
                        </Box>
                      )}
                    </React.Fragment>
                  </Box>
                );
              })}
              <Box sx={{ paddingTop: 1 }}>
                <Button
                  sx={{
                    ...classes.draftButton,
                    fontSize: '12px',
                    padding: '4px 12px',
                    height: '30px'
                  }}
                  onClick={handleAddApprover}
                >
                  Add approver
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
      {userPermissions?.['Approval Forms']?.['Manage Approval Form Notes'] && (
        <Box sx={{ padding: 2 }}>
          <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>Notes</Stack>
          <DebouncedFormTextField
            onDebouncedChange={(value: string) => {
              setNotes(value);
            }}
            label="Add Note"
            defaultValue={notes}
            styles={{ width: '300px' }}
          />
        </Box>
      )}
      {loadingEmailTemplates || loadingData || loadingFormData ? (
        <Skeleton animation="wave" width={600} height={56} />
      ) : (
        <>
          {!approvalTemplateData?.config.hide_email_template && (
            <Box sx={{ padding: 2 }}>
              <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>Email template</Stack>
              <Autocomplete
                disablePortal
                options={templates}
                groupBy={(option) => option.entity}
                getOptionLabel={(option) => option.name}
                value={templates.find((e) => e.id === approvalEmailTemplate) || null}
                onChange={(event, value) =>
                  value ? setApprovalEmailTemplate(value.id) : setApprovalEmailTemplate(null)
                }
                sx={{ width: '300px', paddingTop: 3, ...sharedClasses.formAutocomplete }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pßro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ input: { '&::placeholder': { opacity: 1 } } }}
                    label="Select email template"
                    InputLabelProps={{ shrink: true }}
                    placeholder="(Use default template)"
                  />
                )}
              />
            </Box>
          )}
        </>
      )}
      {fetchingInitialJobsAndApplications ? (
        <Skeleton animation="wave" width={600} height={56} />
      ) : (
        <>
          {parseInt(approvalTemplateData?.config?.enable_approval_template_for_applications) ===
            1 && (
            <Box sx={{ padding: 2 }}>
              <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>Application</Stack>
              <Autocomplete
                disablePortal
                options={jobs}
                getOptionLabel={(option) => `${option.reference} - ${option.title}`}
                value={jobs.find((e) => e.id === job) || null}
                onChange={(event, value) => (value ? handleJobChange(value.id) : handleDeleteJob())}
                sx={{ width: '300px', paddingTop: 3, ...sharedClasses.formAutocomplete }}
                loading={fetchingJobs}
                loadingText={'Loading jobs...'}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pßro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Job"
                  />
                )}
              />
              <Autocomplete
                disablePortal
                options={applications}
                getOptionLabel={(option) => `${option.id} - ${option.candidate_name}`}
                value={applications.find((e) => e.id === application) || null}
                noOptionsText={job ? 'No applications found for this job' : 'Please choose a job'}
                onChange={(event, value) => {
                  value ? setApplication(value.id) : setApplication(null);
                }}
                loading={fetchingApplications}
                loadingText={'Loading applications...'}
                sx={{ width: '300px', paddingTop: 5, ...sharedClasses.formAutocomplete }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pßro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Application"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Application"
                  />
                )}
              />
            </Box>
          )}
        </>
      )}
      <Box sx={classes.footerButtons}>
        <Button
          sx={classes.cancelButton}
          onClick={() => {
            window.location.href = requisitionId
              ? `/admin/requisitions/${requisitionId}`
              : '/admin/requisitions';
          }}
        >
          Cancel
        </Button>
        {(template_id || approvalFormData.state === 'draft') && (
          <LoadingButton
            id="save-approval-draft-button"
            type="submit"
            task={() =>
              approvalFormData.state === 'draft' ? updateApprovalForm('draft') : handleSaveDraft()
            }
            sx={{ ...classes.draftButton, minWidth: 100 }}
          >
            Save draft
          </LoadingButton>
        )}

        <LoadingButton
          id="submit-new-approval"
          type="submit"
          task={() => (template_id ? handleSubmitApproval() : updateApprovalForm())}
          sx={{ ...sharedClasses.saveButton }}
        >
          Submit
        </LoadingButton>
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
