import React, { Dispatch, useContext, useState } from 'react';
import { Button, TableRow, IconButton, Popover, Box, Tooltip } from '@mui/material';
import { MyReportsTableState, MyReportsTableAction } from './types';
import { classes } from '../../styles';
import { ReportContext } from '../../index';
import { downloadReport as downloadingReport } from '../../History/API';
import { Edit, MoreVert } from '@mui/icons-material';
import {
  StickyLeftCell,
  StickyRightCell
} from '../../../../TableWithSearchFieldDropDownAndFilter/StickyComponents';
import { tableValueFormat } from '../../helper';
import TransferReportModal from './TransferReportModal';
import ShareModal from './ShareModal';
import { SavedReportRecord } from '../../types';
import GenericDialog from '../../../NewUI/Components/Modals/GenericDialog';
import { useMutation } from '@tanstack/react-query';
import { ArrowRefsAndHoveredContext } from '../../../../TableWithSearchFieldDropDownAndFilter/ArrowRefsAndHoveredContext';

const ReportTableBody = ({
  state,
  dispatch,
  refetchForms
}: {
  state: MyReportsTableState;
  dispatch: Dispatch<MyReportsTableAction>;
  refetchForms: () => void;
}) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(null);
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [trashModalOpen, setTrashModalOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<SavedReportRecord | null>(null);
  const { records, activeColumns } = state;
  const reportServiceApi = useContext(ReportContext);
  const { tableHovered } = useContext(ArrowRefsAndHoveredContext);
  const activeFields = Object.entries(state.fieldOptions).filter(
    ([key, value]) => activeColumns[value.name].enabled
  );
  const getPreviewData = (r: SavedReportRecord) => {
    reportServiceApi.reports.getPreview({ ...r.query_config }).then((res) => {
      dispatch({
        type: 'SET_SELECTED_RECORD',
        payload: { data: r, previewHeaders: res.headers, previewData: res.data }
      });
    });
  };

  const { mutate: deleteReport } = useMutation({
    mutationFn: (id: number) => reportServiceApi.reports.delete(id),
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `${selectedReport?.title} has been trashed`, state: 'success' }
      });
      setSelectedReport(null);
      setTrashModalOpen(false);
      refetchForms();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Failed to trash report', state: 'error' }
      })
  });

  return (
    <>
      {records.map((r, i) => (
        <TableRow key={i} sx={{ width: '100%' }}>
          {activeFields.map(([key, value]) => (
            <StickyLeftCell
              key={key}
              previousCellOffSet={value?.previousCellOffSet ? 0 : 50} // 50px offset from line 36 with min-width: 50px
              sticky={value.sticky}
              leftBound={value.leftBound}
              tableHovered={tableHovered}
              additionalStyles={value.style}
            >
              {' '}
              {tableValueFormat(
                r[value.name],
                value.name,
                r,
                reportServiceApi.currentUser.user_timezone
              )}
            </StickyLeftCell>
          ))}
          <StickyRightCell tableHovered={tableHovered}>
            <Box sx={{ ...classes.actionButtonSection, width: '25%', minWidth: '235px' }}>
              <Button
                sx={classes.csvDownloadButton}
                onClick={() => downloadingReport(r, dispatch, reportServiceApi)}
              >
                Download report
              </Button>
              <Box sx={classes.actionButtonIcons}>
                <Tooltip title="Edit" placement="top">
                  <IconButton sx={{ marginLeft: '1rem' }} onClick={() => getPreviewData(r)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="More options" placement="top">
                  <IconButton
                    value={r.id}
                    onClick={(e) => {
                      setActionsAnchorEl(e.currentTarget);
                      setSelectedReport(r);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </StickyRightCell>
        </TableRow>
      ))}
      <TransferReportModal
        modalOpen={transferModalOpen}
        setModalOpen={setTransferModalOpen}
        reportId={1}
      />
      <ShareModal
        shareModalOpen={shareModalOpen}
        setShareModalOpen={setShareModalOpen}
        reportId={1}
      />
      <Popover
        id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
        sx={classes.actionsMenu}
        open={Boolean(actionsAnchorEl)}
        anchorEl={actionsAnchorEl}
        onClose={() => setActionsAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <span
          onClick={() => {
            setActionsAnchorEl(null);
            setShareModalOpen(true);
          }}
        >
          Share
        </span>
        <span
          onClick={() => {
            setActionsAnchorEl(null);
            setTransferModalOpen(true);
          }}
        >
          Transfer ownership
        </span>
        <span
          onClick={() => {
            setActionsAnchorEl(null);
            setTrashModalOpen(true);
          }}
        >
          Trash
        </span>
      </Popover>
      <GenericDialog
        isDialogOpen={trashModalOpen}
        setDialogOpen={setTrashModalOpen}
        title={`Are you sure you want to trash this report?`}
        description="This report will be removed from your Saved Reports"
        buttonCallback={() => selectedReport && deleteReport(selectedReport.id)}
        callbackLoading={false}
        buttonText="Trash"
        url=""
      />
    </>
  );
};

export default ReportTableBody;
