import React, { Dispatch, useRef, useState } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Stack, TextField } from '@mui/material';
import { NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication, IFormsOption } from '../types';
import { ADD_FORM_PLACEHOLDER, checkMissingPlaceholders, ModalType } from '../config';
import { styles } from './styles';
import FormsDropdown from './Components/FormsDropdown';
import EmailsDropdown from './Components/EmailsDropdown';
import EmailBodyComponent from './Components/EmailBodyComponent';
import { useGetEmailTemplate, useGetSmsTemplatePlaceholders } from '../helper';
import MultiTagInput from '../../Components/CustomUIElements/MultiTagInput';
import { emailIsValid } from '../../Components/Utilities/emailIsValid';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import { IApplicationStatus } from '../../Job/types';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { scrollToElement } from '../../utils/scroll-to-element';
import { classes } from '../../Job/Applications/styles';
import NotificationOptionSwitch from './Components/NotificationOptionsSwitch';
import SmsTemplateComponent from '../../Components/CustomUIElements/SmsTemplateComponent';
import { validatePhone } from './SendSms';
import { IUserPermissions } from '../../Components/sharedTypes';
import { MissingPlaceholdersConfirmationModal } from './MissingPlaceholdersConfirmationModal';

export default function SendForm({
  ApplicationState,
  dispatch,
  smsTemplatePermissions
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  smsTemplatePermissions: boolean;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const applicationStatuses = queryClient.getQueryData<IApplicationStatus[]>([
    'application statuses'
  ]);
  const [selectedForm, setSelectedForm] = useState<IFormsOption | null>(null);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string | null>('');
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);
  const [submissionError, setSubmissionError] = useState<string>('');
  const [status, setStatus] = useState<IApplicationStatus | null>(
    applicationStatuses?.find((s) => s.id === application?.status_details?.id) ||
      applicationStatuses?.[0] ||
      null
  );
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [step, setStep] = useState<number>(1);
  const [notification, setNotification] = useState<string>('email');
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const jobId = application?.job.id;
  const hasEventPermission = permissions?.['Events']?.['Create / Edit Events'];
  const [missingPlaceholderConfirmation, setMissingPlaceholderConfirmation] =
    useState<boolean>(false);

  const fieldRefs = {
    form: useRef<HTMLDivElement>(null),
    template: useRef<HTMLDivElement>(null),
    subject: useRef<HTMLInputElement>(null),
    body: useRef<HTMLDivElement>(null)
  };

  const { modalsOpen, apiKey, emailPlaceholders } = ApplicationState;

  const getEmailTemplateEndpoint = (() => {
    if (!hasEventPermission && selectedEmailTemplate && jobId) {
      return Api.getEmailTemplateFromJob(
        apiKey,
        Number(selectedEmailTemplate.split(' -')[0]),
        jobId
      );
    }
  })();

  useGetEmailTemplate({
    apiKey,
    selectedEmailTemplate,
    setBody,
    dispatch,
    setSubject,
    endpoint: getEmailTemplateEndpoint
  });

  const { placeholders } = useGetSmsTemplatePlaceholders({ kind: 'form', dispatch });

  const { mutate: sendForm, isLoading: sendingForm } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } =
          notification === 'email'
            ? await Api.sendForm(application.job.id, application.id, {
                form_id: selectedForm?.id,
                email_template_id: selectedEmailTemplate?.split(' -')[0],
                status_id: status?.id,
                recipients: emails.join(','),
                subject,
                body
              })
            : await Api.sendFormBySMS(application.job.id, application.id, {
                form_id: selectedForm?.id,
                status_id: status?.id,
                phones: phoneNumbers.join(','),
                body: smsMessage.trim()
              });
        return res;
      }
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: 'Form has been sent', state: 'success' }
      });
      handleClose();
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `Failed to send form`, state: 'error' }
      })
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  const validateFields = () => {
    const fieldsErrors: Record<string, string> = {};
    setPhoneNumberError('');
    setSubmissionError('');
    if (step === 1) {
      !selectedForm && (fieldsErrors.form = 'Must select a form');
    } else {
      if (notification === 'email') {
        !selectedEmailTemplate && (fieldsErrors.template = 'Must select a template');
        !subject.trim() && (fieldsErrors.subject = 'Must add email subject');
        !body.trim() && (fieldsErrors.body = 'Must add email body');
        !emails.length && setSubmissionError('Must input at least one valid email');
      } else {
        !phoneNumbers.length && setPhoneNumberError('Must input at least one valid phone number');
        !smsMessage.length && (fieldsErrors.sms = 'Must add sms message');
      }
    }
    setErrors(fieldsErrors);
    if (fieldsErrors.form) {
      scrollToElement(fieldRefs.form);
    } else if (fieldsErrors.template) {
      scrollToElement(fieldRefs.template);
    } else if (fieldsErrors.subject) {
      scrollToElement(fieldRefs.subject);
    } else if (fieldsErrors.body) {
      scrollToElement(fieldRefs.body);
    }
    return !Object.keys(fieldsErrors).length;
  };

  const handleEmailSubmission = () => {
    const missingPlaceholders = checkMissingPlaceholders([ADD_FORM_PLACEHOLDER], body.trim());
    validateFields() &&
      (missingPlaceholders ? setMissingPlaceholderConfirmation(true) : sendForm());
  };
  const handleSubmit = () => {
    if (step === 1) {
      validateFields() && setStep(2);
    } else {
      if (notification === 'email') {
        handleEmailSubmission();
      } else {
        phoneNumbers.length && validateFields() && sendForm();
      }
    }
  };

  const buttonName = step === 1 ? 'Next' : 'Send';

  if (!jobId) return null;

  const subEntityGroupingEndpoint = Api.getSubEntityGroupedEmailTemplates(apiKey, jobId, {
    group_templates: true
  });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.SEND_FORM}
      label="Send form modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalStyleOverrides, ...(step === 1 ? {} : styles.wideModal) }}
      disableEnforceFocus
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Send form</Stack>
        {step === 1 ? (
          <Stack sx={{ rowGap: 3, padding: '0px 4px 16px 2px', overflow: 'auto' }}>
            <FormsDropdown
              selectedForm={selectedForm}
              setSelectedForm={(value) => {
                value && setErrors({ ...errors, form: '' });
                setSelectedForm(value);
              }}
              dispatch={dispatch}
              dropdownLabel="Form"
              error={errors.form}
              innerRef={fieldRefs.form}
            />
            <Autocomplete
              disablePortal
              options={applicationStatuses || []}
              getOptionLabel={(option) => option.name}
              value={status}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3, flexGrow: 1 }}
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="After completing the form, change status to"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={{ '& .MuiInputBase-root': { margin: 'unset', marginTop: 0.5 } }}
                />
              )}
              onChange={(event, value) => setStatus(value)}
            />
          </Stack>
        ) : (
          <Stack sx={{ overflow: 'auto', flexGrow: 1 }}>
            <NotificationOptionSwitch
              setNotification={setNotification}
              notification={notification}
            />
            {notification === 'email' ? (
              <Stack sx={{ rowGap: 2 }}>
                <MultiTagInput
                  tags={emails}
                  setTags={setEmails}
                  validation={emailIsValid}
                  validationMessage="Must input valid email"
                  label="Recipients"
                  width="100%"
                  error={submissionError}
                  tagType="emails"
                  helperText="To copy and paste bulk emails, email addresses must be separated with a comma"
                  required
                  styles={{ margin: 'unset' }}
                />
                <Stack sx={{ paddingRight: '2px', paddingTop: 3 }}>
                  <EmailsDropdown
                    apiKey={apiKey}
                    selectedEmailTemplate={selectedEmailTemplate}
                    setSelectedEmailTemplate={setSelectedEmailTemplate}
                    setBody={setBody}
                    dispatch={dispatch}
                    required
                    dropdownLabel="Email template"
                    error={errors.template}
                    innerRef={fieldRefs.template}
                    endpoint={subEntityGroupingEndpoint}
                  />
                </Stack>
                <Stack>
                  <FormTextField
                    label="Subject"
                    value={subject}
                    required
                    onChange={(v) => setSubject(v.target.value)}
                    error={errors.subject}
                    innerRef={fieldRefs.subject}
                  />
                  <Stack
                    sx={{
                      paddingLeft: 0.5,
                      color: '#838383',
                      fontSize: '11px',
                      lineBreak: 'anywhere'
                    }}
                  >{`Available placeholders are: ${emailPlaceholders}`}</Stack>
                </Stack>
                <EmailBodyComponent
                  body={body}
                  setBody={setBody}
                  placeholders={emailPlaceholders}
                  error={errors.body}
                  innerRef={fieldRefs.body}
                />
              </Stack>
            ) : (
              <>
                <MultiTagInput
                  tags={phoneNumbers}
                  setTags={setPhoneNumbers}
                  validation={validatePhone}
                  validationMessage="Must input valid phone number"
                  label="Phone numbers"
                  width="100%"
                  error={phoneNumberError}
                  tagType="phone number"
                  helperText="To copy and paste bulk sms, phone numbers must be separated with a comma"
                  required
                  styles={{ margin: 'unset' }}
                />
                <SmsTemplateComponent
                  smsMessageError={errors.sms}
                  {...{ smsTemplatePermissions, smsMessage, setSmsMessage, dispatch, placeholders }}
                />
              </>
            )}
          </Stack>
        )}
        <Box sx={{ ...classes.modalFooter, columnGap: 1, paddingTop: 1 }}>
          <Button
            id="close-add-to-event-modal-button"
            sx={classes.modalCancelButton}
            onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
          >
            Cancel
          </Button>
          {step !== 1 && (
            <Button
              onClick={() => setStep(1)}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            id="add-to-event-submit-button"
            type="submit"
            sx={{
              ...sharedClasses.genericButton,
              '&.Mui-disabled ': { background: '#DDDDDD', color: '#939393' }
            }}
            onClick={handleSubmit}
          >
            {sendingForm ? <CircularProgress size={20} color="inherit" /> : buttonName}
          </Button>
        </Box>
      </Stack>
      {missingPlaceholderConfirmation && (
        <MissingPlaceholdersConfirmationModal
          missingPlaceholderList={ADD_FORM_PLACEHOLDER}
          overrideFunction={sendForm}
          missingPlaceholderConfirmation={missingPlaceholderConfirmation}
          setMissingPlaceholderConfirmation={setMissingPlaceholderConfirmation}
          overridingLoading={sendingForm}
        />
      )}
    </StyledModal>
  );
}
