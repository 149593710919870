var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import { Radio, Grid, CircularProgress, withStyles } from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
import axios from "axios";
const PinkRadio = withStyles({
  root: {
    "&$checked": {
      color: "#E60278"
    }
  },
  checked: {}
})((props) => /* @__PURE__ */ React.createElement(Radio, __spreadValues({ color: "default" }, props)));
function AdProductSelection({
  debouncedTitle,
  answers,
  setAnswers,
  requiredRefs,
  jobAdId,
  setMaxBulletPoints,
  setBrandingEnabled
}) {
  const [seekAdProduct, setSeekAdProduct] = useState(null);
  const [adProductInfo, setAdProductInfo] = useState("");
  const [loadingSeekAdProduct, setLoadingSeekAdProduct] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const classes = useStyles();
  const setSelectedValue = (value) => {
    setSelectedId(value);
    setAnswers(__spreadProps(__spreadValues({}, answers), { ad_type: value }));
  };
  const handleChangeAdType = (ad) => {
    setSelectedValue(ad.id.value);
    setBrandingEnabled(!!ad.features.branding);
    setMaxBulletPoints(
      ad.features.searchBulletPoints ? Number(ad.features.searchBulletPoints.limit) : 0
    );
  };
  useEffect(() => {
    const getSeekAdProducts = (url) => __async(this, null, function* () {
      setLoadingSeekAdProduct(true);
      try {
        const { data } = yield axios.get(url, {
          params: {
            listing_sub_classification: answers.listing_sub_classification,
            listing_location: answers.listing_location,
            title: debouncedTitle,
            job_ad_id: jobAdId
          }
        });
        const { products, information } = data.data.advertisementProducts;
        setSeekAdProduct(products);
        setAdProductInfo(information);
        products.forEach((product) => {
          if (product.selected) {
            handleChangeAdType(product);
          }
        });
      } catch (exception) {
        console.error(exception);
        setSeekAdProduct(null);
      } finally {
        setLoadingSeekAdProduct(false);
      }
    });
    answers.listing_sub_classification && debouncedTitle && answers.listing_location && getSeekAdProducts(`/api/v4/seek/job_ads/${jobAdId}/ad_products`);
  }, [answers.listing_location, answers.listing_sub_classification, debouncedTitle, jobAdId]);
  if (loadingSeekAdProduct) {
    return /* @__PURE__ */ React.createElement(CircularProgress, { size: 20, color: "inherit" });
  }
  return /* @__PURE__ */ React.createElement(Grid, { container: true }, seekAdProduct && seekAdProduct.map((ad, index) => {
    const id = ad.id.value;
    const selected = selectedId === id;
    return /* @__PURE__ */ React.createElement(Grid, { item: true, xs: 12, sm: 12, md: Math.floor(12 / seekAdProduct.length), key: index }, /* @__PURE__ */ React.createElement("div", { className: classes.seekAdType, onClick: () => handleChangeAdType(ad) }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "seekAdheaderWrapper",
        style: index === 0 || index === seekAdProduct.length - 1 ? { borderRadius: index === 0 ? "5px 0 0 0" : "0 5px 0 0" } : {}
      },
      "Pay using your SEEK Contract"
    ), /* @__PURE__ */ React.createElement(
      "div",
      {
        style: selected ? { border: "#E60278 1px solid", height: "calc(100% - 2rem)" } : { border: "#E9E8E8 1px solid", height: "calc(100% - 2rem)" }
      },
      /* @__PURE__ */ React.createElement("div", { className: "seekRadioRadioBtnWrapper", ref: requiredRefs.ad_type }, /* @__PURE__ */ React.createElement(
        PinkRadio,
        {
          checked: selected,
          onChange: () => handleChangeAdType(ad),
          value: id,
          name: "radio-buttons",
          inputProps: { "aria-label": "A" }
        }
      )),
      /* @__PURE__ */ React.createElement("h4", null, ad.label),
      /* @__PURE__ */ React.createElement("div", null, ad.price && ad.price.summary),
      /* @__PURE__ */ React.createElement("div", { className: classes.seekEmbeddedHTML }, /* @__PURE__ */ React.createElement("div", null, ad.description), /* @__PURE__ */ React.createElement("ul", null, ad.sellingPoints.map((sp, index2) => /* @__PURE__ */ React.createElement("li", { key: index2 }, sp.text))))
    )));
  }), /* @__PURE__ */ React.createElement("p", { className: classes.adProductInfo }, adProductInfo));
}
AdProductSelection.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  jobAdId: PropTypes.number,
  setMaxBulletPoints: PropTypes.func.isRequired,
  setBrandingEnabled: PropTypes.func.isRequired,
  debouncedTitle: PropTypes.string
};
export default AdProductSelection;
