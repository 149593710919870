"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../../ThemeContext/ThemeObject";
import { classes as rootClass } from "../styles";
export const classes = __spreadProps(__spreadValues({}, rootClass), {
  sectionHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#666666"
  },
  editIcon: {
    fontSize: "14px",
    stroke: "#666666"
  },
  editIconWrapper: {
    background: "#eeeeee",
    "&:hover": { background: "#d6d6d6" },
    "&:focus": {
      outline: "none"
    },
    marginLeft: "0.5rem",
    padding: "7px"
  },
  reportDataWrapper: {
    display: "flex",
    marginTop: "15px",
    alignItems: "center"
  },
  contentWrapper: {
    color: "#666666",
    background: "#eeeeee",
    padding: "6px 16px",
    fontSize: "13px",
    display: "inline-flex",
    height: "fit-content",
    borderRadius: "1rem",
    letterSpacing: "0.1px",
    "&.active": {
      borderColor: theme.palette.secondary.main
    }
  },
  contentWrapperNumber: {
    color: "#666666",
    background: "#eee",
    padding: "4px 11px 6px 18px",
    marginLeft: "-1rem",
    fontSize: "14px",
    display: "inline-flex",
    height: "fit-content",
    borderRadius: "1rem",
    letterSpacing: "0.1px",
    "&.active": {
      borderColor: theme.palette.secondary.main
    }
  },
  contentEllipsis: {
    width: "63px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  popover: {
    marginTop: "15px"
  },
  popoverContent: {
    minWidth: "260px",
    padding: "22px 20px 22px 24px",
    boxShadow: "0px 2px 14px rgba(48,90,111,0.16)"
  },
  previewTableWrapper: {
    background: " #FFFFFF",
    boxShadow: "inset 0px 0px 30px -18px rgba(0, 0, 0, 0.3)",
    borderRadius: "6px",
    padding: "0 1rem 1rem 0rem",
    marginTop: "3rem"
  },
  previewInfoHeader: {
    background: "#EDF2F5",
    width: "fit-content",
    padding: "0.4rem 0.6rem 0.4rem 0.8rem",
    borderTopLeftRadius: "6px 6px",
    fontSize: "13.5px"
  },
  previewIcon: {
    color: "#084d6d",
    fontSize: "16px",
    marginRight: "0.8rem"
  },
  downloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 19px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  saveButton: {
    padding: "8px 19px",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#FFF"
    }
  },
  tabFooter: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "1em",
    columnGap: "20px",
    marginLeft: "auto"
  },
  exitText: {
    margin: "auto 0",
    color: "#E37D74",
    fontWeight: "700",
    cursor: "pointer",
    textDecoration: "underline"
  },
  previewContentWrapper: {
    marginRight: "5rem",
    marginBottom: "1.5rem"
  },
  filtersBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#084D6D",
      color: "#FFFFFF",
      transform: "translate(0%, -50%)",
      fontWeight: "bold",
      fontSize: "9px",
      minWidth: "16px",
      height: "16px"
    }
  },
  previewReportPeriod: {
    color: "#666666",
    background: "#eeeeee",
    padding: "6px 16px",
    borderRadius: "1rem",
    letterSpacing: "0.1px",
    "&.active": {
      borderColor: theme.palette.secondary.main
    }
  }
});
