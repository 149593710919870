"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
export const useSeekAccounts = ({ jobId }) => {
  const [seekAccounts, setSeekAccounts] = useState([]);
  useEffect(() => {
    const getSeekAccounts = () => __async(void 0, null, function* () {
      try {
        const response = yield fetch(`/api/v4/seek/jobs/${jobId}/accounts`);
        const { accounts } = yield response.json();
        setSeekAccounts(accounts);
      } catch (error) {
        console.log(error);
      }
    });
    getSeekAccounts();
  }, [jobId]);
  return { seekAccounts, setSeekAccounts };
};
