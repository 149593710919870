import React, { useState } from 'react';
import { Box, Button, CircularProgress, Modal, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectedApplicants from '../SelectedApplicants';
import { IExtendedActionModalProps } from '../../types';
import { classes } from '../styles';
import moment from 'moment';
import { SingleDatePicker } from '../../../Components/CustomUIElements/SingleDatePicker';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';

interface IFailedExports {
  candidate_name: string;
  error: string;
}

export default function ExportToOnboard({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters
}: IExtendedActionModalProps) {
  const [isSending, setIsSending] = useState(false);
  const [failedExports, setFailedExports] = useState<IFailedExports[] | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string>('');

  const handleClose = () => {
    setStartDate(null);
    setDateError('');
    setFailedExports(null);
    setModalsOpen((prev) => ({ ...prev, exportToOnboard: false }));
  };

  const handleSubmit = async () => {
    if (startDate) {
      setIsSending(true);
      try {
        const response = await fetch(`/api/v4/jobs/${jobId}/scout_onboard_exports`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-authenticate': apiKey
          },
          body: JSON.stringify({
            start_date: moment(startDate).format('YYYY-MM-DD'),
            application_ids: globalSelected.map((app) => app.id)
          })
        });
        if (response.ok) {
          const data = await response.json();
          if (data.errors) {
            setFailedExports(data.errors);
            setSnackbar({
              message: 'Some candidates failed to export',
              state: 'warning'
            });
          } else {
            handleClose();
            setSnackbar({
              message: 'Successfully exported candidate(s) to SCOUT Onboard',
              state: 'success'
            });
          }
        }
      } catch (error) {
        setSnackbar({
          message: 'Error exporting candidate(s) to SCOUT Onboard',
          state: 'error'
        });
      } finally {
        setIsSending(false);
      }
    } else {
      setDateError('Please select a start date');
    }
  };

  return (
    <Modal
      open={modalsOpen.exportToOnboard}
      onClose={handleClose}
      aria-labelledby="export-onboard-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
        <Box id="export-onboard-title" sx={classes.actionModalHeader}>
          Export candidates to Onboard
        </Box>
        <Box
          sx={classes.modalContent}
          id="export-onboard-modal-content"
          aria-label="Export Onboard Modal Content"
        >
          <Box sx={{ marginLeft: '15px' }}>
            <Stack sx={{ paddingBottom: 2 }}>
              <SingleDatePicker
                value={startDate}
                label={`When will the candidate(s) start?`}
                inputStyles={{ ...sharedClasses.datePicker, width: '275px' }}
                onChange={(value) => {
                  if (!value?.isValid()) {
                    setDateError('Please input a valid date');
                  } else {
                    setDateError('');
                    setStartDate(moment(value).toDate());
                  }
                }}
                showTodayButton
                error={dateError}
                required
              />
            </Stack>
            {!failedExports ? (
              <>
                <h3>Candidates</h3>
                <SelectedApplicants
                  globalSelected={globalSelected}
                  handleDeselectCandidate={handleDeselectCandidate}
                  closeModalCallback={handleClose}
                  filters={filters}
                />
              </>
            ) : (
              <Box sx={classes.centerColumn}>
                <h3>Some candidates failed to export:</h3>
                <Box sx={{ ...classes.centerColumn, ...classes.exportErrors }}>
                  {failedExports.map((failedExport) => (
                    <Box key={failedExport.candidate_name} sx={classes.centerColumn}>
                      <h4>{failedExport.candidate_name}</h4>
                      <p>{failedExport.error}</p>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {!failedExports ? (
          <Box sx={classes.modalFooter}>
            <Button
              id="close-export-onboard-button"
              sx={classes.modalCancelButton}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              id="submit-export-onboard-button"
              type="submit"
              sx={{ ...classes.modalSendButton, width: '160px', height: '41px' }}
              onClick={handleSubmit}
            >
              {isSending ? <CircularProgress size={20} color="inherit" /> : 'Export to Onboard'}
            </Button>
          </Box>
        ) : (
          <Box sx={classes.modalFooter}>
            <Button
              id="close-export-onboard-button"
              sx={{ ...classes.modalSendButton, width: '91px', height: '41px' }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
