import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import { classes } from '../styles';
import { StyledSnackbarProps } from '../../../Components/CustomUIElements/StyledSnackbar';
import Tooltip from '@mui/material/Tooltip';
import { IFilterDropdownProps, IDropdownProps } from '../../types';

const BASE_URL = window.location.origin;

export default function TempCSVExport({
  apiKey,
  jobId,
  setSnackbarState,
  filters,
  sortBy,
  sortOrder,
  sortableColumns
}: {
  apiKey: string;
  jobId: number;
  setSnackbarState: StyledSnackbarProps['setSnackBarState'];
  filters: IFilterDropdownProps['filters'];
  sortBy: string;
  sortOrder: string;
  sortableColumns: IDropdownProps['sortableColumns'];
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const activeColumns = {};
  sortableColumns.forEach((column) => {
    activeColumns[column.id] = column.enabled;
  });

  const handleSortTranslate = (sort: string) => {
    switch (sort) {
      case 'candidate':
        return 'candidate_name';
      case 'gender':
        return 'candidate_gender';
      case 'email':
        return 'candidate_email';
      case 'suburb':
        return 'candidate_suburb';
      case 'state':
        return 'candidate_state';
      case 'country':
        return 'candidate_state';
      case 'status':
        return 'status_name';
      case 'date':
        return 'created_at';
      default:
        return sort;
    }
  };

  const checkboxStyle = {
    color: '#DDDDDD',
    marginLeft: '10px',
    '&.Mui-checked': {
      color: '#5BC4BF'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '28px'
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleExportCSV = async () => {
    setIsLoading(true);
    let body;

    body = JSON.stringify({
      applications_report: {
        with_answers: true,
        sort: handleSortTranslate(sortBy),
        sort_order: sortOrder,
        filter: {
          min_rating: filters?.rating[0],
          max_rating: filters?.rating[1],
          exact_states: filters?.status.map((s) => s.id)
        },
        activeColumns: activeColumns,
        exclude_unrated: filters?.excludeApplicantsWithoutRating
      }
    });

    try {
      const response = await fetch(
        `${BASE_URL}/api/v4/jobs/${jobId}/applications/applications_report`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-api-authenticate': apiKey
          },
          body: body
        }
      );
      if (response.status === 202) {
        setSnackbarState({
          message:
            'Your CSV is being generated. Once complete, a download link will be sent to your email.',
          state: 'success'
        });
      } else {
        setSnackbarState({
          message: 'There was an error generating your CSV. Please try again.',
          state: 'error'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <Tooltip title="Export CSV" placement="top" arrow>
      <Box>
        <Box>
          <IconButton
            id="export-applications-csv-button"
            sx={classes.iconButton}
            onClick={handleModalOpen}
          >
            <IosShareIcon />
          </IconButton>
        </Box>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="export-applications-csv-modal"
        >
          <Box sx={classes.csvExportModal}>
            <CloseIcon onClick={() => setModalOpen(false)} sx={classes.closeIcon} />
            <Button
              id="export-applications-csv-button"
              sx={classes.csvExportButton}
              onClick={handleExportCSV}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: '#FFFFFF', fontSize: '20px' }} />
              ) : (
                'Export CSV'
              )}
            </Button>
          </Box>
        </Modal>
      </Box>
    </Tooltip>
  );
}
