import React, { Dispatch, useState } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { ModalType } from '../config';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../Components/GenericModal/ModalFooterButtons';
import { styles } from './styles';
import { IError } from '../../SmartForms/types';
import { IApprovalTemplate } from '../../ApprovalForms/ApprovalTemplates/types';

export default function CreateApproval({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [selectedTemplate, setSelectedTemplate] = useState<IApprovalTemplate | null>(null);
  const [movingPage, setMovingPage] = useState<boolean>(false);
  const [templateError, setTemplateError] = useState<string>('');
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { data: approvalTemplates, isLoading: loadingApprovalTemplates } = useQuery({
    queryKey: ['approval templates'],
    queryFn: async () => {
      const { res } = await Api.getApprovalTemplates();
      return res.enabled_requisition_forms;
    },
    onError: (error: IError) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: error.errors.join(', '), state: 'error' }
      })
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.CREATE_APPROVAL}
      label="Move application modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>New approval form</Stack>
        <Stack sx={{ paddingRight: '4px', overflow: 'auto', paddingBottom: 3 }}>
          <Autocomplete
            disablePortal
            options={approvalTemplates || []}
            getOptionLabel={(option) => option.name}
            value={selectedTemplate}
            sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 4, flexGrow: 1 }}
            loading={loadingApprovalTemplates}
            loadingText="Loading approval templates..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select an approval template"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={sharedClasses.autoCompleteTextfield}
                helperText={templateError}
                required
              />
            )}
            onChange={(event, value) => {
              value && setTemplateError('');
              setSelectedTemplate(value);
            }}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Confirm"
          primaryButtonCallback={() => {
            if (selectedTemplate) {
              setMovingPage(true);
              window.location.href = `/admin/requisitions/new?form_id=${selectedTemplate?.id}&job_id=${application?.job.id}&application_id=${application?.id}`;
            } else {
              setTemplateError('Must select a template');
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={movingPage}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
