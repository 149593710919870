import { RefObject } from 'react';

type ScrollOptions = {
  behavior?: 'auto' | 'smooth' | 'instant';
  block?: 'start' | 'center' | 'end' | 'nearest';
};

export const scrollToElement = <T extends HTMLElement>(
  elementRef: RefObject<T> | T | null,
  options: ScrollOptions = {}
): void => {
  const { behavior = 'smooth', block = 'center' } = options;
  const element = elementRef && 'current' in elementRef ? elementRef.current : elementRef;

  if (!element) return;

  window.requestAnimationFrame(() => {
    element.scrollIntoView({ behavior, block });
  });
};
