"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
export const classes = {
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: "30px",
    width: "100%",
    columnGap: "16px",
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  buttonRedLight: {
    color: "#E37D7A",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    padding: "8px 18px",
    fontWeight: "bold",
    fontSize: "14px",
    textDecoration: "underline",
    backgroundColor: "#ffffff",
    "&:hover": {
      color: "#d74742",
      backgroundColor: "#ffffff"
    }
  },
  revertloadButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    color: "#DDDDDD"
  },
  actionsModal: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    maxWidth: "800px",
    width: "90%",
    height: "95%",
    maxHeight: "328px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  actionsModalReducedPadding: {
    display: "flex",
    flexDirection: "column",
    padding: "60px",
    paddingLeft: "48px",
    maxWidth: "900px",
    width: "100%",
    height: "84vh",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& h3": {
      color: "#939393",
      fontSize: "19px",
      fontWeight: "Bold",
      marginBottom: "30px"
    }
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    margin: "46px 0px"
  },
  modalHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column"
  },
  modalEditButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    fontWeight: "bold",
    textTransform: "none",
    color: "#FFFFFF",
    backgroundColor: "#5BC4BF",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 18px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  iconGrey: {
    color: "#666666",
    fontSize: "28px"
  },
  tabs: {
    "& button": {
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      letterSpacing: "0",
      color: "#939393",
      textTransform: "none",
      minWidth: "unset",
      "&:hover": {
        color: "#737373"
      },
      "&:focus": {
        outline: "none"
      }
    },
    "& button.Mui-selected": {
      fontWeight: "600",
      color: "#084D6D"
    }
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "#EEEEEE",
    marginBottom: "25px"
  },
  tabIndicator: {
    backgroundColor: "#5BC4BF",
    margin: "0 16px"
  },
  modalFormLine: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    columnGap: "50px",
    rowGap: "26px",
    alignItems: "center",
    marginBottom: "30px",
    width: "100%"
  },
  jobPageContainer: {
    backgroundColor: "#FFFFFF",
    padding: "40px"
  },
  jobPageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "26px",
    padding: "0px 16px",
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  backToJobButton: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  draftButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: "rgb(91,196,191)",
    background: "rgb(91,196,191,0.12)",
    height: "41px",
    margin: "8px 0",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      boxShadow: "none"
    }
  },
  cancelButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    width: "70px",
    textTransform: "none",
    textDecoration: "underline",
    color: theme.palette.error.main,
    backgroundColor: "#FFFFFF",
    height: "41px",
    margin: "8px 0",
    marginRight: "auto",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "5px 0px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      boxShadow: "none"
    }
  },
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    padding: "1em",
    justifyContent: "flex-end",
    columnGap: "20px"
  },
  sectionHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#666666",
    padding: "8px 4px"
  },
  sectionSubtitle: {
    color: "#838383",
    fontFamily: "Source Sans Pro",
    paddingInline: "4px"
  },
  approversContainer: {
    display: "flex",
    marginTop: 3,
    alignItems: "center",
    padding: "8px 0"
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0"
  },
  fileIconContainer: {
    display: "flex",
    alignItems: "center"
  },
  fileIcon: {
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main
    }
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "350px"
  },
  fileSize: {
    color: "#999999"
  }
};
