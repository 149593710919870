"use strict";
import { theme } from "../../../../ThemeContext/ThemeObject";
export const styles = {
  section: {
    flexBasis: "40%",
    flexGrow: 1,
    height: "420px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column"
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  button: {
    fontSize: "12px",
    fontWeight: "bold",
    backgroundColor: "#5BC4BF1F",
    color: theme.palette.action.main,
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.main,
      color: "#FFFFFF"
    }
  },
  tabContainer: {
    display: "flex",
    gap: "20px",
    borderBottom: "1px solid #DDDDDD"
  },
  tab: {
    color: "#939393",
    fontSize: "18px",
    paddingBottom: "10px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  tabSelected: {
    color: theme.palette.primary.main,
    fontWeight: "600"
  },
  line: {
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    bottom: 0,
    borderRadius: "1px",
    transition: "left 0.25s cubic-bezier(.6,.36,.25,1), width 0.25s cubic-bezier(.6,.36,.25,1)"
  },
  taskContainer: {
    padding: "20px 0",
    overflowY: "scroll",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "26px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "& .MuiSkeleton-root": {
      transformOrigin: "0px 0px !important"
    },
    "& .spinner": {
      margin: "auto"
    }
  },
  emptyStateText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "8px"
  }
};
