import React from 'react';

export function ScoutOnboardLogo() {
  return (
    <svg
      width="100"
      height="22"
      viewBox="0 0 300 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79181 23.2879C3.39981 23.2879 0.903809 20.5999 0.903809 17.1439C0.903809 13.6879 3.39981 10.9999 6.79181 10.9999C10.1838 10.9999 12.7438 13.6879 12.7438 17.1439C12.7438 20.5999 10.1838 23.2879 6.79181 23.2879ZM6.79181 43.7679C3.39981 43.7679 0.903809 41.0799 0.903809 37.6239C0.903809 34.1679 3.39981 31.4799 6.79181 31.4799C10.1838 31.4799 12.7438 34.1679 12.7438 37.6239C12.7438 41.0799 10.1838 43.7679 6.79181 43.7679Z"
        fill="#084D6D"
      />
      <path
        d="M38.5768 43.7679C27.2488 43.7679 19.5048 35.6399 19.5048 22.0079C19.5048 8.31187 27.2488 0.567871 38.5768 0.567871C49.9048 0.567871 57.6488 8.37587 57.6488 22.0079C57.6488 35.6399 49.9048 43.7679 38.5768 43.7679ZM38.5768 35.1919C44.0808 35.1919 47.4728 30.0719 47.4728 22.0079C47.4728 13.8799 44.0808 9.14387 38.5768 9.14387C33.1368 9.14387 29.6808 13.8799 29.6808 22.0079C29.6808 30.0719 33.1368 35.1919 38.5768 35.1919Z"
        fill="#084D6D"
      />
      <path
        d="M65.3623 42.9999V1.33587H75.6663L85.9063 21.3039C87.3783 24.1199 88.7223 27.4479 90.0023 30.7119H90.2583C89.7463 26.2319 88.9783 20.2799 88.9783 15.3519V1.33587H98.3863V42.9999H88.1463L77.8423 23.0319C76.4343 20.2159 75.0903 16.8879 73.8103 13.6239H73.5543C74.0663 18.2319 74.8343 23.9919 74.8343 28.9839V42.9999H65.3623Z"
        fill="#084D6D"
      />
      <path
        d="M108.05 42.9999V1.33587H122.578C131.218 1.33587 138.066 3.63987 138.066 11.7039C138.066 15.3519 136.018 19.3839 132.626 20.6639V20.9199C136.85 22.0719 140.114 25.1439 140.114 30.7119C140.114 39.2239 132.818 42.9999 123.794 42.9999H108.05ZM118.034 17.9119H122.194C126.482 17.9119 128.338 16.0559 128.338 13.1759C128.338 10.1679 126.354 8.95187 122.258 8.95187H118.034V17.9119ZM118.034 35.3839H123.026C127.89 35.3839 130.386 33.5919 130.386 30.0719C130.386 26.5519 127.954 25.2079 123.026 25.2079H118.034V35.3839Z"
        fill="#084D6D"
      />
      <path
        d="M164.077 43.7679C152.749 43.7679 145.005 35.6399 145.005 22.0079C145.005 8.31187 152.749 0.567871 164.077 0.567871C175.405 0.567871 183.149 8.37587 183.149 22.0079C183.149 35.6399 175.405 43.7679 164.077 43.7679ZM164.077 35.1919C169.581 35.1919 172.973 30.0719 172.973 22.0079C172.973 13.8799 169.581 9.14387 164.077 9.14387C158.637 9.14387 155.181 13.8799 155.181 22.0079C155.181 30.0719 158.637 35.1919 164.077 35.1919Z"
        fill="#084D6D"
      />
      <path
        d="M200.349 22.1359L199.453 25.7199H207.965L207.069 22.1359C205.981 18.0399 204.893 13.1119 203.805 8.75987H203.549C202.525 13.1119 201.437 18.0399 200.349 22.1359ZM184.925 42.9999L197.917 1.33587H209.821L222.813 42.9999H212.317L209.885 33.3999H197.533L195.101 42.9999H184.925Z"
        fill="#084D6D"
      />
      <path
        d="M227.112 42.9999V1.33587H242.408C251.24 1.33587 258.664 4.40787 258.664 14.4559C258.664 20.5359 255.784 24.4399 251.496 26.4879L260.776 42.9999H249.64L241.96 28.3439H237.096V42.9999H227.112ZM237.096 20.4719H241.64C246.376 20.4719 248.936 18.4239 248.936 14.4559C248.936 10.4879 246.376 9.20787 241.64 9.20787H237.096V20.4719Z"
        fill="#084D6D"
      />
      <path
        d="M266.675 42.9999V1.33587H278.643C291.379 1.33587 299.763 7.54387 299.763 22.0079C299.763 36.4079 291.379 42.9999 279.219 42.9999H266.675ZM276.659 34.9999H278.067C284.787 34.9999 289.587 31.8639 289.587 22.0079C289.587 12.0879 284.787 9.33587 278.067 9.33587H276.659V34.9999Z"
        fill="#084D6D"
      />
    </svg>
  );
}
