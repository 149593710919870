import React, { useState, Dispatch, useContext } from 'react';
import {
  Box,
  Popover,
  Button,
  Modal,
  Badge,
  Stack,
  CircularProgress,
  IconButton,
  Popper
} from '@mui/material';
import { classes } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PreviewTable from '../../../NewUI/Components/PreviewTable';
import moment from 'moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReportTableBody from '../../../NewUI/Components/PreviewTable/TableBody';
import { getFieldsTotal, getCreateReportParams, downloadParams } from '../helper';
import DownloadPreview from '../ModalContents/DownloadPreview';
import { saveAs } from 'file-saver';
import { useMutation } from '@tanstack/react-query';
import { ReportContext } from '../../index';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import { StyledSnackbarProps } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import { CustomReportsActionType, CustomReportsStateType } from '../types';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { dateRangeOptions } from '../../DataVizSection/GraphDefaultOptions';
import DateStartToEndRangeField from '../../../NewUI/Components/CustomUIElements/DateRangePickerWithDynamicTitle/DateStartToEndRangeField';
import EntityFiltersModal from './EntityFiltersModal';
import { useReportOptions } from '../hooks';

const CustomReportPreview = ({
  reportState,
  dispatch,
  setSnackbar,
  loadPreviewTable,
  refetchForms
}: {
  reportState: CustomReportsStateType;
  dispatch: Dispatch<CustomReportsActionType>;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  loadPreviewTable: boolean;
  refetchForms: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [downloadCSVOpen, setDownloadCSVOpen] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [editTimePeriod, setEditTimePeriod] = useState<boolean>(false);
  const [openCustomPeriod, setOpenCustomPeriod] = useState<boolean>(false);
  const [openEntityFiltersModal, setOpenEntityFiltersModal] = useState<boolean>(false);
  const reportApiService = useContext(ReportContext);
  const actionName = reportState.reportId ? 'Update' : 'Save';

  const handleTimePeriodChange = (value: { value: number | string; label: string }) => {
    if (value.value === 'custom') {
      setOpenCustomPeriod(true);
    } else {
      dispatch({
        type: 'SET_DATE',
        payload: {
          startDate: moment().subtract(value.value, 'days').toDate(),
          endDate: moment().toDate(),
          dateRange: value
        }
      });
      setAnchorEl(null);
    }
    setEditTimePeriod(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };

  const {
    mutate: downloadReport,
    isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (reportState: CustomReportsStateType) =>
      reportApiService.reports
        .download(downloadParams(reportState, reportApiService))
        .then((blob) => saveAs(blob, `${reportState?.data?.name}.csv`))
        .catch(() => setSnackbar({ message: 'Failed to download report', state: 'error' }))
  });

  const saveReport = useMutation({
    mutationFn: async (reportState: CustomReportsStateType) => {
      reportState.reportId
        ? await reportApiService.reports
            .update(reportState.reportId!, getCreateReportParams(reportState))
            .then((options) => {
              if (options.error) throw new Error();
            })
        : await reportApiService.reports
            .saveReport(getCreateReportParams(reportState))
            .then((options) => {
              if (options.error) throw new Error();
            });
    },
    onError: () =>
      setSnackbar({
        message: `Failed to ${actionName} ${reportState.data.name} to Reports Builder`,
        state: 'error'
      }),
    onSuccess: () => {
      setSnackbar({
        message: `${actionName}d ${reportState.data.name} to Reports Builder`,
        state: 'success'
      });
      refetchForms();
    }
  });

  const { isLoading: loadingCreateOptions } = useReportOptions(
    dispatch,
    setSnackbar,
    reportApiService
  );

  function showEllipsisIfManySubEntities() {
    const entities = reportState?.data?.entityIds;
    if (!entities) return;
    return entities[0]?.id === reportState?.createReportOptions?.entitiesOptions?.current_entity?.id
      ? entities[1]?.name
      : entities[0]?.name;
  }

  const previewTableLength = reportState?.previewTable?.data?.length;
  const filtersApplied = getFieldsTotal(reportState);

  return (
    <Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Box sx={classes.previewContentWrapper}>
          <Box sx={classes.sectionHeader}>Report name </Box>
          <Box sx={{ ...classes.reportDataWrapper, alignItems: 'unset' }}>
            <Box sx={{ marginTop: '7px', fontSize: '13px' }}>{reportState?.data?.name}</Box>
            <IconButton
              sx={classes.editIconWrapper}
              onClick={() => dispatch({ type: 'SET_EDIT_TITLE_FIELDS', payload: true })}
            >
              <EditIcon sx={classes.editIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={classes.previewContentWrapper}>
          <Box sx={classes.sectionHeader}>Report period</Box>
          <Box sx={classes.reportDataWrapper}>
            <Box sx={classes.contentWrapper}>
              {`${moment(reportState.startDate).format('D MMM YYYY')} \u2014 `}
              <em>{`${moment(reportState.endDate).format('D MMM YYYY')}`}</em>
            </Box>
            <IconButton
              sx={classes.editIconWrapper}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(event.currentTarget);
                setEditTimePeriod(true);
              }}
            >
              <EditIcon sx={classes.editIcon} />
            </IconButton>
            <Popper
              id="report-preview-time-period-popper"
              sx={{ background: 'inherit' }}
              open={editTimePeriod}
              anchorEl={anchorEl}
              placement="bottom-end"
            >
              <ClickAwayListener onClickAway={() => setEditTimePeriod(false)}>
                <Stack
                  sx={{
                    width: '200px',
                    padding: '8px 0px',
                    boxShadow: '0px 2px 14px rgba(48,90,111,0.16)',
                    marginTop: 2,
                    borderRadius: '6px'
                  }}
                >
                  {dateRangeOptions.map((option, index) => (
                    <Stack
                      key={index}
                      sx={{
                        padding: '8px 24px',
                        '&:hover': { background: 'rgba(8,77,109,0.05)' },
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: `${index === 7 ? '#084D6D' : '#666666'}`,
                        backgroundColor: `${
                          reportState.dateRange.value === option.value
                            ? 'rgba(25,118,210,0.12)'
                            : 'unset'
                        }`
                      }}
                      onClick={() => handleTimePeriodChange(option)}
                    >
                      {option.label}
                    </Stack>
                  ))}
                </Stack>
              </ClickAwayListener>
            </Popper>
            {openCustomPeriod && anchorEl && (
              <DateStartToEndRangeField
                isLoading={false}
                reportState={{
                  startDate: reportState.startDate as Date,
                  endDate: reportState.endDate as Date,
                  dateRange: { value: 'custom', label: 'Custom' }
                }}
                reportDispatch={dispatch}
                fieldName="SET_DATE"
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleClose={() => setOpenCustomPeriod(false)}
              />
            )}
          </Box>
        </Box>
        <Box sx={classes.previewContentWrapper}>
          <Box sx={classes.sectionHeader}>Entities</Box>
          <Box sx={classes.reportDataWrapper}>
            <Box sx={classes.contentWrapper}>
              <Box sx={{ display: 'contents' }}>
                <Box sx={{ fontWeight: '600', paddingRight: '4px' }}>Entity: </Box>
                <Box>{reportState?.createReportOptions?.entitiesOptions?.current_entity?.name}</Box>
              </Box>
            </Box>
            <Box sx={{ paddingLeft: '0.5rem' }} />
            {reportState?.data?.entityIds && reportState?.data?.entityIds?.length !== 0 && (
              <Box>
                {reportState?.data?.entityIds.length > 1 ? (
                  <Box
                    sx={{ display: 'flex', '&:hover': { cursor: 'pointer' } }}
                    onClick={handleClick}
                  >
                    <Box
                      sx={{
                        ...classes.contentWrapper,
                        paddingRight: '5px',
                        borderRight: '4px solid white',
                        zIndex: '1'
                      }}
                    >
                      <Box sx={{ display: 'contents' }}>
                        <Box sx={{ fontWeight: '600', paddingRight: '4px', whiteSpace: 'nowrap' }}>
                          Sub entities:{' '}
                        </Box>
                        <Box sx={classes.contentEllipsis}>{showEllipsisIfManySubEntities()}</Box>
                      </Box>
                    </Box>
                    <Box sx={classes.contentWrapperNumber}>
                      <Box sx={{ display: 'contents' }}>
                        <Box>
                          <Box
                            sx={{ fontWeight: '600' }}
                          >{`+${reportState?.data?.entityIds.length}`}</Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={classes.contentWrapper}>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ fontWeight: '600', paddingRight: '4px' }}>Sub-Entity: </Box>
                      <Box>{reportState?.data?.entityIds?.map((entity) => `${entity.name}  `)}</Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            <IconButton
              sx={classes.editIconWrapper}
              onClick={() => setOpenEntityFiltersModal(true)}
            >
              <EditIcon sx={classes.editIcon} />
            </IconButton>
            <Popover
              id="field-dropdown-popover"
              sx={classes.popover}
              open={dropdownOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Box sx={classes.popoverContent}>
                <Box sx={{ display: 'flex', marginBottom: '0.75rem' }}>
                  <Box sx={{ fontWeight: '600', paddingRight: '7px' }}>Entity: </Box>
                  <Box>
                    {reportState?.createReportOptions?.entitiesOptions?.current_entity?.name}
                  </Box>
                </Box>
                {reportState?.data?.entityIds?.map((entity, i) => (
                  <Box key={i}>
                    {reportState?.createReportOptions?.entitiesOptions?.current_entity?.id ===
                    entity.id ? (
                      <Box />
                    ) : (
                      <Box sx={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <Box sx={{ fontWeight: '600', paddingRight: '7px' }}>Sub-Entity: </Box>
                        <Box>{entity.name}</Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Popover>
          </Box>
        </Box>
        <Box>
          <Box sx={classes.sectionHeader}>Fields</Box>
          <Box sx={classes.reportDataWrapper}>
            <Box sx={{ display: 'flex', fontSize: '13px' }}>
              <Badge badgeContent={filtersApplied} sx={classes.filtersBadge}>
                <Box
                  sx={{
                    ...classes.previewReportPeriod,
                    cursor: `${filtersApplied ? 'pointer' : ''}`
                  }}
                  onClick={() => {
                    if (filtersApplied) {
                      dispatch({ type: 'SET_EDIT_TITLE_FIELDS', payload: false });
                    }
                  }}
                >
                  <Stack>{filtersApplied ? 'View filters' : 'None applied'}</Stack>
                </Box>
              </Badge>
            </Box>
            <IconButton
              sx={classes.editIconWrapper}
              onClick={() => dispatch({ type: 'SET_EDIT_TITLE_FIELDS', payload: false })}
            >
              <EditIcon sx={classes.editIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box sx={classes.previewTableWrapper}>
          {previewTableLength ? (
            <Box sx={classes.previewInfoHeader}>
              <RemoveRedEyeIcon sx={classes.previewIcon} /> Preview Showing 1 - {previewTableLength}{' '}
              out of {previewTableLength}
            </Box>
          ) : null}
          <PreviewTable
            tableHeaders={reportState.previewTable.tableHeaders}
            data={reportState.previewTable.data}
            isLoading={loadPreviewTable}
            tableBody={<ReportTableBody state={reportState.previewTable} />}
          />
        </Box>
        <Box sx={{ display: 'inline-flex', width: '100%', paddingTop: '1rem' }}>
          <Box sx={{ paddingTop: '1em' }}>
            <Button
              variant="contained"
              onClick={() =>
                dispatch({
                  type: 'EXIT_PREVIEW',
                  payload: {
                    entity_id: reportApiService.accessibleEntities,
                    user: [reportApiService.currentUser.id]
                  }
                })
              }
              sx={classes.exitButton}
            >
              Exit
            </Button>
          </Box>
          <Box sx={{ ...classes.tabFooter, minWidth: 'unset' }}>
            <Button
              id="save-report-button"
              type="submit"
              variant="contained"
              disabled={saveReport.isLoading}
              sx={{ ...sharedClasses.saveButton, minWidth: '135px' }}
              onClick={() => saveReport.mutate(reportState)}
            >
              {saveReport.isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                `${actionName} Report`
              )}
            </Button>
            <Button
              id="download-report-button"
              type="submit"
              variant="contained"
              sx={{ ...classes.downloadButton }}
              onClick={() => {
                setDownloadCSVOpen(true);
                downloadReport(reportState);
              }}
            >
              Download CSV
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal open={downloadCSVOpen} onClose={() => setDownloadCSVOpen(false)}>
        <Box sx={{ ...sharedClasses.modalBody, maxWidth: '480px', minHeight: '430px' }}>
          <CloseIcon onClick={() => setDownloadCSVOpen(false)} sx={classes.closeIcon} />
          <DownloadPreview
            finishedDownload={isLoading}
            file={isSuccess}
            downloadAgain={() => {
              setDownloadCSVOpen(true);
              downloadReport(reportState);
            }}
          />
        </Box>
      </Modal>
      {openEntityFiltersModal && (
        <EntityFiltersModal
          reportState={reportState}
          dispatch={dispatch}
          openEntityFiltersModal={openEntityFiltersModal}
          setOpenEntityFiltersModal={setOpenEntityFiltersModal}
          loadingCreateOptions={loadingCreateOptions}
        />
      )}
    </Box>
  );
};

export default CustomReportPreview;
