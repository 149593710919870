var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  TextField,
  Radio,
  RadioGroup,
  Grid,
  Box,
  Select,
  MenuItem
} from "@material-ui/core";
import useStyles from "../useStyles";
import { useSeekPayTypes } from "../../hooks/useSeekPayTypes";
import { useSeekCurrencies } from "../../hooks/useSeekCurrencies";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import TextAreaWithCounter from "../UtilComponents/TextAreaWithCounter";
function PayDetails({ answers, setAnswers, requiredRefs, activeError }) {
  const { payTypes } = useSeekPayTypes();
  const { currencies } = useSeekCurrencies();
  const classes = useStyles();
  const setSalaryType = (value) => {
    const [basisCode, intervalCode] = value.split(":");
    setAnswers(__spreadProps(__spreadValues({}, answers), { salary_type: basisCode, salary_interval: intervalCode }));
  };
  const parseSalary = (value) => {
    return parseFloat(value.split(",").join("")) || 0;
  };
  return /* @__PURE__ */ React.createElement(Box, { className: classes.payDetailsContainer }, /* @__PURE__ */ React.createElement("h4", null, "Pay details"), /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Pay Type"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: `${answers.salary_type}:${answers.salary_interval || "Hour"}`,
      onChange: (e) => setSalaryType(e.target.value)
    },
    payTypes.map((pay) => /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        key: pay.label,
        label: pay.label,
        value: `${pay.basisCode}:${pay.intervalCode}`,
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ))
  )), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.salary,
      fullWidth: true,
      className: `${classes.formControl} ${classes.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Pay Range ($AUD)", /* @__PURE__ */ React.createElement("span", { className: classes.asterisk }, " *"), activeError === requiredRefs.salary && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), !answers.salary_min || !answers.salary_max ? /* @__PURE__ */ React.createElement("span", null, "This field is required") : /* @__PURE__ */ React.createElement("span", null, "The pay range cannot exceed $100,000"))),
    /* @__PURE__ */ React.createElement("span", { className: classes.subLabel }, "Enter a pay range to offer candidates (this will not show on your ad)"),
    /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: classes.controlledNumberInput } }, /* @__PURE__ */ React.createElement(
      Grid,
      {
        item: true,
        container: true,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        wrap: "nowrap"
      },
      /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, { m: 1, ml: 0 }, /* @__PURE__ */ React.createElement(
        TextField,
        {
          error: activeError === requiredRefs.salary,
          className: classes.textField,
          variant: "outlined",
          onBlur: () => {
            if (answers.salary_min > answers.salary_max)
              setAnswers(__spreadProps(__spreadValues({}, answers), { salary_max: answers.salary_min }));
          },
          value: Number(answers.salary_min).toLocaleString(),
          InputProps: {
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "From")
          },
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { salary_min: parseSalary(e.target.value) }))
        }
      )), /* @__PURE__ */ React.createElement(Box, { m: 1 }, /* @__PURE__ */ React.createElement(
        TextField,
        {
          error: activeError === requiredRefs.salary,
          className: classes.textField,
          variant: "outlined",
          value: Number(answers.salary_max).toLocaleString(),
          onBlur: () => {
            if (answers.salary_max < answers.salary_min)
              setAnswers(__spreadProps(__spreadValues({}, answers), { salary_max: answers.salary_min }));
          },
          InputProps: {
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "To")
          },
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { salary_max: parseSalary(e.target.value) }))
        }
      )), /* @__PURE__ */ React.createElement(Box, { m: 1 }, /* @__PURE__ */ React.createElement(
        Select,
        {
          classes: { select: classes.dropdown },
          variant: "outlined",
          value: answers.currency,
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { currency: e.target.value })),
          displayEmpty: false,
          renderValue: (value) => value
        },
        currencies.map((currency) => /* @__PURE__ */ React.createElement(MenuItem, { key: currency.code, value: currency.code }, currency.code))
      )), /* @__PURE__ */ React.createElement("span", { className: classes.salaryIntervalLabel }, "per ", answers.salary_interval || "Hour"))
    ))
  ), /* @__PURE__ */ React.createElement(
    TextAreaWithCounter,
    {
      title: "Customise the Pay information on your Ad (Optional)",
      value: answers.salary_additional_text,
      setValue: (value) => setAnswers(__spreadProps(__spreadValues({}, answers), { salary_additional_text: value })),
      maxLength: 50,
      helperText: "E.g. $50,000 + annual bonus"
    }
  ));
}
PayDetails.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  requiredRefs: PropTypes.object.isRequired,
  activeError: PropTypes.ref
};
export default PayDetails;
