import React from 'react';
import { Avatar, Box } from '@mui/material';
import { classes } from './SystemReports/styles';
import { SavedReportRecord } from './types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const locale = navigator.language || navigator.languages[0];
const timeFormat = locale === 'en-US' ? 'MM/DD/YYYY, HH:mm A' : 'DD/MM/YYYY HH:mm A';

export const tableValueFormat = (
  value: string,
  name: string,
  record: SavedReportRecord,
  timezone: string | null
) => {
  switch (name) {
    case 'query_config':
      const startDateStr = formatDate(record.query_config?.dates?.startDate, timezone);
      const endDateStr = formatDate(record.query_config?.dates?.endDate, timezone);
      return value
        ? `${startDateStr} - ${endDateStr}`
        : '-';
    case 'updated_at':
      return formatDate(value, timezone);
    case 'created_at':
      return formatDate(value, timezone);
    case 'report_type':
      if (!value) return;
      return value?.charAt(0)?.toUpperCase() + value?.slice(1);
    case 'title':
      return record.report_type === 'system' ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {value}
          <Avatar sx={{ ...classes.avatarStyle, marginLeft: '10px' }} variant="square">
            S
          </Avatar>
        </Box>
      ) : (
        value
      );
    default:
      return value;
  }
};

const formatDate = (value: string, timezone: string | null) => {
  if (!value) {
    return '-';
  }
  if (timezome) {
    return dayjs(value).tz(timezone).format(timeFormat);
  }
  return (new Date(value)).toLocaleString();
};

export const dateRangeOptionsWithNoCustom = [
  { value: 7, label: 'Every 7 days' },
  { value: 30, label: 'Every 30 Days' },
  { value: 90, label: 'Every 90 days' },
  { value: 180, label: 'Every 180 days' },
  { value: 365, label: 'Every 1 year' },
  { value: 1095, label: 'Every 3 years' },
  { value: 1825, label: 'Every 5 years' }
];

export const newBuildBody = (state: any, newFilter: boolean) => {
  const body = {
    page: newFilter ? 1 : state.currentPage,
    limit: state.limit,
    entity_id: state.entity_id,
    user: state.user
  };
  if (state.sort_by) {
    body['sort_by'] = state.sort_by;
    body['sort_order'] = state.sort_order;
  }
  if (state.searchItem) body['q'] = state.searchItem;

  if (state.filters.report_type && state.filters.report_type.length > 0) {
    body['report_type'] = state.filters.report_type.map((item) => item.name);
  }
  if (state.filters.user && state.filters.user.length > 0) {
    body['user'] = state.filters.user.map((item) => item.id);
  }

  return body;
};
