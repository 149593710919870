import React from 'react';
import { Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { IAttachment } from '../../types';
import { classes } from '../../../Components/Modals/styles';
import { useDragScroll } from '../../../hooks';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfPreview({
  onPinchHandler,
  selectedAttachment,
  setIsLoadingPreview,
  zoom
}: {
  onPinchHandler: (...args: unknown[]) => ReactDOMAttributes;
  selectedAttachment: IAttachment;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
  zoom: number;
}) {
  const BASE_URL = window.location.origin;
  const [scrollRef] = useDragScroll();
  const scaledDimension = `${(100 / zoom) * 0.1}%`;

  const [numPages, setNumPages] = React.useState<number>(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setIsLoadingPreview(false);
  }

  function onDocumentLoadProgress({ loaded, total }: { loaded: number; total: number }) {
    if (loaded >= total) {
      setIsLoadingPreview(false);
    }
  }

  return (
    <Box sx={{ overflow: 'auto' }} ref={scrollRef} {...onPinchHandler()}>
      <Box
        sx={{
          paddingBottom: '5px',
          touchAction: 'none',
          transform: `scale(${zoom})`,
          width: scaledDimension,
          height: scaledDimension
        }}
      >
        <Document
          file={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
          loading={<Box sx={classes.previewLoader}></Box>}
          onLoadStart={() => setIsLoadingPreview(true)}
          onLoadError={() => setIsLoadingPreview(false)}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadProgress={onDocumentLoadProgress}
        >
          {[...Array(numPages)].map((_, page) => (
            <Box sx={{ ...classes.pdfPage }} key={page}>
              <Page
                width={750}
                pageNumber={page + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Box>
          ))}
        </Document>
      </Box>
    </Box>
  );
}
