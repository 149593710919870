import React, { useState, useEffect } from 'react';
import { classes } from './styles';
import Box from '@mui/material/Box';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';
import { IExpiryDialogProps } from './types';
import { SingleDateTimePicker } from '../CustomUIElements/SingleDatePicker';

function ExpiryDialog({
  isExpiryDialogOpen,
  setExpiryDialogOpen,
  ad,
  apiKey,
  handleSnack,
  handleLocalExpiryChange
}: IExpiryDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [newExpiryDate, setNewExpiryDate] = useState(moment());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setNewExpiryDate(moment(ad?.expired_at));
  }, [setNewExpiryDate, ad]);

  async function handleSave() {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v4/seek/job_ads/${ad.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-api-authenticate': apiKey
        },
        body: JSON.stringify({
          title: ad.title,
          description: ad.description,
          date: newExpiryDate.format('YYYY-MM-DD')
        })
      });
      if (response.status !== 200 && seekResponse.status !== 200) {
        console.log('error');
        setExpiryDialogOpen(false);
        handleSnack('Error updating expiry date');
      } else {
        handleLocalExpiryChange(moment(newExpiryDate).toDate(), ad.id);
      }
    } catch (error) {
      console.error(error);
      handleSnack('Error updating expiry date');
    } finally {
      setIsLoading(false);
      setExpiryDialogOpen(false);
    }
  }

  const calculateMinDate = (published: string) => {
    const now = moment();
    const publishedDate = moment(published).add(1, 'day');
    const diff = now.diff(publishedDate, 'days');
    if (diff > 0) {
      return now;
    } else {
      return publishedDate;
    }
  };

  return (
    <Dialog
      open={isExpiryDialogOpen}
      onClose={() => setExpiryDialogOpen(false)}
      sx={{ '& .MuiPaper-root': classes.dialog }}
    >
      <h4>Ad expiry date</h4>
      <Box sx={classes.expiryMessage}>
        Choose a new expiry date to shorten the duration of your job ad
      </Box>
      <Box sx={classes.expiryDateMessage}>
        Your ad will run until 11:59PM {moment().tz(timeZoneString).format('zz')}
      </Box>
      {ad?.created_at && ad?.advertisement_published_at && ad?.expired_at && (
        <>
          <SingleDateTimePicker
            open={calendarOpen}
            onOpen={() => setCalendarOpen(true)}
            onClose={() => setCalendarOpen(false)}
            disableMaskedInput
            value={newExpiryDate}
            minDate={calculateMinDate(ad.advertisement_published_at)}
            maxDate={moment(ad.advertisement_published_at).add(29, 'days')}
            onChange={setNewExpiryDate}
          />

          <Box sx={classes.expiryPostPickerMessage}>
            And be live for <span>{moment(newExpiryDate).diff(moment(), 'days')} days</span>
          </Box>
          <Box sx={classes.expiryChangePreview}>
            <Box sx={classes.expiryDateContainer}>
              <Box sx={classes.expiryTitle}>Expiry (current)</Box>
              <Box sx={classes.expiryDate}>{moment(ad.expired_at).format('DD MMM YYYY')}</Box>
            </Box>
            <ArrowRightAltIcon sx={{ margin: '14px' }} />
            <Box sx={classes.expiryDateContainer}>
              <Box sx={classes.expiryTitle}>Expiry (new)</Box>
              <Box sx={classes.expiryDate}>{moment(newExpiryDate).format('DD MMM YYYY')}</Box>
            </Box>
          </Box>
        </>
      )}
      <Box sx={{ ...classes.dialogButtonContainer, marginTop: '30px', marginBottom: '10px' }}>
        <Button disableElevation sx={classes.buttonDateConfirm} onClick={handleSave}>
          {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
        </Button>
      </Box>
    </Dialog>
  );
}

export default ExpiryDialog;
