"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { theme } from "../../../ThemeContext/ThemeObject";
import { styles as sharedClasses } from "../styles";
export const styles = __spreadProps(__spreadValues({}, sharedClasses), {
  modalInfoStyle: {
    color: "#939393",
    fontFamily: "Source Sans Pro",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "normal"
  },
  modalStyleOverrides: {
    height: "auto",
    maxHeight: "84vh",
    width: "700px",
    padding: "40px"
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    rowGap: 3,
    overflow: "auto"
  },
  errorMessage: {
    marginLeft: 0.5,
    color: "#E37D7A",
    fontSize: "12px"
  },
  autoCompleteTextfield: {
    "& .MuiInputBase-root": { margin: "unset", marginTop: 1 },
    "& .MuiFormHelperText-root": {
      margin: 0.5,
      fontSize: "12px",
      fontFamily: "inherit",
      color: theme.palette.error.main
    }
  },
  loaderContainer: {
    flexGrow: 1,
    rowGap: 3,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.action.main,
    fontSize: "18px"
  },
  wideModal: { width: "904px", maxWidth: "90vw", minWidth: "650px" },
  subWidget: {
    marginTop: "24px",
    flex: "40%",
    borderRadius: "3px",
    height: "100px",
    minWidth: "240px"
  },
  widgetBlockContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    columnGap: "50px"
  },
  widgetBlock: {
    border: "1px solid #E3E3E3",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    paddingX: "16px",
    alignItems: "center",
    width: "50%",
    height: "100%"
  },
  widgetBlockText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "12px"
  },
  widgetBlockHeading: {
    color: theme.palette.primary.main
  },
  widgetBlockBody: {
    fontSize: "14px",
    color: "#333333"
  },
  widgetBlockIcon: {
    backgroundColor: "#EDF2F5",
    padding: "12px",
    borderRadius: "8px",
    color: theme.palette.primary.main
  }
});
