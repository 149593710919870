import React from 'react';
import { IAttachment } from '../../types';
import { classes } from '../../../Components/Modals/styles';
import { Box } from '@mui/material';
import { useDragScroll } from '../../../hooks';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';

export default function ImagePreview({
  isLoadingPreview,
  onPinchHandler,
  selectedAttachment,
  setIsLoadingPreview,
  zoom
}: {
  isLoadingPreview: boolean;
  onPinchHandler: (...args: unknown[]) => ReactDOMAttributes;
  selectedAttachment: IAttachment;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
  zoom: number;
}) {
  const [scrollRef] = useDragScroll();
  const BASE_URL = window.location.origin;

  return (
    <Box ref={scrollRef} sx={{ ...classes.imagePreviewContainer }} {...onPinchHandler()}>
      <Box
        component="img"
        alt={selectedAttachment.attached_file_name}
        draggable={false}
        onLoad={() => setIsLoadingPreview(false)}
        src={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
        sx={{
          ...(classes.imagePreview as React.CSSProperties),
          opacity: isLoadingPreview ? 0 : 1,
          transform: `scale(${zoom})`,
          transformOrigin: '0 0'
        }}
      />
    </Box>
  );
}
