import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Api from '../../Job/API';
import {
  DebouncedFormNumberField,
  DebouncedFormTextField
} from '../../Components/CustomUIElements/FormTextField';
import FormCheckboxGroup from '../../Components/CustomUIElements/FormCheckboxGroup';
import { Virtualize } from '../../Components/CustomUIElements/VirtualizedAutocomplete';
import { SingleDateTimePicker } from '../../Components/CustomUIElements/SingleDatePicker';
import { classes } from '../styles';
import Classifications from './Classifications/Classifications';
import moment from 'moment';
import { additionalOptionsDefaults, editJobsConfig, emojiRegex } from '../../Jobs/config';
import {
  IAdditionalOptions,
  IGeneralProps,
  IJobData,
  IPrivacyAcknowledgement,
  IVisibility
} from '../types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  createFilterOptions,
  InputLabel,
  TextField
} from '@mui/material';
import { ChevronRight, ExpandLess } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

const BASE_URL = window.location.origin;

const filterOptions = createFilterOptions({
  stringify: (option) => option.position_title + option.id
});

function GeneralTab({
  apiKey,
  job,
  jobId,
  timezoneOptions,
  setTimezoneOptions,
  brandOptions,
  setBrandOptions,
  requisitionsOptions,
  setRequisitionsOptions,
  classificationsOptions,
  setClassificationsOptions,
  autoRaterOptions,
  setAutoRaterOptions,
  assignedUsersOptions,
  setAssignedUsersOptions,
  salespersonOptions,
  setSalespersonOptions,
  rmTeamOptions,
  setRmTeamOptions,
  changeTab,
  setEditTabValue,
  setChangeTab,
  localOptions,
  setLocalOptions,
  newJobData,
  setNewJobsData,
  handleUpdateJob,
  userPermissions,
  setIsEdited,
  userTimeZone,
  jobFeedActive
}: IGeneralProps) {
  const [expandOptionalFields, setExpandOptionalFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTimezones, isLoadingTimezones] = useState(false);
  const [countries, setCountries] = useState('');

  const fieldRefs = {
    reference: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    location: useRef<HTMLInputElement>(null),
    expires_at: useRef<HTMLInputElement>(null),
    employment_status: useRef<HTMLInputElement>(null),
    salary_info: useRef<HTMLInputElement>(null),
    number_of_vacancies: useRef<HTMLInputElement>(null),
    from_email: useRef<HTMLInputElement>(null),
    external_approval_id: useRef<HTMLInputElement>(null),
    requisition_info: useRef<HTMLInputElement>(null)
  };

  const checkReference = async (value: string) => {
    if (job.reference === value) {
      return setNewJobsData((prev: IJobData) => ({
        ...prev,
        reference: { ...prev.reference, error: '' }
      }));
    }
    setIsLoading(true);
    try {
      const response = await Api.referenceValidation(
        { 'X-api-authenticate': apiKey },
        { 'job[reference]': value }
      );
      if (response.valid) {
        setNewJobsData((prev: IJobData) => ({
          ...prev,
          reference: { ...prev.reference, error: '' }
        }));
      } else {
        if (response.correct_format === false) {
          newJobData?.reference?.value &&
            setNewJobsData((prev: IJobData) => ({
              ...prev,
              reference: {
                ...prev.reference,
                error: 'Incorrect reference format, prefix letters before digits'
              }
            }));
        } else {
          newJobData?.reference?.value &&
            setNewJobsData((prev: IJobData) => ({
              ...prev,
              reference: { ...prev.reference, error: 'Reference already in use' }
            }));
        }
        fieldRefs['reference']?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (countries.length > 0) return;
    let isMounted = true;
    const handleGetCountries = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/system/v1/countries_states.json`, {
          method: 'GET'
        });
        const data = await response.json();
        const countriesWithlabels = data
          .map((country) => ({
            ...country,
            printable_name: country.name
              .toLowerCase()
              .split(' ')
              .map((word) => {
                if (word !== 'and' && word !== 'of') {
                  return word.charAt(0) === '('
                    ? word[0] + word[1].toUpperCase() + word.slice(2)
                    : word[0].toUpperCase() + word.slice(1);
                } else {
                  return word;
                }
              })
              .join(' '),
            states: country.states.map((state) => ({
              ...state,
              label: state.name
            }))
          }))
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        if (isMounted) {
          setCountries(countriesWithlabels);
        }
      } catch (error) {
        console.error(error);
      }
    };
    handleGetCountries();
    return () => {
      isMounted = false;
    };
  }, [countries]);

  const fetchOptionalSectionData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [
        timezone,
        brand,
        requisition,
        classification,
        autoRater,
        assignedUser,
        sales,
        rmTeam,
        local
      ] = await Promise.all([
        Api.getJobTimezones({ 'X-api-authenticate': apiKey }),
        Api.getJobBrand({ 'X-api-authenticate': apiKey }, jobId),
        Api.getJobrequisitions(
          { 'X-api-authenticate': apiKey },
          { override_limit: true, limit: 500, 'filter[exact_states]': ['approved'] }
        ),
        Api.getJobClassifications({ 'X-api-authenticate': apiKey }),
        Api.getJobAutoRaters({ 'X-api-authenticate': apiKey }, jobId),
        Api.getJobAssignableUsers({ 'X-api-authenticate': apiKey }, jobId),
        Api.getSalesPeople({ 'X-api-authenticate': apiKey }),
        Api.getRMteamMembers({ 'X-api-authenticate': apiKey }),
        Api.getJobLocales({ 'X-api-authenticate': apiKey })
      ]);

      setTimezoneOptions(timezone?.timezones?.map((a) => a.name));
      setBrandOptions(brand?.brands);
      setRequisitionsOptions(requisition?.requisitions);
      setClassificationsOptions(classification?.classifications);
      setAutoRaterOptions(autoRater?.auto_raters);
      setAssignedUsersOptions(assignedUser?.assignable_users);
      setSalespersonOptions(
        sales?.sales_people?.map((a) => `${a.id} - ${a.firstname} ${a.lastname}`)
      );
      setRmTeamOptions(
        rmTeam?.rm_team_members?.map((a) => `${a.id} - ${a.firstname} ${a.lastname}`)
      );
      setLocalOptions(local?.locales);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [apiKey, jobId]);

  useEffect(() => {
    timezoneOptions.length === 0 && !loadingTimezones && fetchOptionalSectionData();
  }, [timezoneOptions.length, fetchOptionalSectionData, loadingTimezones]);

  useEffect(() => {
    if (changeTab[0]) {
      if (handleValidateFields()) {
        setEditTabValue(changeTab[1]);
        setChangeTab([false, null]);
      }
    } else if (!changeTab[0] && changeTab[1] === 1) {
      handleValidateFields() && handleUpdateJob();
    }
  }, [changeTab]);

  const handleValidateFields = () => {
    let isValid = true;
    let firstError: keyof typeof fieldRefs | undefined = undefined;

    Object.entries(newJobData).forEach((field) => {
      let tempErrorMessage = '';
      if (field[1]?.required && !field[1]?.value) {
        if (!firstError) {
          firstError = field[0] as keyof typeof fieldRefs;
        }
        isValid = false;
        tempErrorMessage = 'This field is required';
      }
      if (field[0] === 'title' || field[0] === 'location') {
        if (field[1]?.value?.match(emojiRegex)) {
          isValid = false;
          tempErrorMessage = "This field can't have emojis";
        }
      }
      if (field[0] === 'expires_at') {
        if (!moment(newJobData?.expires_at?.value).isValid()) {
          isValid = false;
          tempErrorMessage = 'This field must be a valid date';
        } else {
          if (
            !moment(newJobData?.expires_at?.value).isAfter(moment()) &&
            !moment(job?.expires_at).isSame(newJobData?.expires_at?.value) &&
            job.state !== 'closed'
          ) {
            isValid = false;
            tempErrorMessage = 'Date and Time must be today or in the future';
          }
        }
      }
      if (field[0] === 'reference' && isValid && newJobData?.reference?.error) {
        isValid = false;
        tempErrorMessage = newJobData?.reference?.error;
      }
      if (
        [
          'classification_list',
          'classification_ids',
          'external',
          'internal',
          'entity_privacy_acknowledgement',
          'privacy_acknowledgement',
          'linkedin_listing_opt_in'
        ].includes(field[0])
      ) {
        isValid = true;
      } else {
        setNewJobsData((prev: IJobData) => ({
          ...prev,
          [field[0]]: { ...prev[field[0] as keyof typeof prev], error: tempErrorMessage }
        }));
      }
    });

    // Scroll to first invalid field
    if (firstError) {
      fieldRefs[firstError]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    return isValid;
  };

  const getDefaults = (options, defaults) => {
    if (options?.length > 0 && defaults?.length > 0) {
      const ids = defaults.map((item) => item.id);
      const selected = options?.filter((option) => ids.indexOf(option?.id) >= 0);
      return selected;
    } else return [];
  };

  return (
    <Box sx={classes.tabContent}>
      <Box id="new-application-modal-title" sx={classes.headerContent}>
        Job Details
      </Box>
      <Box role="form" id="edit-general-job" aria-label="General tab edit jobs">
        <Box sx={classes.generalModalFormLine}>
          <DebouncedFormTextField
            onDebouncedChange={(value: string) => {
              setNewJobsData((prev: IJobData) => ({
                ...prev,
                reference: { ...prev.reference, value }
              }));
              checkReference(value);
              setIsEdited(true);
            }}
            dataTestId="reference-input"
            label="Reference"
            defaultValue={newJobData.reference.value}
            required={newJobData.reference.required}
            error={newJobData.reference.error}
            innerRef={fieldRefs.reference}
            styles={{ width: '250px' }}
          />
          <DebouncedFormTextField
            dataTestId="title-input"
            onDebouncedChange={(value: string) => {
              setNewJobsData((prev: IJobData) => ({
                ...prev,
                title: { ...prev.title, value, error: '' }
              }));
              setIsEdited(true);
            }}
            label="Title"
            defaultValue={newJobData.title.value}
            required={newJobData.title.required}
            error={newJobData.title.error}
            innerRef={fieldRefs.title}
            styles={{ width: '250px' }}
          />
        </Box>
        <Box sx={classes.generalModalFormLine}>
          <DebouncedFormTextField
            onDebouncedChange={(value: string) => {
              setNewJobsData((prev: IJobData) => ({
                ...prev,
                location: { ...prev.location, value, error: '' }
              }));
              setIsEdited(true);
            }}
            id="location"
            dataTestId="location"
            label="City/Town"
            error={newJobData.location.error}
            defaultValue={newJobData.location.value}
            required={newJobData.location.required}
            innerRef={fieldRefs.location}
            styles={{ width: '250px' }}
          />
          <Box sx={{ position: 'relative', marginBottom: '4px' }}>
            <Autocomplete
              id="country"
              loading={countries?.length < 1}
              options={countries}
              getOptionLabel={(option) => option.printable_name}
              value={newJobData?.country_id?.value}
              loadingText={isLoading ? 'Loading countries...' : 'No countries'}
              sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro, sans-serif',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Country" InputLabelProps={{ shrink: true }} />
              )}
              onChange={(event, value) => {
                setNewJobsData((prev: IJobData) => ({
                  ...prev,
                  country_id: { ...prev.country_id, value }
                }));
                setIsEdited(true);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{ ...classes.generalModalFormLine, columnGap: '25px', position: 'relative' }}
          ref={fieldRefs.expires_at}
        >
          <Box>
            <SingleDateTimePicker
              label={
                <InputLabel htmlFor="text-input" sx={sharedClasses.inputLabel}>
                  Expiry date and time<span>*</span>
                </InputLabel>
              }
              inputStyles={classes.datePicker}
              onChange={(value) => {
                setNewJobsData((prev: IJobData) => ({
                  ...prev,
                  expires_at: { ...prev.expires_at, value: moment(value).toISOString(), error: '' }
                }));
                setIsEdited(true);
              }}
              timezone={newJobData.time_zone.value ?? userTimeZone}
              value={newJobData.expires_at.value}
              disablePast
            />
          </Box>

          {newJobData.expires_at.error && (
            <Box sx={{ ...classes.errorBox, bottom: '-35px' }}>{newJobData.expires_at.error}</Box>
          )}
        </Box>
        <Accordion
          sx={classes.accordionRoot}
          expanded={expandOptionalFields}
          onChange={() => setExpandOptionalFields(!expandOptionalFields)}
        >
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={expandOptionalFields ? <ExpandLess /> : <ChevronRight />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box sx={{ ...classes.headerContent, marginBottom: '0', paddingRight: '15px' }}>
              Optional fields
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0' }}>
            <Box sx={{ ...classes.generalModalFormLine }}>
              <Box>
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      employment_status: { ...prev.employment_status, value }
                    }));
                    setIsEdited(true);
                  }}
                  id="employment_status"
                  label="Employment Status"
                  defaultValue={newJobData.employment_status.value}
                  required={newJobData.employment_status.required}
                  error={newJobData.employment_status.error}
                  innerRef={fieldRefs.employment_status}
                  styles={{ width: '250px' }}
                />
                <Box sx={{ color: '#838383', width: '250px', fontSize: '12px' }}>
                  i.e. Full-time, Part-time, Casual
                </Box>
              </Box>
              {userPermissions?.['Job Options']?.['Salary Info field'] && (
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      salary_info: { ...prev.salary_info, value }
                    }));
                    setIsEdited(true);
                  }}
                  id="salary_info"
                  label="Salary Info"
                  defaultValue={newJobData.salary_info.value}
                  required={newJobData.salary_info.required}
                  error={newJobData.salary_info.error}
                  innerRef={fieldRefs.salary_info}
                  styles={{ width: '250px' }}
                />
              )}
              <Virtualize
                value={newJobData.time_zone.value}
                passOptions={timezoneOptions}
                setValue={async (event: string) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    time_zone: { ...prev.time_zone, value: event }
                  }));
                  setIsEdited(true);
                }}
                passedStyles={{
                  width: '250px',
                  marginTop: '24px',
                  ...classes.formAutocomplete,
                  marginBottom: '4px'
                }}
                label="Timezone"
                loading={isLoadingTimezones}
                loadingText="Loading timezones..."
              />
            </Box>
            <Box sx={classes.generalModalFormLine}>
              <DebouncedFormNumberField
                onDebouncedChange={(value: string) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    number_of_vacancies: { ...prev.number_of_vacancies, value }
                  }));
                  setIsEdited(true);
                }}
                id="number_of_vacancies"
                label="Number of vacancies"
                defaultValue={newJobData.number_of_vacancies.value}
                required={newJobData.number_of_vacancies.required}
                error={newJobData.number_of_vacancies.error}
                innerRef={fieldRefs.number_of_vacancies}
                styles={{ width: '250px' }}
              />
              {userPermissions?.['Job Options']?.['Brand and State fields'] && (
                <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                  <Autocomplete
                    id="brand"
                    options={brandOptions}
                    getOptionLabel={(option) => option?.name}
                    value={newJobData.brand.value}
                    loading={brandOptions?.length < 1}
                    loadingText={isLoading ? 'Loading brands......' : 'No brands'}
                    sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
                    ListboxProps={{
                      style: {
                        fontFamily: 'Source Sans Pro, sans-serif',
                        color: '#333333',
                        maxHeight: '200px'
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Brand" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, value) => {
                      setNewJobsData((prev: IJobData) => ({
                        ...prev,
                        brand: { ...prev.brand, value }
                      }));
                      setIsEdited(true);
                    }}
                  />
                </Box>
              )}
              <Box>
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      from_email: { ...prev.from_email, value }
                    }));
                    setIsEdited(true);
                  }}
                  id="from_email"
                  label="From email"
                  defaultValue={newJobData.from_email.value}
                  required={newJobData.from_email.required}
                  error={newJobData.from_email.error}
                  innerRef={fieldRefs.from_email}
                  styles={{ width: '250px' }}
                />
                <Box sx={{ color: '#838383', width: '250px', fontSize: '12px' }}>
                  Or leave blank to use{' '}
                  {job.entity_email
                    ? job.entity_email
                    : job.locale === 'en-au'
                      ? 'jobs@scouttalent.com.au'
                      : 'jobs@scouttalent.ca'}
                </Box>
              </Box>
            </Box>
            <Box sx={classes.generalModalFormLine}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    external_approval_id: { ...prev.external_approval_id, value }
                  }));
                  setIsEdited(true);
                }}
                id="external_approval_id"
                label="External Approval ID"
                defaultValue={newJobData.external_approval_id.value}
                required={newJobData.external_approval_id.required}
                error={newJobData.external_approval_id.error}
                innerRef={fieldRefs.external_approval_id}
                styles={{ width: '250px' }}
              />
              <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                <Autocomplete
                  id="requisitions"
                  multiple
                  options={requisitionsOptions}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option?.id}>
                        {`${option?.position_title} - ${option?.id}`}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option?.position_title ?? '';
                  }}
                  value={getDefaults(requisitionsOptions, newJobData?.requisitions?.value)}
                  loading={requisitionsOptions?.length < 1}
                  filterOptions={filterOptions}
                  loadingText={isLoading ? 'Loading requisitions...' : 'No requisitions'}
                  sx={{
                    ...classes.formAutocomplete,
                    minWidth: '250px',
                    width: '100%',
                    marginTop: '24px'
                  }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Requisitions (Job Details Report)"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(event, value) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      requisitions: { ...prev.requisitions, value }
                    }));
                    setIsEdited(true);
                  }}
                />
              </Box>
            </Box>
            <Box sx={classes.generalModalFormLine}>
              {userPermissions?.['Job Options']?.['Show Postcode Field'] && (
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      postcode: { ...prev.postcode, value }
                    }));
                    setIsEdited(true);
                  }}
                  id="postcode"
                  label="Postcode/Zipcode"
                  defaultValue={newJobData?.postcode?.value}
                  required={newJobData?.postcode?.required}
                  error={newJobData?.postcode?.error}
                  styles={{ width: '250px' }}
                />
              )}
              {userPermissions?.['Job Options']?.['Brand and State fields'] && (
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      address_state: { ...prev.address_state, value }
                    }));
                    setIsEdited(true);
                  }}
                  id="address_state"
                  label="State/Region/Province"
                  defaultValue={newJobData?.address_state?.value}
                  required={newJobData?.address_state?.required}
                  error={newJobData?.address_state?.error}
                  styles={{ width: '250px' }}
                />
              )}
              <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                <Autocomplete
                  id="language/country"
                  options={localOptions}
                  getOptionLabel={(option) => option?.label}
                  value={newJobData?.locale?.value}
                  loading={localOptions?.length < 1}
                  loadingText={
                    isLoading ? 'Loading language & countries...' : 'No language & countries'
                  }
                  sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Language" InputLabelProps={{ shrink: true }} />
                  )}
                  onChange={(event, value) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      locale: { ...prev.locale, value }
                    }));
                    setIsEdited(true);
                  }}
                />
              </Box>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    tag_list: { ...prev.tag_list, value }
                  }));
                  setIsEdited(true);
                }}
                id="tag_list"
                label="Tag list"
                defaultValue={newJobData?.tag_list?.value}
                required={newJobData?.tag_list?.required}
                error={newJobData?.tag_list?.error}
                styles={{ width: '250px' }}
              />
            </Box>
            <Box
              id="new-application-modal-title"
              sx={{ ...classes.headerContent, marginBottom: '1rem' }}
            >
              Classifications
            </Box>
            <Box sx={classes.generalModalFormLine}>
              <Classifications
                isLoading={isLoading}
                setNewJobsData={setNewJobsData}
                classificationsOptions={classificationsOptions}
                newJobData={newJobData}
                setIsEdited={setIsEdited}
              />
            </Box>
            <Box sx={classes.fullWidthFieldContainer}>
              <FormCheckboxGroup
                options={editJobsConfig(userPermissions, jobFeedActive)}
                directionRow={true}
                defaultValues={additionalOptionsDefaults(job)}
                onChange={(value: IAdditionalOptions) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    options: {
                      ...prev.options,
                      ...value
                    },
                    linkedin_listing_opt_in: value.linkedin_listing_opt_in
                  }));
                  setIsEdited(true);
                }}
                groupLabel={'Additional Options'}
                color={'#838383'}
              />
            </Box>
            {newJobData?.options?.auto_ratings &&
            userPermissions?.['Job Options']?.['Auto Rating'] ? (
              <Box sx={classes.generalModalFormLine}>
                <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                  <Autocomplete
                    id="sub_classifications"
                    options={autoRaterOptions}
                    getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
                    value={newJobData.auto_rater.value}
                    loading={autoRaterOptions?.length < 1}
                    loadingText={
                      isLoading
                        ? 'Loading Application recipients......'
                        : 'No Application recipients'
                    }
                    sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
                    ListboxProps={{
                      style: {
                        fontFamily: 'Source Sans Pro, sans-serif',
                        color: '#333333',
                        maxHeight: '200px'
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Auto-rater"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    onChange={(event, value) => {
                      setNewJobsData((prev: IJobData) => ({
                        ...prev,
                        auto_rater: { ...prev.auto_rater, value }
                      }));
                      setIsEdited(true);
                    }}
                  />
                  <Box
                    sx={{ color: '#838383', width: '250px', fontSize: '12px', padding: '5px 0' }}
                  >
                    Auto-ratings for applications of this job will be created in this user's name
                  </Box>
                </Box>
              </Box>
            ) : (
              <span />
            )}
            {userPermissions?.['Job Options']?.['Privacy Acknowledgement'] && (
              <Box sx={classes.fullWidthFieldContainer}>
                <FormCheckboxGroup
                  options={[
                    {
                      id: 'privacy_acknowledgement',
                      name: 'Show the Privacy Acknowledgement',
                      position: 0
                    }
                  ]}
                  directionRow={true}
                  defaultValues={{ privacy_acknowledgement: newJobData.privacy_acknowledgement }}
                  onChange={(value: IPrivacyAcknowledgement) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      privacy_acknowledgement: value.privacy_acknowledgement
                    }));
                    setIsEdited(true);
                  }}
                  groupLabel={'Privacy Acknowledgement'}
                  color={'#838383'}
                />
                {!newJobData.entity_privacy_acknowledgement && (
                  <Box sx={{ paddingLeft: '4px', color: '#E37D7A' }}>
                    A Privacy Acknowledgement is not currently set for this Job. You can update your
                    Privacy Acknowledgement from your Entity&nbsp;
                    <Link
                      href={`${BASE_URL}${newJobData.entity_privacy_acknowledgement_link}`}
                      sx={{ color: '#E37D7A !important', fontWeight: 'bold' }}
                      target="_blank"
                    >
                      settings here
                    </Link>
                  </Box>
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
        {userPermissions?.['Job Options']?.['External'] ||
        userPermissions?.['Job Options']?.['Internal'] ? (
          <Box>
            <Box id="new-application-modal-title" sx={classes.headerContent}>
              Visibility
            </Box>
            <Box sx={classes.generalModalFormLine}>
              <FormCheckboxGroup
                options={
                  userPermissions?.['Job Options']?.['External'] &&
                  userPermissions?.['Job Options']?.['Internal']
                    ? [
                        { id: 'external', name: 'External' },
                        { id: 'internal', name: 'Internal' }
                      ]
                    : userPermissions?.['Job Options']?.['External']
                      ? [{ id: 'external', name: 'External' }]
                      : [{ id: 'internal', name: 'Internal' }]
                }
                directionRow={true}
                defaultValues={{
                  external: newJobData.external,
                  internal: newJobData.internal
                }}
                onChange={(value: IVisibility) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    external: value.external,
                    internal: value.internal
                  }));
                  setIsEdited(true);
                }}
                color={'#838383'}
              />
            </Box>
          </Box>
        ) : (
          <Box />
        )}
        <Box id="new-application-modal-title" sx={classes.headerContent}>
          Job Settings
        </Box>
        <Box sx={classes.generalModalFormLine}>
          {userPermissions?.['Job Options']?.['Assign User to Job'] && (
            <Box sx={{ position: 'relative', marginBottom: '4px' }}>
              <Autocomplete
                id="asssignUser"
                options={assignedUsersOptions}
                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                value={newJobData.assigned_user.value}
                loading={assignedUsersOptions?.length < 1}
                loadingText={isLoading ? 'Loading assigned users...' : 'No assigned user'}
                sx={{ ...classes.formAutocomplete, width: '250px', marginTop: '24px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assigned users"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, value) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    assigned_user: { ...prev.assigned_user, value }
                  }));
                  setIsEdited(true);
                }}
              />
            </Box>
          )}
          {job.features.smart_form_recipients &&
            job.entity_settings.show_smart_form_recipients &&
            (['admin', 'staff'].includes(userPermissions?.['user_type']) ||
              userPermissions?.['paid_user']) && (
              <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                <Autocomplete
                  id="smartFormO"
                  multiple
                  options={assignedUsersOptions}
                  getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
                  value={getDefaults(
                    assignedUsersOptions,
                    newJobData?.smart_form_recipients?.value
                  )}
                  loading={assignedUsersOptions?.length < 1}
                  loadingText={
                    isLoading
                      ? 'Loading smart form notification recipients...'
                      : 'No smart form notification recipients'
                  }
                  sx={{
                    ...classes.formAutocomplete,
                    minWidth: '250px',
                    width: '100%',
                    marginTop: '24px'
                  }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Smart form notification recipient"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(event, value) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      smart_form_recipients: { ...prev.smart_form_recipients, value }
                    }));
                    setIsEdited(true);
                  }}
                />
              </Box>
            )}
          <Box sx={{ position: 'relative', marginBottom: '4px' }}>
            <Autocomplete
              id="recipients"
              multiple
              options={assignedUsersOptions}
              getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
              value={getDefaults(assignedUsersOptions, newJobData?.recipients?.value)}
              loading={assignedUsersOptions?.length < 1}
              loadingText={
                isLoading ? 'Loading Application recipients......' : 'No Application recipients'
              }
              sx={{
                ...classes.formAutocomplete,
                minWidth: '250px',
                width: '100%',
                marginTop: '24px'
              }}
              ListboxProps={{
                style: {
                  fontFamily: 'Source Sans Pro, sans-serif',
                  color: '#333333',
                  maxHeight: '200px'
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Application recipient"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(event, value) => {
                setNewJobsData((prev: IJobData) => ({
                  ...prev,
                  recipients: { ...prev.recipients, value }
                }));
                setIsEdited(true);
              }}
            />
          </Box>
        </Box>
        <Box sx={classes.generalModalFormLine}>
          {job.features.pin_jobs && (
            <Box sx={{ position: 'relative', marginBottom: '4px' }}>
              <Autocomplete
                id="pinnedUser"
                multiple
                options={assignedUsersOptions}
                getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
                value={getDefaults(assignedUsersOptions, newJobData?.pinned_users?.value)}
                loading={assignedUsersOptions?.length < 1}
                loadingText={isLoading ? 'Loading pinned user...' : 'No smart pinned users'}
                sx={{
                  ...classes.formAutocomplete,
                  minWidth: '250px',
                  width: '100%',
                  marginTop: '24px'
                }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pinned users" InputLabelProps={{ shrink: true }} />
                )}
                onChange={(event, value) => {
                  setNewJobsData((prev: IJobData) => ({
                    ...prev,
                    pinned_users: { ...prev.pinned_users, value }
                  }));
                  setIsEdited(true);
                }}
              />
            </Box>
          )}
        </Box>
        {['admin', 'staff'].includes(userPermissions?.['user_type']) && (
          <>
            <Box sx={classes.headerContent}>Internal</Box>
            <Box sx={classes.generalModalFormLine}>
              <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                <Virtualize
                  value={newJobData?.salesperson?.value}
                  passOptions={salespersonOptions}
                  setValue={async (event: string) => {
                    setNewJobsData((prev: IJobData) => ({
                      ...prev,
                      salesperson: { ...prev.salesperson, value: event }
                    }));
                    setIsEdited(true);
                  }}
                  passedStyles={{
                    width: '250px',
                    marginTop: '24px',
                    ...classes.formAutocomplete,
                    marginBottom: '4px'
                  }}
                  label="Salesperson"
                  loading={salespersonOptions?.length < 1}
                  loadingText={isLoading ? 'Loading sales people...' : 'No sales people'}
                />
              </Box>
              {userPermissions?.['Job Options']?.['Assign Recruitment Advertising User to Job'] && (
                <Box sx={{ position: 'relative', marginBottom: '4px' }}>
                  <Virtualize
                    value={newJobData?.rm_team_member?.value}
                    passOptions={rmTeamOptions}
                    setValue={async (event: string) => {
                      setNewJobsData((prev: IJobData) => ({
                        ...prev,
                        rm_team_member: { ...prev.rm_team_member, value: event }
                      }));
                      setIsEdited(true);
                    }}
                    passedStyles={{
                      width: '250px',
                      marginTop: '24px',
                      ...classes.formAutocomplete,
                      marginBottom: '4px'
                    }}
                    label="RM Team Member"
                    loading={rmTeamOptions?.length < 1}
                    loadingText={isLoading ? 'Loading RM team members...' : 'No RM team members'}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default memo(GeneralTab);
