import React, { useState, Dispatch, SetStateAction, FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { classes } from './styles';
import QuestionFieldViewer from '../../Components/Utilities/QuestionFieldViewer';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Switch from '@mui/material/Switch';
import { FormTextField } from '../../Components/CustomUIElements/FormTextField';
import Api from '../../Job/API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';
import { IApplicantQuestion, SortableItemSwitchProps } from '../types';
import AvailabilityFieldQuestion from '../../Components/CustomUIElements/AvailabilityFieldQuestion';
import { theme } from '../../../../components/ThemeContext/ThemeObject';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon
} from '@mui/icons-material';
import { FelixAIActiveSVG, FelixAISVG } from '../../Components/Icons';

export default function SortableItem({
  aiInterview,
  allQuestions,
  apiKey,
  editingQuestion,
  getQuestions,
  index,
  jobId,
  questions,
  setEditingQuestion,
  setModalsOpen,
  setQuestions,
  setSnackbar
}: {
  aiInterview: boolean;
  allQuestions: IApplicantQuestion[];
  apiKey: string;
  applicationCount: number;
  editingQuestion: IApplicantQuestion;
  getQuestions: any;
  index: number;
  jobId: number;
  questions: IApplicantQuestion[];
  setEditingQuestion: Dispatch<SetStateAction<Record<IApplicantQuestion, null>>>;
  setModalsOpen: Dispatch<SetStateAction<Record<string, boolean>>>;
  setQuestions: Dispatch<SetStateAction<IApplicantQuestion[]>>;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [tempEnabled, setTempEnabled] = useState(questions[index].enabled);
  const [tempRequired, setTempRequired] = useState(questions[index].required);
  const [tempRateable, setTempRateable] = useState(questions[index].rateable);
  const [tempConfidential, setTempConfidential] = useState(questions[index].confidential);
  const [tempWeight, setTempWeight] = useState(questions[index].weight);
  const [tempFelixAI, setTempFelixAI] = useState(questions[index].felix_ai_interview_prompter);
  const isQuestionEditable = questions[index].editable;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: questions[index].id
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    filter: isDragging ? 'brightness(75%)' : 'brightness(100%)',
    zIndex: isDragging ? 10 : 1
  };

  const deleteQuestion = async (url: string, questionId: number) => {
    setIsDeleting(true);
    try {
      const response = await Api.deleteQuestion(
        { 'X-api-authenticate': apiKey },
        questionId,
        jobId
      );
      if (response.errors) {
        setSnackbar({
          message: 'You do not have right to delete this field',
          state: 'error'
        });
      } else {
        setSnackbar({
          message: 'Field has been deleted',
          state: 'success'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const cloneQuestion = async (url: string, questionId: number) => {
    setIsCloning(true);
    try {
      await Api.cloneQuestion({ 'X-api-authenticate': apiKey }, questionId, jobId);

      setSnackbar({
        message: 'Field has been cloned',
        state: 'success'
      });
    } catch (error) {
      setSnackbar({
        message: `Failed to clone, ${error}`,
        state: 'error'
      });
    } finally {
      setIsCloning(false);
      setIsDialogOpen(false);
      getQuestions();
    }
  };

  const handleUpdateActions = async (
    event?: React.ChangeEvent<HTMLInputElement>,
    action: keyof (typeof questions)[0],
    weight?: number
  ) => {
    action === 'enabled' && setTempEnabled(!tempEnabled);
    action === 'required' && setTempRequired(!tempRequired);
    action === 'rateable' && setTempRateable(!tempRateable);
    action === 'confidential' && setTempConfidential(!tempConfidential);
    action === 'weight' && setTempWeight(weight);
    action === 'felix_ai_interview_prompter' && setTempFelixAI(!tempFelixAI);
    const fieldId = questions[index].id;
    const originalWeight = questions[index].weight;
    const originalAiInterviewPrompter = questions[index].felix_ai_interview_prompter;
    try {
      const response = await fetch(`/api/v4/fields/${fieldId}?job_id=${jobId}`, {
        method: 'PUT',
        headers: {
          'X-api-authenticate': apiKey,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          field: {
            [action]: event?.target.checked,
            ...(action === 'felix_ai_interview_prompter' && {
              felix_ai_interview_prompter: !tempFelixAI
            }),
            weight: weight
          }
        })
      }).then((response) => response.json());
      if (response.id) {
        setSnackbar({
          message: 'The change has been saved',
          state: 'success'
        });
        const newQuestions = [...allQuestions];
        const indexOfAllquestions = allQuestions.findIndex((item) => item.id === fieldId);
        if (weight !== undefined) {
          newQuestions[indexOfAllquestions].weight = weight;
        } else {
          newQuestions[indexOfAllquestions][action] = !allQuestions[indexOfAllquestions][action];
        }
        setTimeout(() => {
          setQuestions(newQuestions);
        }, 500);
      } else if (response.errors) {
        setSnackbar({
          message: `There was an error saving your changes. ${response.errors}`,
          state: 'error'
        });
        setTimeout(() => {
          action === 'enabled' && setTempEnabled(!event?.target.checked);
          action === 'required' && setTempRequired(!event?.target.checked);
          action === 'rateable' && setTempRateable(!event?.target.checked);
          action === 'confidential' && setTempConfidential(!event?.target.checked);
          action === 'felix_ai_interview_prompter' && setTempFelixAI(originalAiInterviewPrompter);
          action === 'weight' && setTempWeight(originalWeight);
        }, 500);
      }
    } catch (error) {
      setSnackbar({
        message: `There was an error saving your changes. ${error}`,
        state: 'error'
      });
      setTimeout(() => {
        action === 'enabled' && setTempEnabled(!event?.target.checked);
        action === 'required' && setTempRequired(!event?.target.checked);
        action === 'rateable' && setTempRateable(!event?.target.checked);
        action === 'confidential' && setTempConfidential(!event?.target.checked);
        action === 'felix_ai_interview_prompter' && setTempFelixAI(originalAiInterviewPrompter);
        action === 'weight' && setTempWeight(originalWeight);
      }, 500);
    }
  };

  const [dialogProps, setDialogProps] = useState({
    url: '',
    title: 'Permanently delete question?',
    description: '',
    buttonText: 'Delete',
    buttonCallback: deleteQuestion,
    callbackLoading: false,
    dialogId: 0
  });

  const handleDeleteQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}?job_id=${jobId}`,
      description: `Are you sure you want to delete this question? This action cannot be undone.`,
      dialogId: questionId,
      buttonCallback: deleteQuestion
    }));
    setIsDialogOpen(true);
  };

  const handleCloneQuestion = (questionId: number) => {
    setDialogProps((prev) => ({
      ...prev,
      url: `/api/v4/fields/${questionId}?job_id=${jobId}`,
      title: 'Clone question',
      description: `Are you sure you want to clone this question?`,
      buttonText: 'Clone',
      dialogId: questionId,
      buttonCallback: cloneQuestion
    }));
    setIsDialogOpen(true);
  };

  const handleEditQuestion = () => {
    setEditingQuestion({
      ...editingQuestion,
      enabled: questions[index].enabled,
      required: questions[index].required,
      rateable: questions[index].rateable,
      felix_ai_interview_prompter: questions[index].felix_ai_interview_prompter
    });
    setModalsOpen((prev) => ({ ...prev, newField: true }));
  };

  const switchTypes: { type: SortableItemSwitchProps['type']; isChecked: boolean }[] = [
    { type: 'enabled', isChecked: tempEnabled },
    { type: 'required', isChecked: tempRequired },
    { type: 'rateable', isChecked: tempRateable },
    { type: 'confidential', isChecked: tempConfidential }
  ];

  const showAiToggle = aiInterview && questions[index].type === 'TextAreaField';
  const isPrompterOn = questions[index].felix_ai_interview_prompter;
  const aiPrompterToggleText = `Turn ${isPrompterOn ? 'off' : 'on'} AI Interview Prompter`;

  return (
    <Box ref={setNodeRef} style={style}>
      <Box key={questions[index].id}>
        {questions[index].type === 'CsvDataLookupField' ||
        questions[index].type === 'JobReferenceLookupField' ? (
          <span />
        ) : (
          <Box sx={{ ...classes.questionsWrapper, minWidth: 'initial', overflow: 'auto' }}>
            <Box sx={{ display: 'flex' }}>
              <DragIndicatorIcon
                sx={{ cursor: isDragging ? 'grabbing' : 'pointer', ...classes.dragHandle }}
                {...listeners}
                {...attributes}
              />
              <Box sx={{ marginRight: 'auto', ...classes.questionInput }}>
                <Box sx={{ height: '6px' }}></Box>
                <Box sx={{ ...classes.modalFormLine, position: 'relative', minWidth: '300px' }}>
                  <QuestionFieldViewer question={questions[index]} draggable={true} />
                </Box>
              </Box>

              <Stack>
                <Box sx={classes.actionButtonsContainer}>
                  {showAiToggle && (
                    <Tooltip title={aiPrompterToggleText} placement="top" arrow>
                      <IconButton
                        onClick={() =>
                          handleUpdateActions(undefined, 'felix_ai_interview_prompter')
                        }
                      >
                        {questions[index].felix_ai_interview_prompter ? (
                          <FelixAIActiveSVG />
                        ) : (
                          <FelixAISVG />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  {isQuestionEditable && !questions[index].answered && (
                    <Tooltip title="Edit" placement="top" arrow>
                      <IconButton
                        data-testid={`edit-question-button-${questions[index].title}`}
                        onClick={() => handleEditQuestion()}
                      >
                        <EditIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isQuestionEditable && (
                    <Tooltip title="Copy" placement="top" arrow>
                      <IconButton
                        data-testid={`clone-question-button-${questions[index].title}`}
                        onClick={() => handleCloneQuestion(questions[index].id)}
                      >
                        <FileCopyIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!questions[index].answered && (
                    <Tooltip title="Delete" placement="top" arrow>
                      <IconButton
                        data-testid={`delete-question-button-${questions[index].title}`}
                        onClick={() => {
                          handleDeleteQuestion(questions[index].id);
                        }}
                      >
                        <DeleteIcon
                          sx={{ color: theme.palette.error.main, fontSize: theme.spacing(2) }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                <Stack direction="row" alignItems="center" flexGrow={1}>
                  {switchTypes.map(({ type, isChecked }) => (
                    <SortableItemSwitch
                      key={type}
                      question={questions[index]}
                      type={type}
                      checked={isChecked}
                      handleUpdateActions={(event) => handleUpdateActions(event, type)}
                    />
                  ))}
                  <Box sx={{ marginBottom: '12px' }}>
                    <FormTextField
                      value={tempWeight?.toString()}
                      onChange={(e) => handleUpdateActions(e, 'weight', parseInt(e.target.value))}
                      styles={{
                        width: '50px'
                      }}
                      draggable={!questions[index].editable}
                    />
                    <Stack sx={{ fontSize: '12px', textAlign: 'center' }}>Weight</Stack>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            {questions[index].type === 'AvailabilityField' && (
              <AvailabilityFieldQuestion question={questions[index]} />
            )}
            {tempConfidential && (
              <Box sx={{ padding: '0 6px', color: '#838383', fontSize: '12px' }}>
                <span style={{ fontWeight: 'bold' }}>Confidential Setting Enabled:</span> For DE&I
                compliance, only authorised users (HR Admins), can view responses. Hiring Managers
                will not have access.
              </Box>
            )}
          </Box>
        )}
      </Box>
      <GenericDialog
        url={dialogProps.url}
        title={dialogProps.title}
        description={dialogProps.description}
        buttonText={dialogProps.buttonText}
        buttonCallback={dialogProps.buttonCallback}
        callbackLoading={isDeleting || isCloning}
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        dialogId={dialogProps.dialogId}
      />
    </Box>
  );
}

const SortableItemSwitch: FC<SortableItemSwitchProps> = ({
  question,
  type,
  checked,
  handleUpdateActions
}) => {
  const isActive = question[type];
  const isEditable = question.editable;
  const label = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;

  return (
    <Box sx={{ ...classes.sortableItemSwitchContainer, fontWeight: isActive ? 'bold' : 'normal' }}>
      <Switch
        sx={isActive ? classes.switchActive : classes.switch}
        checked={checked}
        size="small"
        onChange={(event) => handleUpdateActions(event, type)}
        disabled={!isEditable}
      />
      <Stack sx={classes.switchLabel}>{label}</Stack>
    </Box>
  );
};
