import React, { useState, Dispatch } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  FileCopy as FileCopyIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import OldIntegrationsLink from './OldIntegrationsLink';
import ExportButton from './ExportButton';
import OnboardExpressLogo from './Logos/OnboardExpressLogo.png';
import XrefLogo from './Logos/XrefLogo.png';
import { ApplicationState, ApplicationAction } from '../types';
import { ModalType } from '../config';
import OnboardExpress from './Modals/OnboardExpress';
import XRef from './Modals/XRef';
import { ScoutOnboardLogo } from './Logos/Logos';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../Components/sharedTypes';
import { styles } from './styles';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { IApplication } from '../types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment-timezone';
import Api from '../API';
import { theme } from '../../../../components/ThemeContext/ThemeObject';
import UnusualActivity from './Modals/UnusualActivity';

export default function IntegrationsTab({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const [alreadyExportedModalOpen, setAlreadyExportedModalOpen] = useState<boolean>(false);
  const { modalsOpen, userTimeZone } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const { isRefetching: refetchingApplication } = useQuery({
    queryKey: ['application'],
    enabled: false
  });

  const { data: integrationsData } = useQuery({
    queryKey: ['integrations'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getIntegrationsData(application.id);
        return res;
      }
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting integrations data, ${error}`,
          state: 'error'
        }
      });
    }
  });

  if (!application) return null;

  return (
    <Stack sx={{ rowGap: 5 }}>
      <Stack sx={{ flexDirection: 'row', columnGap: 4 }}>
        {permissions?.Integrations?.['Export To Revolve Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={styles.integrationCardImageContainer}
              component="img"
              src={OnboardExpressLogo}
            />
            <ExportButton
              onClick={() =>
                application.revolve_record_id
                  ? setAlreadyExportedModalOpen(true)
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ONBOARD_EXPRESS })
              }
            />
          </Stack>
        )}
        {permissions?.Integrations?.['Export To SCOUT Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack sx={styles.integrationCardImageContainer}>
              <ScoutOnboardLogo />
            </Stack>
            <ExportButton
              onClick={() =>
                dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EXPORT_TO_ONBOARD })
              }
            />
          </Stack>
        )}
        {permissions?.Integrations?.['Xref (Export)'] && application?.integrations?.xref && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
              component="img"
              src={XrefLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.XREF })}
            />
          </Stack>
        )}
      </Stack>
      {permissions?.Integrations?.['Xref (View)'] && !!integrationsData?.xref_records.length && (
        <Stack sx={styles.integrationDetails}>
          <Stack
            sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Xref requested checks</Stack>
            <Tooltip title="Update status" placement="top" arrow>
              <IconButton
                sx={{
                  padding: 0.5,
                  '&:focus': {
                    outline: 'none'
                  }
                }}
                onClick={() => queryClient.refetchQueries(['application'])}
              >
                {refetchingApplication ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <RefreshIcon fontSize="small" sx={{ color: '#666666' }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <Table sx={styles.integrationDetailsTable}>
            <TableHead>
              <TableRow>
                {['Submitted at', 'Package name', 'Reference ID', 'Status', 'Referees'].map(
                  (title, index) => (
                    <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                      <Stack>{title}</Stack>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {integrationsData?.xref_records.map((record, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    {moment
                      .tz(
                        record.created_at,
                        application.job.time_zone ||
                          userTimeZone ||
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                      .format('ddd, D MMM YYYY, hh:mma z')}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>{record.package_name}</TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>{record.reference_id}</TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>{record.state}</TableCell>
                  <TableCell>
                    {record.referees?.length ? (
                      <Stack sx={{ rowGap: 2 }}>
                        {record.referees.map((referee, index) => (
                          <Stack key={index} sx={{ rowGap: 1 }}>
                            {referee.first_name} {referee.last_name} - {referee.state}
                            {referee.report_path && (
                              <Link
                                href={referee.report_path}
                                target="_blank"
                                style={{
                                  color: 'inherit',
                                  width: 'fit-content',
                                  textDecoration: 'underline'
                                }}
                              >
                                View Report
                              </Link>
                            )}
                            {referee.unusual_activity && (
                              <Stack
                                sx={{
                                  color: theme.palette.error.main,
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  width: 'fit-content'
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: 'SET_MODALS_OPEN',
                                    payload: ModalType.UNUSUAL_ACTIVITY
                                  });
                                  dispatch({
                                    type: 'SET_UNUSUAL_ACTIVITY_DATA',
                                    payload: {
                                      referenceId: record.reference_id,
                                      selectedReferee: referee
                                    }
                                  });
                                }}
                              >
                                Unusual Activity
                              </Stack>
                            )}
                          </Stack>
                        ))}
                      </Stack>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      )}
      <OldIntegrationsLink />
      {modalsOpen === ModalType.ONBOARD_EXPRESS && (
        <OnboardExpress ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.XREF && (
        <XRef ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      <Dialog open={alreadyExportedModalOpen} onClose={() => setAlreadyExportedModalOpen(false)}>
        <Stack sx={styles.alreadyExportedModal}>
          <CheckCircleIcon sx={{ color: '#5BC4BF', fontSize: '4rem' }} />
          <Stack sx={styles.modalTitle}>Export to Revolve Onboard</Stack>
          <Stack sx={{ alignItems: 'center', color: '#333333', paddingBottom: 2 }}>
            <Stack>This application has already been exported to Revolve.</Stack>
            <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
              <Stack>Revolve record id is</Stack>
              <Stack fontWeight="bold">{application.revolve_record_id}</Stack>
              <CopyToClipboard
                id="copy-revolve-id-button"
                text={application.revolve_record_id}
                onCopy={() =>
                  dispatch({
                    type: 'SET_SNACKBAR',
                    payload: {
                      message: 'Revolve record id has been copied to clipboard',
                      state: 'success'
                    }
                  })
                }
              >
                <Tooltip title={'Copy ID'} placement={'bottom'}>
                  <FileCopyIcon fontSize="small" sx={{ color: '#666666', cursor: 'pointer' }} />
                </Tooltip>
              </CopyToClipboard>
            </Stack>
          </Stack>
          <Button
            sx={sharedClasses.genericButton}
            onClick={() => setAlreadyExportedModalOpen(false)}
          >
            Exit
          </Button>
        </Stack>
      </Dialog>
      {modalsOpen === ModalType.UNUSUAL_ACTIVITY && (
        <UnusualActivity ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
    </Stack>
  );
}
