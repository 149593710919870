"use strict";
export const styles = {
  section: {
    flexBasis: "40%",
    flexGrow: 1,
    maxHeight: "420px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column"
  },
  taskContainer: {
    paddingBottom: "20px",
    overflowY: "scroll",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "26px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "& .MuiSkeleton-root": {
      transformOrigin: "0px 0px !important"
    },
    "& .spinner": {
      margin: "auto"
    }
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "30px"
  },
  emptyStateText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "8px"
  }
};
