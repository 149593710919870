import React from 'react';
import { Stack } from '@mui/material';
import { styles } from '../styles';
import { getApplicationRatingColor } from '../config';

export default function RatingCard({
  rating,
  children
}: {
  rating: number | null;
  children: React.ReactNode;
}) {
  return (
    <Stack
      sx={{
        ...styles.ratingCard,
        ...getApplicationRatingColor(rating)
      }}
    >
      {children}
    </Stack>
  );
}
