var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import useStyles from "../useStyles";
import PropTypes from "prop-types";
const workSalaryOptions = [
  { value: "Casual", label: "Casual" },
  { value: "ContractTemp", label: "Contract" },
  { value: "FullTime", label: "Full Time" },
  { value: "PartTime", label: "Part Time" }
];
function WorkTypes({ answers, setAnswers }) {
  const classes = useStyles();
  return /* @__PURE__ */ React.createElement(FormControl, { fullWidth: true, className: `${classes.formControl}` }, /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: classes.label }, "Work Type"), /* @__PURE__ */ React.createElement(
    RadioGroup,
    {
      value: answers.listing_work_type,
      onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { listing_work_type: e.target.value }))
    },
    workSalaryOptions.map((pay) => /* @__PURE__ */ React.createElement(
      FormControlLabel,
      {
        key: pay.value,
        label: pay.label,
        value: pay.value,
        control: /* @__PURE__ */ React.createElement(Radio, null)
      }
    ))
  ));
}
WorkTypes.propTypes = {
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired
};
export default WorkTypes;
