import React, { MouseEvent } from 'react';
import { Box } from '@mui/material';
import { classes } from '../styles';
import { IconButton } from '@material-ui/core';
import { AIPrompterCloseIcon } from '../../../NewUI/Components/Icons';

export const ResponseMessage = ({ id, message }: { id: string; message: string }) => {
  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const reactComponentRoot = document.getElementById(id) as HTMLElement;
    const responseMessageContainer = reactComponentRoot?.parentElement
      ?.nextElementSibling as HTMLElement;
    if (responseMessageContainer) {
      responseMessageContainer.style.display = 'none';
      reactComponentRoot.innerHTML = 'Get Felix AI’s feedback on my answer';
    }
  };

  return (
    <Box key={id} sx={classes.responseMessageContainer}>
      <IconButton style={classes.closeIcon} onClick={handleClose}>
        <AIPrompterCloseIcon />
      </IconButton>
      <Box
        sx={classes.responseMessageContent}
        dangerouslySetInnerHTML={{ __html: message.replace(/\n\n/g, '<br />') }}
      ></Box>
    </Box>
  );
};
